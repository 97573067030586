import Head from 'next/head'
import React, {useEffect, useState} from 'react'
import type {GetStaticProps, InferGetStaticPropsType} from 'next'
import {
  getLucidColorSchemes,
  getMenus,
  getPage,
  getPageDefinition,
  getPageSections,
  getPublishedPaths,
  getSite, getTypeKits,
  getSiteBuild, getTheme,
  selectActiveSiteBuild
} from '@/graphql/data-managers'
import Sections from '@/components/sections'
import {ServerSideStore} from '@/src/state/ServerSideStore'
import type {BlockContents, Menu, Page, PageBuild, PublishedPagePath, Site, SiteMeta} from '@/components/shared/types'
import type {ColorSchemeResponse} from '@/components/managers/ColorSchemeManager/ColorScheme'
import {ServerSideThemeStore} from '@/src/state/site/ThemeStore'

interface SiteValues {
  [key: string]: BlockContents,
  config: {
    practice: { logos?: { favicon: string } }
  }
}

export interface SplitPublishedPaths {
  proxy_config_id: string
  slug: string[]
}

interface PageStaticProps {
  site: Site
  page: Page
  headerSections: BlockContents[]
  bodySections: BlockContents[]
  footerSections: BlockContents[]
  colorSchemes: ColorSchemeResponse[]
  menus: Menu[]
  siteContents: {[key: string]: BlockContents},
  fonts: string[],
  typeKits: string[],
  globalStyles: string,
  siteValues: SiteValues,
  theme: {
    name: string,
    components: {
      [key: string]: any
    }
  }
}

export async function getStaticPaths() {
  const publishedPages = (await getPublishedPaths()) as PublishedPagePath[]

  return {
    paths: publishedPages.map((page) => ({
      params: {
        ...page,
        slug: page.slug
          .split('/')
          .filter((e) => e)
          .concat('index'),
      }
    })),
    fallback: false,
  }
}

export const getStaticProps: GetStaticProps<PageStaticProps> = async (context) => {

  const {default: formatInitialBlocks} = await import('@/tina/blocks/formatInitialBlocks')
  const {extractFormManagerContents, extractFormManagerValues, FORM_MANAGER_PREFIX} = await import('@/src/utils/formManagerHelper')

  const {proxy_config_id, slug} = context.params as unknown as SplitPublishedPaths
  const patchedSlug = '/' + slug.slice(0, slug.length - 1).join('/')
  const pageDefinition = await getPageDefinition(patchedSlug, proxy_config_id)

  const siteId = pageDefinition.site_id
  const pageId = pageDefinition.page_id
  const siteBuildVersion = pageDefinition.site_build_version
  const pageBuildVersion = pageDefinition.page_build_version

  const site = await getSite(siteId) as Site
  const page = await getPage(pageId)
  const pageBuild = page[`${pageBuildVersion}_page_build`] as PageBuild

  const siteBuildId = site[`${siteBuildVersion}_site_build_id`]
  const {colorSchemes} = await getLucidColorSchemes(siteBuildId)
  const siteContents = extractFormManagerContents(site.contents) as PageStaticProps['siteContents']
  const {menus} = await getMenus(siteBuildId)
  const activeSiteBuild = selectActiveSiteBuild(site, siteBuildVersion)

  const pageSections = await getPageSections(pageBuild.id)
  const siteBuild = await getSiteBuild(activeSiteBuild.build_id!)

  const meta = siteContents?.meta as SiteMeta
  const fonts = JSON.parse(`${meta?.styles?.fonts ?? '[]'}`)
  const typeKits = getTypeKits(pageBuild, site[`${siteBuildVersion}_site_build`])

  const globalStyles = meta?.styles?.css ?? ''

  const themeName = 'flux-v2'
  const theme = {
    name: themeName,
    components: getTheme(themeName, undefined, '../../../../../')
  }

  const siteValues = (extractFormManagerValues(
    siteContents
  )?.[FORM_MANAGER_PREFIX] ?? {config: {}} ) as SiteValues

  const [headerSections, bodySections, footerSections] = await formatInitialBlocks(
    pageSections,
    siteBuild.header_sections,
    siteBuild.footer_sections,
    site.contents
  )

  return {
    props: {
      site,
      page,
      headerSections,
      bodySections,
      footerSections,
      colorSchemes,
      menus,
      siteContents,
      fonts,
      typeKits,
      globalStyles,
      siteValues,
      theme
    }
  }
}

type PageProps = InferGetStaticPropsType<typeof getStaticProps>
export default function StaticPage(
  {
    site,
    page,
    headerSections,
    bodySections,
    footerSections,
    colorSchemes,
    menus,
    siteContents,
    fonts,
    typeKits,
    globalStyles,
    siteValues,
    theme
  }: PageProps) {

  ServerSideStore.menus = menus
  ServerSideStore.site_contents = Object.assign(siteValues?.config ?? {}, siteContents) as BlockContents
  ServerSideStore.schemes = colorSchemes
  ServerSideStore.site = site

  ServerSideThemeStore.themeName = theme.name
  ServerSideThemeStore.components = theme.components

  const [fontLoader, setFontLoader] = useState('')
  const [typeKitsLoader, setTypeKitsLoader] = useState<string[]>([])
  const fontUrl = `https://fonts.googleapis.com/css?family=${fonts.map((font: string) => {
    return font[0].replace(/ /g, '+')
  }).join('|')}&display=swap`

  useEffect(() => {
    /**
     * There seems to be an SSG only bug with Next.js where css imports inline with preload or so are duplicated
     * We will use a method to prevent it by lazy loading fonts ourselves
     */
    setFontLoader(fontUrl)
    setTypeKitsLoader(typeKits)
  }, [])

  return (
    <>
      <Head>
        <title key="page-title">{page.name}</title>
        <link
          key="shortcut-icon"
          rel="shortcut icon"
          type="image/png"
          href={siteValues?.config?.practice?.logos?.favicon}
        />
        <link key={'preconnect-typekit-src1'} rel="preconnect" href="https://use.typekit.net" crossOrigin="anonymous"/>
        <link key={'preconnect-typekit-src2'} rel="preconnect" href="https://p.typekit.net" crossOrigin="anonymous"/>
        {
          typeKits.map(id =>
            <link key={id} rel="preload" href={`https://use.typekit.net/${id}.css`} as="style" crossOrigin="anonymous"/>
          )
        }
        {
          typeKitsLoader.map(id =>
            <link key={`load-${id}`} rel="stylesheet" href={`https://use.typekit.net/${id}.css`} crossOrigin="anonymous"/>
          )
        }
        <>
          <link key="preconnect-fonts" rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
          <link key="preload-fonts" rel="preload" href={fontUrl} as="style" crossOrigin="anonymous"/>
          <link key='load-google-fonts' rel="stylesheet" href={fontLoader} crossOrigin="anonymous"/>
        </>
        {globalStyles && <style>{globalStyles}</style>}
      </Head>
      <div className="layout" id="layout">
        <main className="main-unstack main-mobile-unstack">
          <header className='header-blocks'>
            {unwrapSections(headerSections)}
          </header>

          <div className={'body-sections'}>
            {unwrapSections(bodySections)}
          </div>
        </main>
        <div className={'footer-sections'}>
          {unwrapSections(footerSections)}
        </div>
      </div>
    </>
  )
}

function isKeyOfSection(key: any): key is keyof typeof Sections {
  return typeof key === 'string' && key in Sections
}

function unwrapSections(sectionData: BlockContents[]) {
  return (sectionData ?? []).map(({_template, ...sectionContent}, index) => {
    if (isKeyOfSection(_template)) {
      const SectionComponent = Sections[_template]
      return <SectionComponent key={index} cms={false} {...sectionContent}/>
    }
    return null
  })
}
