import {PresetList} from '@/components/shared/types'

/**
 * 1/1 Migrated
 *
 * 88 - Hero tagline video for inside pages made to look like hero preface [CIRCLE BACK]
 * ✅ Migrated
 * ❌ Not Tested - Nothing to test against
 */
export const presets: PresetList = [
  {
    display: {
      name: 'Hero tagline video for inside pages made to look like hero preface',
      documentationURL: 'https://www.notion.so/einsteinindustries/Hero-tagline-video-for-inside-pages-made-to-look-like-hero-preface-813af74cd3944bc18c873b606cb716b3?pvs=4'
    },
    allowedSections: [],
    notion_IID: '88',
    css: `
    @media (min-width:1024px) { 

.hero-tagline .column {
    max-width: 45% !important; 
} 
.section-background {
    height: calc(100vh - 140px - 2rem);
}
}`
  }
]
