import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import {styleCore} from './stylecore'


export default function TextblockImageCollageA(props) {
  const {
    title,
    subtitle,
    intro,
    textblock_title,
    textblock_content,
    image,
    phrase,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section textblock-image-collage-a-section back-stack full-width custom-max-width'}
      name="textblockimagecollagea"
      section_background={section_background}
    >
      <div className="section-container textblock-image-collage-section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2><span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          <span className="section-richtext section-description paragraph"><RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/></span>
          <div className="row">
            <div className="column images-wrapper">
              <div className="image">
                <SectionImage
                  name="image"
                  src={image?.src}
                  mobile={image?.mobile}
                  link={image?.url}
                  alt={image?.alt}
                  width={image?.width}
                  height={image?.height}
                  priority={image?.priority}
                  sizes={image?.sizes}
                  cms={cms}
                />
              </div>
              <span className="phrase"><InlineTextWithVariables cms={cms} name="phrase" value={phrase}/></span>
            </div>
            <div className="column textblock-wrapper section-richtext paragraph">
              <h3 className="title heading-1"><RichtextWithVariables richtext={textblock_title} cms={cms} name={'textblock_title'}/></h3>
              <div><RichtextWithVariables richtext={textblock_content} cms={cms} name={'textblock_content'}/></div>
              <span className="phrase"><InlineTextWithVariables cms={cms} name="phrase" value={phrase}/></span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

TextblockImageCollageA.StyleCore = styleCore

