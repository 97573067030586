import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  featurescard: {
    ...baseItem,
    label: 'Features Card Item',
    defaultItem: {
      ...baseItem.defaultItem,
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

// InlineBlocks `blocks`
export const FEATURES_CARD_ITEM_BLOCKS = {
  featurescard: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index} cms={cms}>
          <div className="menu-card-item-border-0-2"></div>
          <div className="menu-card-item features-card-item">
            <ItemHeader title={data.title} subtitle={data.subtitle} cms={cms}/>
            <p className="paragraph menu-card-item-description features-card-item-description">
              <RichtextWithVariables
                name="description"
                richtext={data.description}
                cms={cms}
              />
            </p>
          </div>
        </ItemWrapper>
      )
    },
    template: itemTemplates.featurescard,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="menu column menu-card-item-border-0-1"
    >
      <LucidBlocksControls
        cms={props.cms}
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
    </div>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <>
      {props.title ? (
        <h2 className="title heading-3 menu-card-item-title features-card-item-title large-label">
          <SectionText name="title" text={props.title} cms={props.cms} />
        </h2>
      ) : null}
      {props.subtitle ? (
        <h3 className="subtitle heading-4 menu-card-item-subtitle features-card-item-subtitle menu-item-title">
          <SectionText name="subtitle" text={props.subtitle} cms={props.cms} />
        </h3>
      ) : null}
    </>
  )
}
