// import dynamic from 'next/dynamic'
import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/AnnouncementA/presets'

const AnnouncementA = (props) => {
  const {
    title,
    subtitle,
    richtext,
    button,
    meta,
    cms,
    css_overrides,
    color_scheme_id_override,
    className,
    page_section_id,
    section_background
  } = props

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      page_section_id={page_section_id}
      className={className + ' section announcement-section stack announcement-a-section'}
      name="announcementa"
      section_background={section_background}
      meta={meta}>
      <div className="section-container">
        <div className="section-content announcement_gutter_row_1 announcement_gutter_column_1">
          <div className="row announcement-wrapper">
            <div className="column">
              <div className="section-heading">
                <h1>
                  <span className="heading-1 title">
                      <InlineTextWithVariables name="title" value={title} cms={cms} />
                  </span>
                  <span className="heading-4 subtitle">
                      <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
                  </span>
                </h1>
              </div>
              <span className="section-richtext paragraph">
                <RichtextWithVariables name="richtext" richtext={richtext} cms={cms} />
              </span>
            </div>
            {button?.label && (
              <span className="column shrink">
                <SectionButton
                  href={button.url}
                  label={button.label}
                  cms={cms}
                  name="button"
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

AnnouncementA.StyleCore = styleCore
AnnouncementA.presets = presets

export default AnnouncementA
