import {PresetList} from '@/components/shared/types'

/**
 * 1/2 Migrated
 *
 * 74 - Contact Form: Slimmer
 * ✅ Migrated
 * ✅ Tested
 *
 * 154 - Contact Form: Contact Form / Closer combined to 2 columns
 * ✅ Migrated
 * ❌ Not fully tested - Needs to be reviewed by @Chris
 * [CIRCLE BACK]
 *
 * 114 - Form: Align Intro
 * ✅ Migrated
 * ✅ Tested
 *
 * 75 - Form Textblock 2 Column
 * ❌ Removed - Does not apply
 */
export const presets: PresetList = [
  {
    display: {
      name: 'Form: Slimmer',
      documentationURL: 'https://www.notion.so/einsteinindustries/Contact-Form-Slimmer-bf67e10a7f564bfca7bffc79f9da7eb0?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/contact_form-IID-74.png'
    },
    notion_IID: '74',
    allowedSections: [],
    css: `
.contact-form input { 
    padding-left: 70px;
    height: 40px;
    padding-top: 5px;
}
.contact-form input,
.contact-form textarea {
    font-size: 0.9em;    
    border: none;
    background: #fff2;
    margin-bottom: 5px;
    border-radius: 0;
}
.contact-form textarea { margin-bottom: 30px; }
    `
  },
  {
    display: {
      name: 'Form: Form / Closer combined to 2 columns',
      documentationURL: 'https://www.notion.so/einsteinindustries/Contact-Form-Contact-Form-Closer-combined-to-2-columns-1e99d15b8b434541900b59fb1702892c?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/contact_form-IID-154.png',
      description: 'Experimental'
    },
    notion_IID: '154',
    allowedSections: [],
    css: `
@media (min-width: 1024px) {
  .section-form {
      width: 100%;
  }
  .section-form-quote .column {
      max-width: 100%;
      flex: 0 0 100% !important;
  }
  .section-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 4rem;
  }
   .section-heading {
      grid-column: 1 / 3;
      max-width: unset;
  }
  .section-description .column {
      flex: 0 0 100%;
      max-width: unset;
  }

 .column:only-child {
    max-width: unset;
  }
 .contact-form {
    max-width: unset;
  }
}
    `
  },
  {
    display: {
      name: 'Form: Align Intro',
      documentationURL: 'https://www.notion.so/einsteinindustries/Contact-Form-Align-Intro-2fffff494dc348b9883fdd388bf16c96?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/contact_form-IID-114.png',
    },
    notion_IID: '114',
    allowedSections: [],
    css: `
.section-description {
    width: 50%;
}
.section-description .row {
    justify-content: left;
}
@media (max-width: 1024px) {
    .section-heading {
        max-width: 100% !imporant;
    }
    .section-description {
        width: 100%;
    }
    .section-description .row {
        justify-content: center;
    }
}
    `
  }
]
