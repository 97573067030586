import LucidImage from '@/src/utils/LucidImage'
import {useState} from 'react'

interface StaticMapProps {
  mapImage?: string
  size: 'small' | 'large'
}

const StaticMap = ({mapImage, size}: StaticMapProps) => {
  const [hasError, setHasError] = useState(false)
  const [width, height] = size === 'large' ? [540, 540] : [250, 150]

  const handleImageError = () => {
    setHasError(true)
  }

  const sizes = `(max-width: ${width}) 100vw, ${width}px`
  
  return (
    hasError ? (
      <div>
        <span>Map is still processing. Try again in a few minutes.</span>
      </div>
    ) : <LucidImage
      src={mapImage ?? ''}
      alt='Location Map'
      width={width}
      height={height}
      layout='fixed'
      onError={handleImageError}
      sizes={sizes}
    />
  )
}

export default StaticMap
