import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MENU_BLOCK_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'


export default function MenuBlock(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' menu-block-section section'}
      name="menublock"
      section_background={section_background}
    >

      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title color-scheme-title heading-1">
                <InlineTextWithVariables cms={cms} name="title" value={title}/>
              </span>
              <span className="subtitle color-scheme-subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
              </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext">
                  <RichtextWithVariables name="intro" richtext={intro} cms={cms}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row expanded collapse">
            <div className="column">
              <LucidInlineBlocks
                itemProps={props}
                name="menu_block_items"
                blocks={MENU_BLOCK_ITEM_BLOCKS}
                className={`menu-block-items item-count-${props?.menu_block_items?.length ?? 0}`}
              />
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext">
                <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
              </div>
            </div>
          </div>
          <span style={{textAlign: 'center'}}>
              <SectionButton name='button' href={button?.url} label={button?.label} cms={cms}/>
            </span>
        </div>
      </div>
    </Section>
  )
}

MenuBlock.StyleCore = styleCore
