import SectionButton from '../shared/SectionButton'
import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Hero/presets'

export default function Hero(props) {
  const {
    tagline,
    subtitle,
    caption,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    cms,
    meta
  } = props

  let HeroButton = null
  if (button?.url && button?.label) {
    HeroButton = <>
      <SectionButton label={button.label} href={button.url} cms={cms} name={'button'}/>
    </>
  }

  let HeroCaption = null
    if (caption) {
      HeroCaption = <>
        <div className="row hero-caption flexbox-alignment bottom-right mobile-bottom card-inactive">
          <div className="column">
            <span className="caption paragraph"><RichtextWithVariables richtext={caption} cms={cms} name={'caption'}/></span>
          </div>
        </div>
      </>
    }

  return (
    <Section
      cms={cms}
      name="hero"
      className={className + ' hero site section hero-section header-stack'}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      section_background={section_background}
    >
        <div className="section-container">
          <div className="section-content">
            <div className="row hero-tagline flexbox-alignment bottom mobile-bottom card-inactive">
              <div className="column">
                <div className=""></div>
                <h1 className="tagline">
                  <span className="hero-title heading-1 title"><RichtextWithVariables richtext={tagline} cms={cms} name={'tagline'}/></span>
                  <span className="hero-subtitle heading-2 subtitle"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
                </h1>
                {HeroButton}
              </div>
            </div>
            {HeroCaption}
          </div>
        </div>
    </Section>
  )
}

Hero.StyleCore = styleCore
Hero.presets = presets

