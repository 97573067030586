import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/TextblockColumns2/presets'

export default function TextblockColumns2(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_richtext,
    column_1_button,
    column_2_title,
    column_2_image,
    column_2_richtext,
    column_2_button,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' textblock-columns-2-section'}
      name="textblockcolumns2"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content textblock_columns_2_gutter_row_1 textblock_columns_2_gutter_column_1">
          <div className="section-heading">
            <h2>
            <span className="title heading-1">
              <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
            </span>
              <span className="subtitle heading-2">
              <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
            </span>
            </h2></div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row content-row section-richtext left-on-top">
            <div className="column column-1">
              <h3 className="title heading-1"><RichtextWithVariables richtext={column_1_title} cms={cms} name={'column_1_title'}/></h3>
              {
                (column_1_image?.src) && <div className="image">
                    <SectionImage
                        name={'column_1_image'}
                        src={column_1_image?.src}
                        mobile={column_1_image?.mobile}
                        alt={column_1_image?.alt}
                        width={column_1_image?.width}
                        height={column_1_image?.height}
                        priority={column_1_image?.priority}
                        sizes={column_1_image?.sizes}
                        cms={cms}
                    />
                  </div>
              }

              <div className="section-richtext paragraph">
                <span><RichtextWithVariables richtext={column_1_richtext} cms={cms} name={'column_1_richtext'}/></span>
              </div>

              <div className="column-1-button">
                {
                  (column_1_button?.label && column_1_button?.url) &&
                    <SectionButton
                        cms={cms}
                        name={'column_1_button'}
                        label={column_1_button?.label}
                        href={column_1_button?.url}
                    />
                }
              </div>
            </div>
            <div className="column column-2">
              <h3 className="title heading-1"><RichtextWithVariables richtext={column_2_title} cms={cms} name={'column_2_title'}/></h3>
              <div className="image">
                {
                    (column_2_image?.src) && <SectionImage
                        name={'column_2_image'}
                        src={column_2_image.src}
                        mobile={column_2_image?.mobile}
                        alt={column_2_image?.alt}
                        width={column_2_image?.width}
                        height={column_2_image?.height}
                        priority={column_2_image?.priority}
                        sizes={column_2_image?.sizes}
                        cms={cms}
                    />
                }
              </div>
              <div className="section-richtext paragraph">
                <span><RichtextWithVariables richtext={column_2_richtext} cms={cms} name={'column_2_richtext'}/></span>
              </div>

              <div className="column-2-button">
                {
                    (column_2_button?.label && column_2_button?.url) &&
                    <SectionButton
                        cms={cms}
                        name={'column_2_button'}
                        label={column_2_button?.label}
                        href={column_2_button?.url}
                    />
                }
              </div>
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph"><RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/></div>
            </div>
          </div>
          <div className="outro-button">
            {
                (button?.label && button?.url) && <SectionButton label={button?.label} href={button?.url} cms={cms} name={'button'}/>
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

TextblockColumns2.StyleCore = styleCore
TextblockColumns2.presets = presets


