import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'

const ProfileCard = (props) => {
  const {
    title,
    subtitle,
    intro,
    image,
    person_name,
    profession,
    contact_method_label_1,
    contact_method_value_1,
    contact_method_label_2,
    contact_method_value_2,
    button,
    outro,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section profilecard profilecard-section stack'}
      name="profilecard"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content profile_card_gutter_row_1 profile_card_gutter_column_1">
          <div className="section-heading">
            <span className="title heading-1">
              <h1>
                <InlineTextWithVariables name="title" value={title} cms={cms} />
              </h1>
            </span>
            <span className="heading-4 subtitle">
              <h4>
                <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
              </h4>
            </span>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext">
                  <RichtextWithVariables
                    name='intro'
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row card">
            <div className="column shrink card-image">
            <SectionImage
              name='image'
              src={image?.src}
              mobile={image?.mobile}
              alt={image?.alt}
              width={image?.width}
              height={image?.height}
              priority={image?.priority}
              sizes={image?.sizes}
              cms={cms}
            />
            </div>
            <div className="column card-info">
              <p className="name heading-3">
                <InlineTextWithVariables cms={cms} name="person_name" value={person_name} />
              </p>
              <p className="profession heading-6">
                <InlineTextWithVariables cms={cms} name="profession" value={profession} />
              </p>
              <div className="contact-method">
                <div>
                  <p>
                    <strong className="contact-label">
                      <InlineTextWithVariables cms={cms} name="contact_method_label_1" value={contact_method_label_1} />
                    </strong>
                    <span>
                      
                      <InlineTextWithVariables
                        cms={cms}
                        name="contact_method_value_1"
                        value={contact_method_value_1} />
                    </span>
                  </p>
                </div>
                {contact_method_label_2 && contact_method_value_2 && (
                  <div>
                    <p>
                      <strong className="contact-label">
                        <InlineTextWithVariables cms={cms} name="contact_method_label_2" value={contact_method_label_2} />
                      </strong>
                      <span>
                        <InlineTextWithVariables
                          cms={cms}
                          name="contact_method_value_2"
                          value={contact_method_value_2} />
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <SectionButton
                name='button'
                href={button?.url}
                label={button?.label}
                cms={cms}
              />
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext">
                <RichtextWithVariables
                  name='outro'
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </Section>
  )
}

ProfileCard.StyleCore = styleCore

export default ProfileCard
