import {PresetList} from '@/components/shared/types'

/**
 * 2/2 Migrated
 *
 * 24 - Closer: Single Column Large Image
 * ✅ Migrated
 * ✅ Tested
 *
 * 124 - Closer: Split image
 * ✅ Migrated
 * ❌ Not fully tested - An image cannot be added to the section description in the rich text editor;
 *                       it must be added in the section description field.
 * Notes: With section title above, you’ll also need to insert the image in the section description
 *        with the text rather than its own field. Also, if there is a HeroTagline or an Intro above,
 *        one should apply "Front card overlapping header fix & Closer Split Image Fix" to the section,
 *        since the scope of the overrides are limited to the section.
 */
export const presets: PresetList = [
  {
    display: {
      name: 'Closer: Single Column Large Image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Closer-Single-Column-Large-Image-b3e15d25e29649c2b124996360fa93e7?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/closer-IID-24.png'
    },
    notion_IID: '24',
    allowedSections: [],
    css: `
.closer-image {
  text-align: center;
  width: 100%;
  max-width: unset;
}
@media (min-width:1024px) {
  .closer-section {
    padding: 10rem 0;
  }
}
.row {
  flex-flow: column;
}
img {
  max-width: 100%;
  width: 100%;
}
.section-container {
  max-width: 660px;
}
    `
  },
  {
    display: {
      name: 'Closer: Split image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Closer-Split-image-21cc5e5b91174bc9ad8a0ba9b065f8a0?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/closer-IID-124.png',
      description: 'With section title above, you’ll also need to insert the image in the section description with the text rather than it’s own field. Also, if there is a HeroTagline or an Intro above, one should apply "Front card overlapping header fix & Closer Split Image Fix" to the section'
    },
    notion_IID: '124',
    allowedSections: [],
    css: `
.column.closer-image {
  flex-basis: 50%;
}

.closer-image img {
  max-width: 100%
}

.section-container {
  max-width: 1400px;
}

.column.closer-description {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width:1024px) {
  .closer-description img {
    max-width: 50%;
    margin-left: 4rem !important;
  }
}

.closer-description .section-heading span {
  text-align: center !important;
}
.closer-description .heading-2 {
  margin-bottom: 2rem;
}
section.hero-inner-section + section.intro-section {
  padding-top: 5rem;
}
    `
  }
]
