import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'


export default function TextblockColumns3(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_richtext,
    column_1_button,
    column_2_title,
    column_2_image,
    column_2_richtext,
    column_2_button,
    column_3_title,
    column_3_image,
    column_3_richtext,
    column_3_button,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' textblock-columns-3-section stack'}
      name="textblockcolumns3"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content textblock_columns_3_gutter_row_1 textblock_columns_3_gutter_column_1">
          <div className="section-heading">
            <h2><span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          {
            intro && <div className="section-description">
                <div className="row">
                  <div className="column">
                    <div className="section-richtext paragraph"><RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/></div>
                  </div>
                </div>
              </div>
          }
          <div className="row content-row section-richtext">
            <div className="column column-1">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_1_title} cms={cms} name={'column_1_title'}/></h3>

              {
                column_1_image?.src && <div className="image">
                  <SectionImage
                    name={'column_1_image'}
                    src={column_1_image?.src}
                    mobile={column_1_image?.mobile}
                    alt={column_1_image?.alt}
                    width={column_1_image?.width}
                    height={column_1_image?.height}
                    priority={column_1_image?.priority}
                    sizes={column_1_image?.sizes}
                    cms={cms}
                  />
                </div>
              }

              <div className="section-richtext paragraph">
                <RichtextWithVariables richtext={column_1_richtext} cms={cms} name={'column_1_richtext'}/>
              </div>

              {
                    column_1_button?.label?.length > 0 && <div className="column-1-button column-button">
                    <SectionButton
                        cms={cms}
                        name={'column_1_button'}
                        href={column_1_button?.url}
                        label={column_1_button?.label}
                    />
                  </div>
              }
            </div>
            <div className="column column-2">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_2_title} cms={cms} name={'column_2_title'}/></h3>

              {
                column_2_image?.src && <div className="image">
                    <SectionImage
                        src={column_2_image?.src}
                        mobile={column_2_image?.mobile}
                        alt={column_2_image?.alt}
                        width={column_2_image?.width}
                        height={column_2_image?.height}
                        priority={column_2_image?.priority}
                        sizes={column_2_image?.sizes}
                        cms={cms}
                        name={'column_2_image'}/>
                  </div>
              }

              <div className="section-richtext paragraph">
                <RichtextWithVariables richtext={column_2_richtext} cms={cms} name={'column_2_richtext'}/>
              </div>

              {
                  column_2_button?.label?.length > 0 && <div className="column-2-button column-button">
                    <SectionButton
                        cms={cms}
                        name={'column_2_button'}
                        href={column_2_button?.url}
                        label={column_2_button?.label}
                    />
                  </div>
              }
            </div>
            <div className="column column-3">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_3_title} cms={cms} name={'column_3_title'}/></h3>

              {
                column_3_image?.src && <div className="image">
                    <SectionImage
                        src={column_3_image?.src}
                        mobile={column_3_image?.mobile}
                        alt={column_3_image?.alt}
                        width={column_3_image?.width}
                        height={column_3_image?.height}
                        priority={column_3_image?.priority}
                        sizes={column_3_image?.sizes}
                        cms={cms}
                        name={'column_3_image'}/>
                  </div>
              }

              <div className="section-richtext paragraph">
                <RichtextWithVariables richtext={column_3_richtext} cms={cms} name={'column_3_richtext'}/>
              </div>

              {
                column_3_button?.label?.length > 0 && <div className="column-3-button column-button">
                    <SectionButton
                        cms={cms}
                        name={'column_3_button'}
                        href={column_3_button?.url}
                        label={column_3_button?.label}
                    />
                  </div>
              }
            </div>
          </div>
          {
            outro?.length > 0 && <div className="section-outro">
                <div className="row">
                  <div className="column section-richtext paragraph">
                    <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
                  </div>
                </div>
              </div>
          }
          {
              button?.label?.length > 0 && <div style={{
                minWidth: 320,
                textAlign: 'center',
              }} className={'final-button'}>
                <SectionButton label={button?.label} href={button?.url} cms={cms} name={'button'}/>
              </div>
          }
        </div>
      </div>
    </Section>
  )
}

TextblockColumns3.StyleCore = styleCore


