import React from 'react'

type StreetViewProps = {
  embedCode: string
  width?: string
  height?: string
}

const StreetView: React.FC<StreetViewProps> = ({embedCode, width: userWidth, height: userHeight}) => {
  const extractAttribute = (attribute: string, embedCode: string): string => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(embedCode, 'text/html')
    const iframe = doc.querySelector('iframe')
  
    return iframe && iframe.hasAttribute(attribute) ? (iframe.getAttribute(attribute) as string) : ''
  }

  const url =  extractAttribute('src', embedCode)
  const width = userWidth || extractAttribute('width', embedCode)
  const height = userHeight || extractAttribute('height', embedCode)

  return (
    <div className="iframe-wrapper">
      <iframe src={url} width={width} height={height} style={{border: '0'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
    </div>
  )
}

export default StreetView
