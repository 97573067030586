type UploadMapProps = {
  siteId: string
  center: {
    lat: number
    long: number
  }
  address: string
}

const uploadMapImage = async ({siteId, center, address}: UploadMapProps) => {
  try {
    let params = center ? `center=${encodeURIComponent(center.lat)},${encodeURIComponent(center.long)}`
                        : `center=${encodeURIComponent(address)}`
    const response = await fetch(`/api/static-map?${params}&zoom=15&width=540&height=540&siteId=${siteId}`)
    if (!response.ok) {
      throw new Error('Failed to upload map image')
    }
    const responseData = await response.json()
    return responseData.url
  } catch (error) {
    console.error('Error uploading map image:', error)
  }
}

export default uploadMapImage
