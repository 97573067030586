import {FlexBox} from '../shared/stylecore/sets/BreakpointFlexboxGridOptions'
import {Box} from '../shared/stylecore/sets/Box'
import Affiliations from './index'
import {StyleCoreElement} from '@/components/shared/StyleCore'

export const StyleCore = {
  config: {
    ...FlexBox.createFields(),
    ...Box.createFields('imageContainer')
  },
  target: {
    componentName: 'affiliations',
  },
  css: `
    ${FlexBox.createCSS('row-section-images', 'col-section-image')}
    ${Box.createCSS('col-section-image-container', 'imageContainer.')}
  `
}
