import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  menutext: {
    ...baseItem,
    label: 'Menu Text Item',
    defaultItem: {
      ...baseItem.defaultItem,
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

export const MENU_TEXT_ITEM_BLOCKS = {
  menutext: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="menu column">
            <ItemWrapper index={index} cms={cms}>
              <div className="menu-text-item">
                <h3 style={{textAlign: 'center'}}>
                  <span className="menu-item-title menu-text-item-title heading-3">
                    <SectionText name="title" text={data.title} cms={cms}/>
                  </span>
                  <br/>
                  <span className="menu-item-subtitle menu-text-item-subtitle heading-4">
                    <SectionText name="subtitle" text={data.subtitle} cms={cms}/>
                  </span>
                </h3>
                <p>
                  <RichtextWithVariables
                    name="description"
                    richtext={data.description}
                    cms={cms}
                  />
                </p>
              </div>
            </ItemWrapper>
          </div>
          <div className="menu-item-break"></div>
        </>
      )
    },
    template: itemTemplates.menutext,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      focusRing={{offset: -9, borderRadius: 4}}
      insetControls={false}
      cms={props.cms}
    >
      {props.children}
    </LucidBlocksControls>
  )
}

