import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'

// TODO: return "part of the DocShop Network" after Practice Name in copyright if affiliated with DocShop

export default function Footer(props) {
  const {
    intro,
    richtext,
    disclaimer,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' site section footer-section'}
      name="footer"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row expanded">
            <div className="column">
              <div className="section-richtext">
                <div className="custom-footer-text">
                  <div className="section-text paragraph">
                    <RichtextWithVariables name='intro' richtext={intro} cms={cms} />
                  </div>
                </div>
                  <div className="core-footer-text">
                    <div className="disclaim">
                      <div className="section-text paragraph disclaimer">
                        <RichtextWithVariables name='disclaimer' richtext={disclaimer} cms={cms} />
                      </div>
                    </div>
                    <div className="copyright">
                      <div className="section-text paragraph copyright">
                        <RichtextWithVariables name='richtext' richtext={richtext} cms={cms} />
                      </div>
                    </div>
                    <div className="links">
                      <div className="section-richtext paragraph ">
                        <a href="/site-map">Sitemap</a>&nbsp;|&nbsp; 
                        <a href="/privacy-policy">Privacy Policy</a>&nbsp;|&nbsp;
                        <a
                          href="https://www.einsteinextranet.com"
                          rel="nofollow noopener"
                        >
                          Login
                        </a>
                    </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

Footer.StyleCore = styleCore
