import Section from '../shared/Section'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionButton from '../shared/SectionButton'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {LOCATION_ITEM_BLOCKS} from './config'
import React from 'react'
import {styleCore} from './stylecore'

export default function Location(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta} page_section_id={page_section_id}
      className={className + ' section location-section stack with-content'}
      name="location"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </div>
              <div className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </div>
            </h1>
          </div>
          <RichtextWithVariables
            name="intro"
            richtext={intro}
            cms={cms}
          />
          <LucidInlineBlocks name="location" className="location_wrapper" blocks={{location: LOCATION_ITEM_BLOCKS}} itemProps={props} max={1} />
          <RichtextWithVariables
            name="outro"
            richtext={outro}
            cms={cms}
          />
          <SectionButton
            href={button?.url}
            label={button?.label}
            cms={cms}
          />
        </div>
      </div>
    </Section>
  )
}

Location.StyleCore = styleCore
