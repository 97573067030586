import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {Text} from '@nextui-org/react'
import React, {useEffect, useState} from 'react'
import LocationPicker from './LocationPicker'
import SectionButton from '../shared/SectionButton'
import styled from 'styled-components'
import {prepareMapProperties, useLocationData} from './utils'
import Helpers from '@/src/utils/shared/helpers'
import StaticMap from '../StaticMap'
import SchemaLocalBusiness from '../shared/SchemaLocalBusiness'
import uploadMapImage from '../shared/uploadMapImage'
import {useLucidContext} from '@/src/state/ServerSideStore'

const Row = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;

  .city,
  .state,
  .zipcode {
    display: inline;
  }
`

const Column = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex: 1 1 0px;
  padding: 0 1rem;
`

export const LOCATION_ITEM_BLOCKS = {
  template: {
    label: 'Location',
    defaultItem: {
        location: '0',
        customMapUrl: '',
        customCoordinates: '',
        hours: '<p>Office Hours:</p><p>Monday - Friday: 8:00am - 5:00pm</p>',
    },
    fields: [
      {
        name: 'location',
        component: (form) => <LocationPicker onChange={form.input.onChange} value={form.input.value} />
      },
      {
        name: 'map_image',
        label: 'Map Image',
        component: 'image',
        parse: (media) => {
          if (!media) return
          return decodeURIComponent(media.id)
        },
        previewSrc: (src) => src
      },
      {
        name: 'customMapUrl',
        label: 'Custom Map URL',
        component: 'text',
        description: 'Optional. Overrides the default Google Maps URL to link to for this location.',
      },
      {
        name: 'customCoordinates',
        label: 'Custom Coordinates',
        component: 'text',
        description: 'Optional. Enter as "latitude, longitude" to override default address maps placement. Example: "37.7749, -122.4194".',
      },
      {
        name: 'hours',
        label: 'Hours',
        component: 'html',
      }
    ]
  },
  Component({index, cms, data}) {
    if (!data['location']) {
      return null
    }
    return (
      <LocationItem
        key={data.location.id}
        index={index}
        cms={cms}
        data={data}
      />
    )
  }
}

function LocationItem({index, cms, data}) {
  const {location, schemaProps} = useLocationData(data.location)
  const {mapLink, mapCenter, mapAddress} = prepareMapProperties(data, location)
  const [{site}] = useLucidContext(cms)

  const [mapImageUrl, setMapImageUrl] = useState(data.map_image ?? '')

  useEffect(() => {
    const fetchMapImage = async () => {
      if ((mapCenter || !mapAddress.includes('undefined'))) {
        const uploadedImageUrl = await uploadMapImage({siteId: site?.id, center: mapCenter, address: mapAddress})
        data.map_image = uploadedImageUrl ?? ''
        setMapImageUrl(uploadedImageUrl ?? '')
      }
    }

    fetchMapImage()
  }, [mapCenter, mapAddress, site?.id])

  if (!location) {
    return <p>No locations available. Please add a location.</p>
  }

  return (
    <ItemWrapper index={index} cms={cms}>
      <SchemaLocalBusiness {...schemaProps} />
      <Row>
        <Column>
          <div className="address">
            <Text name="address">{location?.address}</Text>
          </div>
          <div className="address2">
            <Text name="address2">{location?.address2}</Text>
          </div>
          <div className="city_state">
            <Text name="city_state">{location?.city}, {location?.state} {location?.postal_code}</Text>
          </div>
          <div className="direction_button">
            <SectionButton
              name="directions"
              href={mapLink}
              label="Directions"
              cms={false}
              openInNewTab
            />
          </div>
          <div className="phone_button">
            <SectionButton
              name='phone'
              href={`tel:${location?.phone}`}
              label={Helpers.format.human.phone.format(location?.phone)}
              cms={false}
            />
          </div>
          <div className="office-hours">
            <RichtextWithVariables cms={cms} name="hours" richtext={data.hours} />
          </div>
        </Column>
        <Column className="location-map">
          <a href={mapLink} target="_blank" rel="noreferrer">
            <StaticMap
              mapImage={mapImageUrl}
              size="large"
            />
          </a>
        </Column>
      </Row>
    </ItemWrapper>
  )
}

function ItemWrapper(props) {
  return (
    <div className="location-item">
      <LucidBlocksControls
        cms={props.cms}
        index={props.index}
        focusRing={{offset: -9, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
    </div>
  )
}
