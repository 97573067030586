import {UnitOptions} from '@/components/sections/shared/stylecore/options/UnitOptions'
import type {FormState} from '@/components/shared/externalTypes'
import type {FormManagerFieldProps, FormManagerTextFieldHelperPropsState} from '@/src/utils/shared/FormManagerFormUI'

export const styleCore = {
  config: {
    navigationBar: {
      content: {
        scrolling: {
          content: {
            mode: {
              content: 'select',
              default: 'sticky',
              options: [
                {value: 'sticky', label: 'Sticky'},
                {value: 'static', label: 'Fixed'},
              ],
              listener: (formState: FormState, fieldState: string, field: FormManagerFieldProps) => {
                if (formState.navigationBar.scrolling.shrinking.enabled && fieldState === 'sticky') {
                  return {
                    message: {
                      state: 'warning' as FormManagerTextFieldHelperPropsState,
                      message: 'Shrinking is not supported when the navigation bar is sticky. Please disable shrinking or change the mode to fixed. For nearly the same effect, you can additionally disable the \'Takes Space\' option.'
                    }
                  }
                }
                return {
                  ...field
                }
              }
            },
            shrinking: {
              content: {
                enabled: {
                  content: 'toggle',
                  default: false,
                  description: 'Enable or disable the shrinking of the navigation bar on scroll.',
                  listener: (formState: FormState, fieldState: string, field: FormManagerFieldProps) => {
                    if (formState.navigationBar.scrolling.mode === 'sticky') {
                      return {
                        locked: true,
                        message: {
                          state: 'error' as FormManagerTextFieldHelperPropsState,
                          message: 'Shrinking is not supported when the navigation bar is sticky. Please change the mode to fixed. For nearly the same effect, you can additionally disable the \'Takes Space\' option'
                        }
                      }
                    }
                    return {
                      ...field,
                    }
                  }
                },
                shrinkOnMobile: {
                  content: 'toggle',
                  default: false,
                  description: 'Enable or disable the shrinking of the navigation bar on mobile.',
                },
                height: {
                  content: 'number',
                  default: 80,
                  min: 0,
                  max: 300,
                  step: 1,
                  unit: 'px' as const,
                  description: 'The height of the navigation bar when it is shrunk.',
                }
              }
            },
            gradient: {
              content: {
                mode: {
                  content: 'select',
                  default: 'none',
                  options: [
                    {value: 'none', label: 'None'},
                    {value: 'scrolled', label: 'When Scrolled'},
                    {value: 'top', label: 'At Top'},
                    {value: 'always', label: 'Always'},
                  ],
                  description: 'When the gradient should be shown on the navigation bar.'
                },
                colorWash: {
                  content: 'toggle',
                  default: true,
                  description: 'Enable or disable the white color wash on the navigation bar when scrolled.',
                },
                skipColorWashOnImages: {
                  content: 'toggle',
                  default: true,
                  description: 'Enable or disable the white color wash on the navigation bar when scrolled on images.',
                },
                color: {
                  default: '#000000',
                  content: 'color',
                  description: 'The color of the gradient on the navigation bar.',
                }
              }
            }
          }
        },
        colors: {
          content: {
            backgroundColor: {
              default: 'inherit',
              content: 'color',
              description: 'The background color of the navigation bar.',
            },
            textColor: {
              default: 'inherit',
              content: 'color',
              description: 'The color of the text in the navigation bar.',
            }
          }
        },
        minHeight: {
          content: 'number',
          default: 150,
          min: 80,
          max: 900,
          step: 1,
          unit: 'px' as const,
          description: 'The minimum height of the navigation bar. Elements will be vertically centered within this height.',
          listener: (formState: FormState, fieldState: string, field: FormManagerFieldProps) => {
            if (parseInt(formState.navigationBar.maxHeight.replace('px', '')) < parseInt(fieldState.replace('px', ''))) {
              return {
                fieldValue: formState.navigationBar.maxHeight.replace('px', ''),
                message: {
                  state: 'error' as FormManagerTextFieldHelperPropsState,
                  message: 'The minimum height must be less than the maximum height.'
                }
              }
            }
            return {
              ...field,
              fieldValue: fieldState.replace('px', ''),
            }
          }
        },
        maxHeight: {
          content: 'number',
          default: 180,
          min: 80,
          max: 900,
          step: 1,
          unit: 'px' as const,
          listener: (formState: FormState, fieldState: string, field: FormManagerFieldProps) => {
            if (parseInt(formState.navigationBar.minHeight.replace('px', '')) > parseInt(fieldState.replace('px', ''))) {
              return {
                fieldValue: formState.navigationBar.minHeight.replace('px', ''),
                message: {
                  state: 'error' as FormManagerTextFieldHelperPropsState,
                  message: 'The maximum height must be greater than the minimum height.'
                }
              }
            }
            return {
              ...field,
              fieldValue: fieldState.replace('px', ''),
            }
          },
          description: 'The maximum height of the navigation bar. Elements will be vertically centered within this height.',
        },
        margin: {
          content: {
            leftAndRight: {
              default: 55,
              content: 'number',
              min: 0,
              unit: 'px' as const,
              description: 'The left and right margin of the navigation bar.',
            },
            links: {
              default: 20,
              content: 'number',
              min: 0,
              unit: 'px' as const,
              description: 'The left and right margin of the links in the navigation bar.',
            }
          }
        },
        images: {
          content: {
            height: {
              content: 'number',
              default: 100,
              min: 0,
              max: 900,
              step: 1,
              description: 'The height of the image in the navigation menu.',
            },
            heightUnit: {
              content: 'select',
              default: '%',
              options: UnitOptions,
            },
            width: {
              content: 'number',
              default: 100,
              min: 0,
              max: 900,
              step: 1,
              description: 'The width of the image in the navigation menu.',
            },
            widthUnit: {
              content: 'select',
              default: '%',
              options: UnitOptions,
            }
          }
        },
        takesSpace: {
          content: 'toggle',
          default: true,
          description: 'Enable or disable the navigation bar taking up space on the page. Will affect all header sections.',
        }
      }
    },
    navigationMenu: {
      content: {
        roundedCorners: {
          content: 'number',
          unit: 'px' as const,
          default: 20,
          min: 0,
          max: 100,
          step: 1,
          description: 'The radius of the rounded corners on the bottom of the navigation menu popup.',
        },
        height: {
          content: 'number',
          default: 50,
          min: 30,
          step: 1,
          max: 900,
          description: 'The height of the navigation menu popup.',
        },
        heightUnit: {
          content: 'select',
          default: 'vh',
          options: UnitOptions,
          description: 'The unit of the height of the navigation menu popup.',
        },
        colors: {
          content: {
            backgroundColor: {
              default: '#FFFFFF',
              content: 'color',
              description: 'The background color of the navigation menu popup.',
            },
            textColor: {
              default: 'inherit',
              content: 'color',
              description: 'The text color of the navigation menu popup.',
            }
          }
        },
        shadow: {
          content: {
            enable: {
              content: 'toggle',
              default: true,
              description: 'Enable or disable the shadow of the navigation menu popup.',
            },
            color: {
              default: '#000000',
              content: 'color',
              description: 'The color of the shadow of the navigation menu popup.',
            },
            opacity: {
              default: 0.5,
              content: 'number',
              min: 0,
              max: 1,
              step: 0.01,
              description: 'The opacity of the shadow of the navigation menu popup.',
            }
          }
        },
        items: {
          content: {
            links: {
              content: {
                backgroundColor: {
                  default: '#00000030',
                  content: 'color',
                  description: 'The background color of the links in the navigation menu popup.',
                },
                padding: {
                  content: 'number',
                  default: 15,
                  min: 0,
                  max: 900,
                  step: 1,
                  description: 'The padding of the links in the navigation menu popup.',
                  unit: 'px' as const,
                },
                borderRadius: {
                  content: 'number',
                  default: 20,
                  min: 0,
                  max: 100,
                  step: 1,
                  description: 'The border radius of the links in the navigation menu popup.',
                  unit: 'px' as const,
                },
                margin: {
                  content: 'number',
                  default: 10,
                  min: 0,
                  max: 900,
                  step: 1,
                  unit: 'px' as const,
                  description: 'The bottom margin of the links in the navigation menu popup.',
                },
                linkTextColor: {
                  default: 'inherit',
                  content: 'color',
                  description: 'The text color of the links in the navigation menu popup.',
                }
              }
            }
          }
        }
      }
    },
  },
  target: {
    componentName: 'navigation',
  },
  css: `
    {target} .navigation-menu {
        position: absolute;
        top: {navigationBar.height};
        left: 0;
        width: 100%;
        height: {navigationMenu.height}{navigationMenu.heightUnit};
        z-index: 100;
        border-radius: 0 0 {navigationMenu.roundedCorners} {navigationMenu.roundedCorners};
        background-color: {navigationMenu.colors.backgroundColor};
        color: {navigationMenu.colors.textColor};
    }
    
    {target} .navigation-inner-shrunk {
        height: {navigationBar.scrolling.shrinking.height} !important;
        min-height: {navigationBar.scrolling.shrinking.height} !important;
    }
    
    {target} .navigation-inner-shrunk .navigation-image img {
        height: {navigationBar.scrolling.shrinking.height} !important;
        max-height: {navigationBar.scrolling.shrinking.height} !important;
    }
    
    {target} .navigation-image img {
        height: 100% !important;
        max-height: {navigationBar.maxHeight} !important;
    }
    
    {target} .navigation-inner-color-wash .navigation-image {
      filter: {navigationBar.scrolling.gradient.skipColorWashOnImages ? inherit : brightness(0) invert(1)};
    }
    
    {target} .navigation-inner-color-wash .link,
    {target} .navigation-inner-color-wash .navigation-inline-menu-trigger,  
    {target} .navigation-inner-color-wash .navigation-menu-trigger,
    {target} .navigation-inner-color-wash .navigation-menu-trigger .heading-5 {
      color: #fff !important;
    }
    
    {target} .navigation-menu-trigger {
      cursor: pointer;
    }
    
    {target} .navigation-inner-gradient {
        background-color: transparent !important;
        background: linear-gradient(180deg, {navigationBar.scrolling.gradient.color}C0 0%, rgba(0, 0, 0, 0) 80%);
    }
    
    {target} {
      background-color: transparent;
      position: {navigation.takesSpace ? 'relative' : 'absolute'};
    }
    
    {target} .navigation-image {
        height: {navigationBar.images.height}{navigationBar.images.heightUnit};
        width: {navigationBar.images.width}{navigationBar.images.widthUnit};
    }
    
    .header-blocks {
        position: {navigationBar.takesSpace ? {navigationBar.scrolling.mode} : fixed} !important;
        top: 0;
        left: 0;
        width: 100%;
        overflow-x: unset;
        z-index: 90;
    }
    
    body {
      overflow-x: unset;
    }
    
    .main-mobile-unstack {
        overflow-x: unset;
        width: 100%;
        height: 100%;
    }
    
    {target} .navigation-menu-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    
    {target} .navigation-menu-container > * {
        margin: 0 10px;
    }
    
    {target} .navigation-menu-links {
        width: 100%;
        display: block;
    }
    
    {target} .navigation-menu-links-items {
        display: grid;
        width: 100%;
    }
    
    {target} .navigation-sub-menu {
      padding-left: 20px;
    }
    
    {target} .menu-link-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    {target} .navigation-menu-links-items > li {
        padding: {navigationMenu.items.links.padding};
        border-radius: {navigationMenu.items.links.borderRadius};
        margin-bottom: {navigationMenu.items.links.margin};
        background-color: {navigationMenu.items.links.backgroundColor};
        width: 100%;
    }
    
    {target} .navigation-menu-links-items a {
        color: {navigationMenu.colors.textColor};
    }
    
    {target} .navigation-menu-links-items .link {
        color: {navigationMenu.items.links.linkTextColor};
    }
    
    {target} .layout a {
        color: {navigationMenu.colors.textColor};
    }
   
    
    {target} {
        position: sticky;
        top: 0;
        z-index: 10;
        width: 100%;
    }
    
    {target} .navigation-outer {
        position: relative;
    }
    
    {target} .navigation-menu-shadow {
        width: 100%;
        height: 100%;
        background-color: {navigationMenu.shadow.color};
    }
    
    {target} .navigation-menu-shadow-wrapper {
        opacity: {navigationMenu.shadow.opacity};
        position: absolute;
        top: {navigationBar.height};
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 99;
    }
    
    {target} .navigation-inner {
        width: 100%;
        height: 100%;
        min-height: {navigationBar.minHeight};
        display: flex;
        align-items: center;
        padding-left: {navigationBar.margin.leftAndRight};
        padding-right: {navigationBar.margin.leftAndRight};
        background-color: {navigationBar.colors.backgroundColor};
        color: {navigationBar.colors.textColor};
        transition: min-height 0.2s;
    }
   
    {target} .flex-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
    
    {target} .section-item-wrapper {
      width: 100%;
      text-align: center;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    {target} .flex-parent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      width: 100%;
      flex-direction: row;
    }
    
    {target} .navigation-menu-column {
        width: 100%;
    }
    
    {target} .flex-full-size {
      position: relative;
      width: 100%;
      height: 100%;
    }
    
    {target} .navigation-desktop .section-flex-center {
      justify-content: center;
    }
    
    {target} .navigation-desktop .section-flex-end {
      justify-content: flex-end;
    }
    
    {target} .navigation-desktop .section-flex-start {
      justify-content: flex-start;
    }
    
    {target} .navigation-mobile .section-flex-start,
    {target} .navigation-mobile .section-flex-end,
    {target} .navigation-mobile .section-flex-center {
      justify-content: center;
    }
    
    {target} .section-flex-space-between {
      justify-content: space-between;
    }
    
    @media only screen and (max-width: 640px) {
        {target} .flex-parent {
           display: flex;
           justify-content: space-between;
           align-items: center;
           padding: 10px;
           width: 100%;
           flex-direction: column;
        }
    }
    
    {target} .link-control a {
      text-wrap: nowrap !important;
    }
    
    {target} .section-item-full-width {
      width: 100% !important;
    }
    
    {target} ul {
       margin-left: unset;
       margin: {navigationBar.margin.links};
       padding: 0;
       text-align: center;
       width: min-content;
    }
    
    {target} .navigation-menu-links ul {
       margin-left: unset;
       margin: 0;
       padding: 20px;
       text-align: center;
       width: 100%;
    }
   
    
    {target} li {
        margin: 0;
        padding: 0;
        width: max-content;
        text-align: center;
        
    }
    
    {target} .link-control {
      width: min-content;
      list-style-type: none;
    }
    
    {target} .link-control > li {
       display: flex;
    }
    
    {target} .navigation-sub-menu {
      padding-left: 20px;
    }
    
    {target} .menu-link-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    .layout ul li {
        margin-bottom: 0px;
    }
  `
}
