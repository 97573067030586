import {Dispatcher, LucidSiteContext, LucidState} from '@/src/state/site/Store'
import {useContext} from 'react'

/**
 * Server Side, we don't really need any context/state management.
 * So we are just using a simple Singleton in the form of a javascript object
 *
 * We are able to freely mutate this in the back end and then access this at a later time.
 */
export const ServerSideStore: LucidState = {
  isSaving: false,
  site_contents: {},
  pages: [],
  schemes: [],
  site_build_id: '',
  menus: [],
  favicon: '/favicon.ico',
  revalidateOnFocus: false
}

export function useLucidContext(cms: boolean): [LucidState, Dispatcher] {
  const [context, lucidDispatch] = useContext(LucidSiteContext)
  /**
   * these extra checks ensure that when using TinaCMS,
   * we are able to successfully preview the page by exiting tina
   *
   * In other words, this bypasses server side store if we are previewing front end generated stuff
   */
  const hasSetSeverSideStore = ServerSideStore.schemes.length > 0 &&
    ServerSideStore.menus.length > 0 && Object.keys(ServerSideStore.site_contents).length > 0

  if (!cms && hasSetSeverSideStore) {
    return [ServerSideStore, lucidDispatch]
  }
  return [context, lucidDispatch]
}
