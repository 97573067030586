import {createContext, PropsWithChildren, useContext, useReducer} from 'react'
import reducer, {ActionTypes} from './reducer'
import {StyleCoreSiteProvider} from '@/src/state/site/StyleCoreStore'
import {LucidSiteContext} from '@/src/state/site/Store'
import {ServerSideStore} from '@/src/state/ServerSideStore'

export type ThemeState = {
  themeName: string,
  components: {
    [key: string]: string
  }
}

export const ServerSideThemeStore: ThemeState = {
  themeName: '',
  components: {}
}

const initialState: ThemeState = {
  themeName: '',
  components: {}
}

export type ThemesDispatcher = (arg: {type: typeof ActionTypes[number], payload: Partial<ThemeState>}) => void

type LucidThemesContextInterface = readonly [ThemeState, ThemesDispatcher]

export const SiteThemeContext = createContext<LucidThemesContextInterface>([initialState, () => {}])

export const SiteThemeProvider = ({children}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <SiteThemeContext.Provider value={[state as ThemeState, dispatch]}>
      <StyleCoreSiteProvider>
        {children}
      </StyleCoreSiteProvider>
    </SiteThemeContext.Provider>
  )
}

export default function useSiteTheme(cms: boolean) {
  const [context, lucidDispatch] = useContext(SiteThemeContext)
  /**
   * these extra checks ensure that when using TinaCMS,
   * we are able to successfully preview the page by exiting tina
   *
   * In other words, this bypasses server side store if we are previewing front end generated stuff
   */
  const hasSetSeverSideStore = ServerSideThemeStore.themeName !== '' &&
    Object.entries(ServerSideThemeStore.components).length !== 0

  if (!cms && hasSetSeverSideStore) {
    return [ServerSideThemeStore, lucidDispatch]
  }
  return [context, lucidDispatch]
}
