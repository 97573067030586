import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Pods: On card with title above (with better alignment)',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-27.gif',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-On-card-with-title-above-with-better-alignment-340305482972406b9d3cbcd4376af426?pvs=4'
    },
    notion_IID: '27',
    allowedSections: [],
    css: `
.pods-items>.row div:nth-child(2) {
    background: white;
}
.pods-items .row, section.features-card-section .menu.column, section.reviews-section .reviews-item {
    box-shadow: 0px 10px 20px 0px #4242425c;
    background-color: #fff;
    border: none;
}
@media (max-width: 1024px) {
.pods-items .row{
box-shadow: none;
}
}
    `
  },
  {
    display: {
      name: 'Pods and Feature Cards: Better spacing and left alignment',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-28.gif',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-and-Feature-Cards-Better-spacing-and-left-alignment-e9bec8c9c1f94883b1ef95bf1f326932?pvs=4',
    },
    notion_IID: '28',
    allowedSections: [],
    css: `
@media (min-width: 600px) {
.features-card-item {
    padding: 0 3rem;
}
}
@media (max-width: 1024px) {
.pods-items h2 {
margin-top: .5rem;
}
}
.pods-items p, section.pods-section .pods-items h2, .pods-items .pods-item-content, section.features-card-section .features-card-item {
    text-align: left;
}
    `
  },
  {
    display: {
      name: 'Pods with Cards',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-70.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-with-Cards-4453ff16635e4dc8a65345aebe13106a?pvs=4'
    },
    notion_IID: '70',
    allowedSections: [],
    css: `
.pods-item-content { /* Square Text */ 
    background: #fff;
    padding: 40px 60px;
    margin: 0 50px;
    border-radius: 4px;
    box-shadow: 0px 10px 15px #0000001a;
}

@media (max-width: 1000px) {
    .pods-items > .row { flex-direction: column; }
    .pods-items > .row:nth-child(even) .column { order: -1 !important; }
    .pods-items > .row .image-box {
        width: 400px;
        max-width: 400px;
        margin: auto auto -50px auto;
        border-radius: 4px;
        z-index: -1;
    }
    .pods-item-content {
        padding: 20px 20px;
        margin-bottom: 80px;
    }
}
    `
  },
  {
    display: {
      name: 'Pods: Fade-In',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-61.gif',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-Fade-In-341cfb66083d48d9bd639587c802cbfb?pvs=4'
    },
    notion_IID: '61',
    allowedSections: [],
    css: `
    /* Animation - Pods Fade In */
.pods-items .row.collapse .pods-item-image .image-box {
  animation: 2s pods-fadeIn;
  transition: all cubic-bezier(0.500, 0.000, 0.500, 1.000); 
  transition-timing-function: cubic-bezier(0.500, 0.000, 0.500, 1.000);
}
.pods-items .row.collapse .pods-item-content {
  animation: 2.5s pods-fadeInSlow;
  transition: all cubic-bezier(0.500, 0.000, 0.500, 1.000); 
  transition-timing-function: cubic-bezier(0.500, 0.000, 0.500, 1.000);
}
@keyframes pods-fadeIn {
  0% { opacity:0;}
  30%{ opacity: 0;}
  100% { opacity:1;}
}
@keyframes pods-fadeInSlow {
  0% { opacity:0;}
  50%{ opacity: 0;}
  100% { opacity:1;}
}
    `
  },
  {
    display: {
      name: 'Pods with Rounded Images and Cards',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-with-Rounded-Images-and-Cards-a5c44eca395d455ea12d8808eda812e5?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-71.png'
    },
    notion_IID: '71',
    allowedSections: [],
    css: `
    /* Pods: Rounding with Cards */
.image-box, .pods-section img { /* Circle Image */
    border-radius: 100% !important;
}
.pods-item-content { /* Square Text */ 
    background: #fff;
    padding: 40px 60px;
    margin: 0 50px;
    border-radius: 4px;
    box-shadow: 0px 10px 15px #0000001a;
}
.image-box { 
    margin-left: 5%; 
    margin-right: -5%; 
}
.pods-item-content { 
    margin-left: -5%; 
    margin-right: 5%; 
}
.pods-items > .row:nth-child(even) .image-box { 
    margin-left: -5%; 
    margin-right: 5%; 
}
.pods-items > .row:nth-child(even) .pods-item-content {
    margin-left: 5%; 
    margin-right: -5%; 
}
.image-box, .pods-section img { /* Circle Image */
    border-radius: 100% !important;
    z-index: -1;
}
@media (max-width: 1000px) {
    .image-box,
    .pods-items > .row:nth-child(even) .image-box,
    .pods-items > .row:nth-child(even) .pods-item-content,
    .pods-item-content {
        margin-left: auto;
        margin-right: auto;
    }
    .pods-item-content,
    .pods-items > .row:nth-child(even) .pods-item-content { 
        margin: 0 30px 60px 30px;
    }
}
    `
  },
  {
    display: {
      name: 'Pods: Remove bottom gap on pods section set to front',
      documentationURL: 'https://www.notion.so/einsteinindustries/Pods-Remove-bottom-gap-on-pods-section-set-to-front-ea3887a96aba434088f9f9455046a7f5?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-41.gif'
    },
    notion_IID: '41',
    allowedSections: [],
    css: `
    /* Pods: Remove bottom gap on pods section set to front */
@media (min-width:1024px) {
    .section-container:after {
        bottom: 0rem;
    }

    .section-container {
        padding-bottom: 0;
        margin-bottom: -2rem;
    }
}
    `
  },
  {
    display: {
       name: 'Pods: Rounded Corners',
       documentationURL: 'https://www.notion.so/einsteinindustries/Pods-Rounded-Corners-3d2f434b9e314b4aac77218e8e952bd4?pvs=4',
       image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/pods-IID-97.png'
      },
      notion_IID: '97',
      allowedSections: [],
      css: `
      
      /* Standard Pods with Rounded Corners ----------------------------------- */
.pods-items .row:nth-child(odd) .image-box { border-radius: 1rem 0 0 1rem; }
.pods-items .row:nth-child(even) .image-box {
    border-radius: 0 1rem 1rem 0;
}
.pods-items .row:first-child .image-box {
    border-radius: 1rem 1rem 0 1rem;
}
.pods-items .row:last-child .image-box {
    border-radius: 0 1rem 1rem 1rem;
}
.pods-items .row:nth-child(odd):last-child .image-box {
    border-radius: 1rem 0 1rem 1rem;
}
      `

  }
]
