import FeaturesCard from '@/components/sections/FeaturesCard/index'
import {FlexBox} from '@/components/sections/shared/stylecore/sets/BreakpointFlexboxGridOptions'
import {Box} from '@/components/sections/shared/stylecore/sets/Box'

export const styleCore = {
  config: {
    ...FlexBox.createFields(),
    ...Box.createFields('photoContainer')
  },
  target: {
    componentName: 'menuphoto',
  },
  css: `
    ${FlexBox.createCSS('row .menus .menu-photos-items, {target} .section-content .row', 'column')}
    ${Box.createCSS('menu-photos-items .column', 'photoContainer.')}
  `
}
