import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Menu Icon: Unlimited columns!',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/menu_icon-IID-152.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Icon-Unlimited-columns-2602ffe56ddd47679a809c228ce8a2cd?pvs=4'
    },
    allowedSections: [],
    notion_IID: '152',
    css: `
@media (min-width:640px) {
  .menu-icon-items {
    flex-flow: row wrap;
  }
  .menu {
    flex: 0 1 0%;
    max-width: unset;
  /* Change this value to set a readable minimum width */
    min-width: 400px;
  }
  .menu-item-break {
    display: none;
  }
}
    `
  }
]
