import Link from 'next/link'

export function LinkWrapper({children, href, className, inNewWindow, cms, ...props}: {
  children: JSX.Element | JSX.Element[],
  href?: string,
  className?: string,
  inNewWindow?: string | boolean,
  cms?: boolean
}) {
  function noLinkOnCms(e: React.MouseEvent<HTMLElement>) {
    if (cms) {
      e.preventDefault()
    }
  }

  if (!href) {
    return children
  }
  const target = String(inNewWindow) === 'true' ? '_blank' : '_self'

  return <Link href={href} onClick={noLinkOnCms} target={target} className={className} {...props}>
    {children}
  </Link>
}
