import React, {useEffect, useState} from 'react'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

export type VideoProps = {
  title: string
  src: string
  poster: string
  size: number
  provider?: 'html5' | 'youtube' | 'vimeo',
  loop?: boolean
}

declare global {
  interface Window {
    onYouTubeIframeAPIReady?: () => void;
  }
}

export default function SectionVideo({title, src, poster, size, provider, loop}: VideoProps) {
  const [, setYouTubeApiReady] = useState(false)

  /**
   * The Plyr React component requires the YouTube API to be loaded before it can render YouTube videos,
   * however, this Plyr component is rendered on the server, so we need to wait until the YouTube API is
   * ready before we can render the component. We just need to trigger the Plyr component to re-render
   */
  useEffect(() => {
    if (provider !== 'youtube' || typeof window === 'undefined') {
      return
    }

    window['onYouTubeIframeAPIReady'] = () => {
      setYouTubeApiReady(true)
    }

    return () => {
      window['onYouTubeIframeAPIReady'] = undefined
    }
  }, [])

  const source = {
    src: src,
    type: 'video/mp4',
    size: size,
    provider: provider ?? 'html5',
  }

  if (!src) {
    return <p>Sorry, no video source was provided.</p>
  }
  
  return <section>
    <Plyr
      title={title}
      loop={loop ?? false}
      source={{
        type: 'video',
        title: title,
        sources: [source],
        poster: poster,
      }}
    />
  </section>
}
