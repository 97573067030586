import {Box} from '@/components/sections/shared/stylecore/sets/Box'

const socialIconOptions = Box.createOptions('section-item-wrapper a', 'icons', {
  display: {
    mode: 'flex'
  }
})
export const styleCore = {
  config: socialIconOptions.fields,
  target: {
    componentName: 'socialstripe',
  },
  css: socialIconOptions.CSS
}
