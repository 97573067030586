import {PresetList} from '@/components/shared/types'

/**
 * 3/3 Migrated
 *
 * 52 - Feature List: Vertically center icons and descriptions
 * ✅ Migrated
 * ✅ Tested
 *
 * 87 - Feature List: Condensed List on Desktop
 * ✅ Migrated
 * ✅ Tested
 *
 * 162 - Features List: 2 column format
 * ✅ Migrated
 * ✅ Tested
 * */
export const presets: PresetList = [
  {
    display: {
      name: 'Feature List: Vertically center icons and descriptions',
      documentationURL: 'https://www.notion.so/einsteinindustries/Feature-List-Vertically-center-icons-and-descriptions-c5623e5ee6e64ca691ecef0c2c26a5da?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_list-IID-52.gif'
    },
    notion_IID: '52',
    allowedSections: [],
    css: `
.features-list-item-wrapper {
     align-items: center;
}
    `
  },
  {
    display: {
      name: 'Feature List: Condensed List on Desktop',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_list-IID-87.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Condensed-List-on-Desktop-f71f90e336424f0cbea08a1e2ab70bfb?pvs=4'
    },
    notion_IID: '87',
    allowedSections: [],
    css: `
@media (min-width:1024px) {
     .features-list-wrapper {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
    }
     .features-list-item-wrapper {
         max-width: 46%;
         width: 46%;
         margin: 0 4% 0 0;
    }
}
 @media (max-width: 640px) {
     .features-list-wrapper {
         text-align: center;
    }
}
    `
  },
  {
    display: {
      name: 'Features List: 2 column format',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_list-IID-162.png',
      documentationURL: 'https://www.notion.so/einsteinindustries/Features-List-2-column-format-7cf8733bd8824d15af6dd5da77e67c06?pvs=4'
    },
    notion_IID: '162',
    allowedSections: [],
    css: `
 @media (min-width:1024px) {
     .section-container .section-heading + .row {
         max-width: 100%;
         flex-basis: 100%;
    }
     .section-container .features-list-wrapper {
         display: grid;
         grid-template-columns: 1fr 1fr;
         column-gap: 4rem;
    }
}
    `
  },
]
