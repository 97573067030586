import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Textblock Column: Slide-In',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Column-Slide-In-11f9dda65dd040a380de7b56177dac64?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-58.gif'
    },
    allowedSections: [],
    notion_IID: '58',
    css: `
    /* This feature is available in StyleCore */
    `
  },
  {
    display: {
      name: 'Textblock Columns 2 : Split 50% width Background Image',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-Split-50-width-Background-Image-04ba4c445e0f4691a74943a31e304ccc?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-50.gif',
      description: 'Documentation contains important details'
    },
    allowedSections: [],
    notion_IID: '50',
    css: `
    
/* Textblock Columns 2 : Split 50% width Background Image */

/** Left Image **/
@media (min-width: 1024px) {
\t\t .section-custom-background,
     .section-background {
\t\t\t\tmax-width: 50%;
\t\t\t\tleft: 0;
\t\t}
\t\t .column-2 img {
\t\t\t\tdisplay: none;
\t\t}

}

/*** Animation fixes ***/
animated.effect-zoom-out .section-custom-background,
animated.effect-zoom-out .section-background, {
    animation: zoomOutSplitLeft 5s ease-out 1 !important;
    transform: scale(1);
}

@keyframes zoomOutSplitLeft {
\t\t100% {
\t\t    transform: scale(1);
\t\t}
\t\t
\t\t0% {
\t\t    transform: translate(-5vw,0) scale(1.2);
\t\t}
}

/* Mobile Empty Column Fix */
@media screen and (max-width: 1024px) {
\t\t.column {
\t\t    flex: 0 0 100%;
\t\t    max-width: 100%;
\t\t}
}
    `
  },
  {
    display: {
      name: 'Textblock Columns 2: Vertically center columns',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-Vertically-center-columns-f9a38a8d467249178d942d7aa6488aad?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-147.png'
    },
    allowedSections: [],
    notion_IID: '147',
    css: `
.column .section-richtext {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
    `
  },
  {
    display: {
      name: 'Textblock Columns 2: bordered background',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-2-bordered-background-369c4398dab4424481a76f45805d8b87?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-140.png'
    },
    allowedSections: [],
    notion_IID: '140',
    css: `
.section-container::before {
  background-color: #fff;
  padding: 6rem;
  content: '';
  display: block;
  height: calc(100% - 6rem);
  margin: 3rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 6rem);
}
@media (min-width:1024px) {

img {
display: none;

}
}
@media (max-width:1024px) {

.column {
  flex-basis: calc(100% - 4rem);
  max-width: calc(100% - 4rem);
margin: 0 auto;

}

.section-container::before {

  margin: 1rem;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);

}
}
.content-row {
  align-items: center;
}


@media (min-width:1024px) {

.section-container {
max-width: 100%;
width: calc(100% - 12rem);
}
}

@media  (min-width: 1500px)  {

.section-container::before {
  margin: 6rem;
padding: 2rem;
  height: calc(100% - 12rem);
  width: calc(100% - 12rem);
}

}

@media (min-width:1024px) {


 {
padding: 12rem 0;
}
}
    `
  },
  {
    display: {
      name: 'Colored Circles',
      documentationURL: 'https://www.notion.so/einsteinindustries/Colored-Circles-1c5dd3f6e8634a19bdfc0a90acd5657e?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-117.png'
    },
    allowedSections: ['textblockimage'],
    notion_IID: '117',
    css: `
    /* Colored Circles on Schemes 7 and 8 --------------- */
@media (min-width: 1024px) {
{
    display: flex;
    max-width: 100vw;
    min-height: 100vh;
}
.section-content {
    position: absolute;
    display: block;
    right: auto;
    left: 0;
    top: 0;
    width: 110vh;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh;
    margin: -5vh 0;
    background: #725279;
    border-radius: 100%;
}
.section-content {
    right: 0;
    left: auto;
}
.column:only-child {
    flex: 1;
    max-width: 100%;
}
}
.section-content .textblock-image-section .column,
.section-content .column.textblock {
    padding: 0 2rem !important;
}
.section-content .textblock-image-section .column.figure {
    display: none;
}
    `
  },
  {
    display: {
      name: 'Textblock Columns: Center images and svgs',
      documentationURL: 'https://www.notion.so/einsteinindustries/Textblock-Columns-Center-images-and-svgs-b566b2fead554444aa25e066860913ff?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/textblock_col_2-IID-51.gif'
    },
    allowedSections: ['textblockcolumns3', 'textblock'],
    notion_IID: '51',
    css: `
      /* Textblock Columns: Center images and svgs  */
svg, p svg, img, p img {
    margin: 1rem auto;
    display: block;
}
    `
  }
]
