import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {useState} from 'react'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/TextblockQuoteCarousel/presets'

export default function TextblockQuoteCarousel(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_icon,
    column_1_richtext,
    column_1_button,
    column_2_title,
    column_2_image,
    column_2_icon,
    column_2_richtext,
    column_2_button,
    outro,
    button,
    className,
    cms,
    quotes,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
  } = props

  const [showIndex, setShowIndex] = useState(0)
  const quotesActive = (quotes && typeof quotes !== 'string' && quotes?.items)

  const bullets = <>
    <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
      {
        quotesActive && (quotes.items ?? [])?.map((quote, index) => {
          return <span
              key={index}
              onClick={() => setShowIndex(index)}
              className={`swiper-pagination-bullet ${index === showIndex % quotes.items?.length && 'swiper-pagination-bullet-active'}`}></span>
        })
      }
    </div>
    <div className="swiper-button-prev" onClick={() => setShowIndex(showIndex <= 0 ? 0 : showIndex - 1)}></div>
    <div className="swiper-button-next" onClick={() => setShowIndex(showIndex + 1)}></div>
  </>

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' textblock-quote-carousel-section stack'}
      name="textblockquotecarousel"
      section_background={section_background}
    >
      <div className="section-container" >
        <div className="section-content textblock_quote_carousel_gutter_row_1 textblock_quote_carousel_gutter_column_1">
          <div className="section-heading">
            <h2>
              <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row content-row section-richtext left-on-top">
            <div className="column column-1">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_1_title} cms={cms} name={'column_1_title'}/></h3>
              <div className="image">
                <SectionImage
                  name="column_1_image"
                  src={column_1_image?.src}
                  mobile={column_1_image?.mobile}
                  alt={column_1_image?.alt}
                  priority={column_1_image?.priority}
                  sizes={column_1_image?.sizes}
                  width={column_1_image?.width}
                  height={column_1_image?.height}
                  cms={cms}
                />
                {
                  column_1_icon && <RichtextWithVariables richtext={column_1_icon} cms={cms} name={'column_1_icon'}/>
                }
              </div>
              <div className="section-richtext" >
                <span className={'paragraph'}><RichtextWithVariables richtext={column_1_richtext} cms={cms} name={'column_1_richtext'}/></span>
              </div>
              <div className="sc-show-inline-carousel section-richtext">
                <div className="testimonials">
                  <div className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
                    <div className="swiper-wrapper">
                      {
                        quotesActive && quotes.items.map((quote, index) => index === (showIndex % quotes.items.length) && <div key={index} dangerouslySetInnerHTML={{__html: quote.quote}}></div>)
                      }
                    </div>
                  </div>
                  {bullets}
                </div>
              </div>
              {
                column_1_button && <div className="column-1-button">
                    <SectionButton cms={cms} href={column_1_button?.url} name={'column_1_button'} label={column_1_button?.label}/>
                  </div>
              }
            </div>
            <div className="column column-2">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_2_title} cms={cms} name={'column_2_title'}/></h3>
              <div className="image">
                <SectionImage
                  name="column_2_image"
                  src={column_2_image?.src}
                  mobile={column_2_image?.mobile}
                  alt={column_2_image?.alt}
                  priority={column_2_image?.priority}
                  sizes={column_2_image?.sizes}
                  width={column_2_image?.width}
                  height={column_2_image?.height}
                  cms={cms}
                />
                {
                  column_2_icon && <RichtextWithVariables richtext={column_2_icon} cms={cms} name={'column_2_icon'}/>
                }
              </div>
              <div className="section-richtext paragraph">
                <span><RichtextWithVariables richtext={column_2_richtext} cms={cms} name={'column_2_richtext'}/></span>
              </div>

              {
                column_2_button && <div className="column-2-button">
                    <SectionButton cms={cms} href={column_2_button?.url} name={'column_2_button'} label={column_2_button?.label}/>
                  </div>
              }
            </div>
          </div>
          <div className="sc-show-full-width-carousel section-richtext">
          </div>
          {
            outro && <div className="section-outro">
                <div className="row">
                  <div className="column section-richtext paragraph">
                    <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
                  </div>
                </div>
              </div>
          }
          {
            button && <div className="section-button-centering">
                <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
              </div>
          }
        </div>
      </div>
    </Section>
  )
}

TextblockQuoteCarousel.StyleCore = styleCore
TextblockQuoteCarousel.presets = presets

