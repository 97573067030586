import {useEffect} from 'react'

interface Options {
  addBodyClass?: Boolean
  display: String | null | undefined
}

const defaultOptions = {
  addBodyClass: false,
  display: 'swap'
}

export type Font = Array<string>

const useGoogleFonts = (
  fonts: Font[],
  options: Options = defaultOptions
): void => {
  const fontsWithSizes = fonts.map((fontArray) => {
    const font = fontArray[0].replace(new RegExp(' ', 'g'), '+')
    let sizes = ''

    if (fontArray.length === 2) {
      sizes = ':' + fontArray[1]
    }

    return font + sizes
  })

  const fontsUri = fontsWithSizes.join('|')
  const swap = `&display=${options.display}`

  useEffect(() => {
    const URL = `https://fonts.googleapis.com/css?family=${fontsUri + swap}`
    // Check if the link already exists
    if (document.querySelector(`link[href="${URL}"]`)) {
      return
    }
    const link = document.createElement('link')
    link.href = URL
    link.rel = 'stylesheet'

    document.head.appendChild(link)
  }, [fonts])
}

export default useGoogleFonts
