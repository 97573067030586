import dynamic from 'next/dynamic'
import React, {useEffect, useMemo, useState} from 'react'
import {useLucidContext} from '@/src/state/ServerSideStore'
import {StyleCoreHelpers} from '@/components/shared/StyleCoreHelper'
import {useOverridesWindowState} from './useOverridesWindowState'
import styled from 'styled-components'
import type {StyleCoreComponentName, StyleCoreOverload, StyleCoreTarget} from '@/components/shared/StyleCore'
import {
  useStyleCoreDispatcher,
  useStyleCoreMap,
  useStyleCorePreRendered,
  useStyleCoreReceiver
} from '@/components/shared/StyleCore'
import type {BackgroundType} from './SectionBackground'
import type {ColorSchemeValues} from '@/components/managers/ColorSchemeManager/ColorScheme'
import type {SectionMeta} from '../../shared/types'
import {useColorScheme} from './colorSchemeHelper'
import createColorSchemeCSS from '@/components/sections/shared/CSSColorScheme'
import StyleManager, {prependClassNameToCSS} from '@/components/sections/shared/StyleManager'
import type {SectionEditorProps} from '@/components/sections/shared/SectionEditor'
import {WindowSizeKey} from './useWindowWidthListener'

type SectionEditorLoader = {SectionEditor: null | React.FunctionComponent<SectionEditorProps>}

const DynamicSectionBackground = dynamic(() => import('./SectionBackground'), {
  ssr: false
})

interface SectionProps {
  color_scheme_id_override: string | null
  children: React.ReactNode
  className?: string
  name: string
  style?: React.CSSProperties
  cms: boolean
  css_overrides?: string
  page_section_id: string,
  meta: SectionMeta,
  section_background?: {
    image?: string
    video?: string
    type?: BackgroundType
    mobile?: {
      src?: string
      transitionPoint?: keyof typeof WindowSizeKey
    }
  }
}

const StyledSection = styled.div<ColorSchemeValues>`
  ${createColorSchemeCSS}
`

export default function Section({...props}: SectionProps) {
  const {
    children,
    className = '',
    name,
    page_section_id,
    css_overrides,
    color_scheme_id_override,
    cms,
    section_background
  } = props

  const [{schemes}] = useLucidContext(cms)

  const styleCoreTarget: StyleCoreTarget = {
    componentName: name as StyleCoreComponentName,
    identifier: page_section_id
  }

  const sectionClassName = [`${name}-section`, className, StyleCoreHelpers.selectors.getSelectorID(styleCoreTarget)].join(' ').trim()

  const styleCoreMap = useStyleCoreMap(cms)
  const styleCoreDispatcher = useStyleCoreDispatcher(cms)
  const meta = Object.assign({}, props.meta)

  if (typeof meta?.style === 'undefined') {
    meta.style = {
      config: {},
      css: '',
      selectors: ''
    }
  }
  meta.style.config = useStyleCoreReceiver(styleCoreTarget, meta?.style.config, cms)

  const prerenderedStyles = useStyleCorePreRendered()

  const [pageSectionBackground, setPageSectionBackground] = useState<string>(section_background?.image ?? '')
  const [backgroundType, setBackgroundType] = useState<BackgroundType>(section_background?.type ?? 'image')
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState<string>(section_background?.mobile?.src ?? '')
  const [mobileTransitionPoint, setMobileTransitionPoint] = useState<keyof typeof WindowSizeKey>(section_background?.mobile?.transitionPoint ?? 'md') 

  const [currentScheme] = useColorScheme(schemes, color_scheme_id_override, cms)

  useEffect(() => {
    if (typeof page_section_id !== 'undefined' && cms && !styleCoreMap.get(styleCoreTarget) && styleCoreMap.getRoot(styleCoreTarget)) {
      styleCoreDispatcher({
        target: styleCoreTarget,
        overloads: [{}]
      })
    }
  }, [])

  useMemo(() => {
    function setInitialValues() {
      if (styleCoreMap.get(styleCoreTarget)) {
        const initialValues = styleCoreMap.getSectionsInitialValues(styleCoreTarget)
        styleCoreDispatcher({
          target: styleCoreTarget,
          overloads: [initialValues as StyleCoreOverload]
        })
      }
    }

    if (typeof page_section_id !== 'undefined' && styleCoreMap.get(styleCoreTarget) && cms) {
      setInitialValues()
    }
  }, [styleCoreTarget.identifier, styleCoreTarget.componentName])

  useEffect(() => {
    if (backgroundType === 'video') setPageSectionBackground(section_background?.video ?? '')
    if (backgroundType === 'image') {
      setPageSectionBackground(section_background?.image ?? '')
      setMobileBackgroundImage(section_background?.mobile?.src ?? '')
      setMobileTransitionPoint(section_background?.mobile?.transitionPoint ?? 'md')
    }

  }, [section_background, backgroundType])

  const cn = `${name}-${Math.random().toString(36).slice(2)}`
  const prefixCss = prependClassNameToCSS(cn, cms, css_overrides)
  const {onActive, onBlur, display, overridesModalIsOpened, setOverrideModalIsOpened} = useOverridesWindowState(cms, page_section_id)

  const [sectionEditorComponent, setSectionEditorComponent] = useState<SectionEditorLoader>({
    SectionEditor: null
  })

  useEffect(() => {
    const loadSectionComponent = async () => {
      const {SectionEditor} = await import('./SectionEditor')
      setSectionEditorComponent({SectionEditor})
    }

    if (cms) {
      loadSectionComponent()
    }
  }, [])

  return (
    <>
      <StyledSection
        onMouseEnter={onActive}
        onMouseLeave={onBlur}
        className={sectionClassName + ` ${cn}`}
        {...currentScheme as ColorSchemeValues}
      >
        <DynamicSectionBackground
          meta={meta}
          className={cn + ' section-image-background section-background'}
          backgroundType={backgroundType}
          pageSectionBackground={pageSectionBackground}
          mobileBackgroundImage={mobileBackgroundImage}
          mobileTransitionPoint={mobileTransitionPoint}
          cms={cms}
        />
        {sectionEditorComponent.SectionEditor &&
          <sectionEditorComponent.SectionEditor
            cms={cms}
            page_section_id={page_section_id}
            color_scheme_id_override={color_scheme_id_override}
            setBackgroundType={setBackgroundType}
            setPageSectionBackground={setPageSectionBackground}
            styleCoreTarget={styleCoreTarget}
            display={display}
            overridesModalIsOpened={overridesModalIsOpened}
            setOverrideModalIsOpened={setOverrideModalIsOpened}
          />
        }
        {children}
        <StyleManager
          cms={cms}
          meta={meta}
          styleCoreTarget={styleCoreTarget}
          prefixCss={prefixCss}
          prerenderedStyles={prerenderedStyles}
          name={name}
        />
      </StyledSection>
    </>
  )
}

