import createInitialStyleCoreElementMap from '@/tina/blocks/createInitialStyleCoreMap'
import {useContext, useEffect, useMemo, useState} from 'react'
import {
  StyleCoreContext,
  type StyleCoreDispatcher,
  type StyleCoreState,
  type StyleCoreStateContents
} from '@/src/state/site/StyleCoreStore'
import deepEqual from '@/src/utils/deepEqual'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {useLucidContext} from '@/src/state/ServerSideStore'
import useSWR, {SWRResponse} from 'swr'
import {GET_SITE_BUILD_TYPEKIT_KITS, GET_SITE_BUILD_TYPOGRAPHY} from '@/graphql/queries'
import {SiteThemeContext, ThemesDispatcher} from '@/src/state/site/ThemeStore'
import Helpers from '@/src/utils/shared/helpers'
import {extractFormManagerContents, FORM_MANAGER_PREFIX} from '@/src/utils/formManagerHelper'
import {useTypeKitFonts} from '@/components/sections/shared/useTypeKitFonts'
import useGoogleFonts, {Font} from '@/components/sections/shared/useGoogleFonts'
import {StyleCoreHelpers} from '@/components/shared/StyleCoreHelper'
import type {
  AcceptedFieldContent,
  DefaultValuesForStyles,
  FieldConfigForStyleCore,
  FieldProcessingFunction,
  FunctionConfig,
  SimpleObject, TypekitKit
} from '@/components/shared/externalTypes'
import {
  TypeKitCSSAndSource,
  TypekitFetched, TypeKitProxyResponse, TypeKitSourceInfo,
  TypographyElement,
  TypographyGroup,
  TypographyGroupMap
} from '@/components/editor/typography/types'
import type {BlockContents, PresetList, Section} from '@/components/shared/types'

export const MUTE_WARNINGS = (process.env.NEXT_PUBLIC_MUTE_WARNINGS === 'true') ?? false

export enum OverloadSlots {
  DEFAULT = 0,
}

export type StyleCoreOverload = SimpleObject

export type TinaFunctionalComponent<P> = React.FunctionComponent<P> & {
  (...args: any): JSX.Element | null,
  StyleCore?: StyleCoreElement
}
export type StyleCoreComponentName = Lowercase<Section['name']>
export type StyleCoreTarget = {
  componentName: StyleCoreComponentName,
  identifier?: string,
}
export type StyleCorePayload = {
  // Any data that needs to be passed to the component
  custom?: {
    [key: string]: any
  }
}

export type InitialStyleCoreConfiguration = {
  styleCoreMap: StyleCoreMap
  styleCorePresetMap: StyleCorePresetMap
}

export type StyleCoreElement = {
  target: StyleCoreTarget,
  css: string,
  config: FieldConfigForStyleCore,
  overloads?: StyleCoreOverload[],
  payload?: StyleCorePayload,
  output?: {
    selectors: string[],
    css: string,
  }
}

export type ThemeAPIRequestBody = {
  theme: string,
  components?: string[]
}

export type StyleDispatcherUpdateType = Partial<StyleCoreElement>
export type StyleCoreMap = Map<string, StyleCoreElement>
export type StyleCoreRenderedMapElement = {
  css: string,
  selectors: string[]
}
export type StyleCorePresetMap = Map<StyleCoreComponentName, PresetList>
export type StyleCoreRenderedMap = Map<string, StyleCoreRenderedMapElement>

type TypographyComponentOptionInput = {
  label: string,
  value: string
}

 export type TypographyFontHookReturn = {
  fonts: Font[],
  options: {
    [key: string]: string | boolean
  }
}

export type TypographyGQLComponent = {
  name: string,
  selectors?: string,
  primary_font_family: string,
  fallback_font_family?: string,
  options?: TypographyComponentOptionInput[],
  id?: string
}

type TypographyGQL = {
  id?: string,
  site_build_id?: string,
  name: string,
  description: string,
  is_default: boolean,
  components: TypographyGQLComponent[]
}

export type TypeKitSource = {
  page_build_id: string[]
  typekit_id: string
}

/**
 * The following CSS is applied to all components that are not explicitly styled by StyleCore
 * Any component that has styling interference should be targeted here.
 */
const STYLING_RESET_TARGETS = `
  {target}.section blockquote {
        all: unset;
  }
`

/**
 * StyleCore is a system for managing styles in a React app.
 * Extensive documentation is available here https://www.notion.so/einsteinindustries/StyleCore-b9b1fef4352b4e10a523517d6e4b7d62?pvs=4
 *
 */

function extractFieldFromConfig(objectOrField: FieldConfigForStyleCore | AcceptedFieldContent, fieldName: string, addUnit: boolean): DefaultValuesForStyles | AcceptedFieldContent {
  const structure: DefaultValuesForStyles = {}
  if (typeof objectOrField !== 'object') {
    return
  }
  for (const [key, value] of Object.entries(objectOrField as FieldConfigForStyleCore)) {
    if (key === fieldName) {
      if (addUnit) {
        return `${value}${(objectOrField as FieldConfigForStyleCore)?.unit ?? ''}` as AcceptedFieldContent
      } else {
        return value as AcceptedFieldContent
      }
    }
    if (key === 'content') {
      if (typeof value === 'object' && value !== null) {
        return extractFieldFromConfig(value as FieldConfigForStyleCore, fieldName, addUnit)
      }
    } else {
      structure[key] = extractFieldFromConfig(value as FieldConfigForStyleCore, fieldName, addUnit)
    }
  }
  return structure
}

function getTargetName(target: string[]) {
  return target.map((item, index) => {
    return `.${item}`
  }).join(' ')
}

function applyAndReplaceTargets(css: string, targetName: string) {
  let newCss = String(css)
  if (css.match(/{target}/)) {
    newCss = css.replaceAll(/{target}/g, targetName)
  } else if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The CSS string for ${targetName} does not contain a {target} and will have a global effect, which may be unintended.`)
  }
  return newCss
}

function convertTypographyElementToCss(element: TypographyElement) {
  const {fonts, mobileFontSize, desktopFontSize, ...rest} = element.configuration
  let css = Object.entries(rest).map(([key, value]) => {
    return `${key.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('-')}: ${value};`
  }).join(' ')
  if (element.configuration.fonts) {
    css += `
      font-family: ${element.configuration.fonts.primary.name}, ${element.configuration.fonts.fallback.name}, sans-serif;
    `
  }
  return css
}

function RenderTypography(overloads: StyleCoreOverload[], typographyMap: TypographyGroupMap) {
  let typographyGroup: TypographyGroup
  try {
    typographyGroup = typographyMap.entries().next().value[1]
    const defaultTypographyGroup = Array.from(typographyMap.entries()).map(e => e[1]).find(e => e.isDefault)
    if (typeof defaultTypographyGroup !== 'undefined') {
      typographyGroup = defaultTypographyGroup
    }
  } catch (e) {
    return ''
  }
  for (const overload of overloads) {
    const overloadGeneral = overload?.general as StyleCoreOverload
    if (typeof overloadGeneral?.typography === 'object' && typeof overloadGeneral?.typography?.typographyOverride === 'string') {
      const foundElement = typographyMap.get(overloadGeneral?.typography?.typographyOverride)
      if (foundElement) {
        typographyGroup = foundElement as TypographyGroup
      }
    }
  }

  let css = ''
  for (const [, value] of Object.entries(typographyGroup.elements)) {
    const minFontInteger = value.configuration.mobileFontSize?.toString().replace(/[^0-9]/g, '')
    const maxFontInteger = value.configuration.desktopFontSize?.toString().replace(/[^0-9]/g, '')
    css += `
      ${StyleCoreHelpers.selectors.getTypographyBaseSelector(value)} {
         ${convertTypographyElementToCss(value)}
      }
      
      @supports(font-size: clamp(1rem, 1vw, 1.5rem)) {
        ${StyleCoreHelpers.selectors.getTypographyBaseSelector(value)} {
          font-size: clamp(${value.configuration.mobileFontSize}, calc(${value.configuration.mobileFontSize} + ((${maxFontInteger} - ${minFontInteger}) * (100vw - 1024px) / (1440 - 1024))), ${value.configuration.desktopFontSize});
        }
      }
    `
  }
  return css
}

export function addCSSReset(currentCSS: string) {
  return `
    ${STYLING_RESET_TARGETS}
    ${currentCSS}
  `
}

function matchFieldsAndApplyFunction(formState: SimpleObject, functions: FunctionConfig, originalState: SimpleObject): SimpleObject {
  const modifiedState: SimpleObject = {}

  if (typeof functions === 'undefined') {
    return formState
  }

  for (const [key, value] of Object.entries(formState)) {
    if (typeof value === 'object' && value !== null ) {
      modifiedState[key] = matchFieldsAndApplyFunction(value as StyleCoreOverload, functions[key] as FunctionConfig, originalState)
    } else if (typeof functions[key] === 'function') {
      modifiedState[key] = (functions[key] as FieldProcessingFunction)(originalState, String(value))
    }
  }

  return modifiedState
}

export function RenderStyle({target, css, config, overloads}: StyleCoreElement, typographyMap: TypographyGroupMap) {
  const {componentName, identifier} = target

  let newCss = String(css)
  function replaceVariables(obj: any, path: string) {
    for (const key in obj) {
      let value = obj[key]
      if (typeof value === 'object' && value !== null) {
        replaceVariables(value, (path === '' ? key : path + '.' + key))
      } else {
        if (value === null || value === '') {
          continue
        }
        const variable = `\\{${path + '\\.' + key}\\s*\\?\\s*(\\{[^{}]*\\}|[^{}]+)\\s*:\\s*(\\{[^{}]*\\}|[^{}]+)\\}`
        if (newCss.match(new RegExp(variable, 'g'))) {
          if (typeof value !== 'boolean') {
            value = value === 'true'
          }
          newCss = newCss.replaceAll(new RegExp(variable, 'g'), value ? '$1' : '$2')
        } else {
          newCss = newCss.replaceAll(new RegExp('{' + path + '.' + key + '}', 'g'), value)
        }
      }
    }
  }

  let overloadsGrouped: StyleCoreOverload[] = [extractFieldFromConfig({...config}, 'default', true) as StyleCoreOverload, ...(overloads ?? [])]
  const overloadsMerged = mergeOverloads(overloadsGrouped.reverse())
  overloadsGrouped = [...overloadsGrouped, matchFieldsAndApplyFunction(overloadsMerged, extractFieldFromConfig({...config}, 'postProcessing', false) as FunctionConfig, overloadsMerged)]
  function applyOverloads(replaceVariables: (obj: any, path: string) => void, overloads: StyleCoreOverload[] | undefined) {
    if (overloads) {
      for (const overload of overloads.reverse()) {
        replaceVariables(overload, '')
      }
    }
  }

  const fontCSSString = RenderTypography(overloadsGrouped, typographyMap)

  newCss += fontCSSString

  newCss += addCSSReset(newCss)

  newCss = applyAndReplaceTargets(newCss, getTargetName([StyleCoreHelpers.selectors.getSelectorID(target)])) ?? ''

  applyOverloads(replaceVariables, overloadsGrouped)

  newCss = applyAndReplaceTargets(newCss, getTargetName([StyleCoreHelpers.selectors.getSelectorID(target)])) ?? ''

  if (newCss.match(/{target}/)) {
    throw new Error(`Lucid StyleCore: The following targets for ${componentName}${typeof identifier !== 'undefined' ? '.' + identifier : ''} were not found in the config or overloads: {target}`)
  }

  if (newCss.match(/{.*}/) && process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The following variables for ${componentName}${typeof identifier !== 'undefined' ? '.' + identifier : ''}
    were not found in the config or overloads: ${newCss.match(/{.*}/)}. This line of CSS will be removed.`)
  }

  newCss = newCss.replaceAll(/(^.*?{[^{}]+?\.(?!\d)[^{}]+?}.*?$)/gm, '')

  return newCss
}

export function convertTargetToKey(target: StyleCoreTarget) {
  return `${target.componentName}${target.identifier ? '.' + target.identifier : ''}`
}

async function fetchSiteTheme(themeName:string, components = []) {
  let body: ThemeAPIRequestBody = {
    theme: themeName,
    components: components
  }
  if (components.length === 0) {
    body = {
      theme: themeName
    }
  }
  const response = await fetch('/api/themes', {
    method: 'POST',
    body: JSON.stringify(body),
  })
  if (response.status === 200) {
    return await response.json()
  }
  return null
}

async function initializeTheme(theme: string, dispatch: ThemesDispatcher) {
  const themeContents = await fetchSiteTheme(theme)
  if (themeContents) {
    dispatch({
      type: 'UPDATE',
      payload: {
        themeName: theme,
        components: themeContents
      }
    })
  }
}

function mergeOverload(overload: StyleCoreOverload, mergedOverload: StyleCoreOverload) {
  for (const [key, value] of Object.entries(overload)) {
    if (typeof value === 'object' && value !== null) {
      if (typeof mergedOverload[key] === 'undefined') {
        mergedOverload[key] = {}
      }
      mergeOverload(value, mergedOverload[key] as StyleCoreOverload)
    } else if (typeof value !== 'undefined' && value !== null && value !== '') {
        mergedOverload[key] = Helpers.convert.convertStringToPrimitive(String(value)) as string | number | boolean
    }
  }
}

export function mergeOverloads(overloads: StyleCoreOverload[]) {
  const mergedOverloads: StyleCoreOverload = {}
  for (const overload of overloads.reverse()) {
    mergeOverload(overload, mergedOverloads)
  }
  return mergedOverloads
}

export function initializeStyleCoreSections(StyleCore: StyleCoreStateContents, dispatch: StyleCoreDispatcher, cms: boolean) {
  if (StyleCore.map.size === 0 && cms) {
    const initialMap: InitialStyleCoreConfiguration  = createInitialStyleCoreElementMap() as InitialStyleCoreConfiguration
    StyleCore.map = initialMap.styleCoreMap
    StyleCore.presets = initialMap.styleCorePresetMap
    StyleCore.initialized = true
    dispatch({
      type: 'UPDATE',
      payload: {
        StyleCore
      }
    })
  }
}

export function useStyleCoreDispatcher(cms: boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)

  initializeStyleCoreSections(StyleCore, dispatch, cms)
  if (!StyleCore.initialized || !cms) return (config: StyleDispatcherUpdateType) => {}
  return (config: StyleDispatcherUpdateType) => {
    if (typeof config.target === 'undefined') {
      throw new Error('StyleCore: The target is undefined')
    }

    const targetAsKey = convertTargetToKey(config.target)

    // We check if the target is already registered
    if (StyleCore.map.has(targetAsKey)) {
      // If the target is a global target (no identifier), we update all the targets with the same name, we update the first overload
      if (typeof config.target.identifier === 'undefined' && typeof config.overloads !== 'undefined') {
        for (const [key, value] of StyleCore.map.entries()) {
          if (key === targetAsKey) {
            if (typeof value.overloads === 'undefined') {
              value.overloads = []
            }
            value.overloads[OverloadSlots.DEFAULT] = config.overloads[0]
          }
        }
      }

      // If the target is a local target (with identifier), we add an overload
      if (typeof config.target.identifier !== 'undefined') {

        const recalledTarget = StyleCore.map.get(targetAsKey) as StyleCoreElement

        // We then add the overload if the field is not initialized
        if (typeof recalledTarget?.overloads === 'undefined') {
          recalledTarget!.overloads = []
        }
        // If there is no default overload, we add it
        if (typeof recalledTarget?.overloads[OverloadSlots.DEFAULT] === 'undefined') {
          recalledTarget!.overloads[OverloadSlots.DEFAULT] = {}
        }
        recalledTarget!.overloads[OverloadSlots.DEFAULT] = config.overloads![0]
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          StyleCore
        }
      })
    } else {

      if (typeof config.target.identifier !== 'undefined' && (typeof config.config === 'undefined' || typeof config.css === 'undefined')) {
        const targetWithoutIdentifier = StyleCore.map.get(convertTargetToKey({
          componentName: config.target.componentName
        }))
        if (typeof targetWithoutIdentifier === 'undefined') {
          throw new Error('Lucid StyleCore: A new partial target cannot be registered without a global target.')
        }

        config.css = config.css ?? targetWithoutIdentifier.css
        config.config = config.config ?? targetWithoutIdentifier.config
      }

      if (typeof config.css === 'undefined') {
        throw new Error('Lucid StyleCore: A new target cannot be registered without a CSS string.')
      }

      if (typeof config.config === 'undefined') {
        throw new Error('Lucid StyleCore: A new target cannot be registered without a CSS string.')
      }

      // If the target is not registered, we add it
      StyleCore.map.set(targetAsKey, config as StyleCoreElement)
      dispatch({
        type: 'UPDATE',
        payload: {
          StyleCore
        }
      })
    }
  }
}

export function useStyleCoreTheme(themeName: string) {
  const [, dispatch] = useContext(SiteThemeContext)
  useEffect(() => {
    initializeTheme(themeName, dispatch)
  }, [themeName])
}

export function useStyleCoreMap(cms:boolean) {
  const [{StyleCore, section_contents}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  if (!cms) return {
    get: (target: StyleCoreTarget) => undefined,
    set: (target: StyleCoreTarget, value: StyleCoreElement) => {},
    getRoot: (target: StyleCoreTarget) => '',
    setRoot: (target: StyleCoreTarget, config: StyleCoreElement) => {},
    asArray: () => [],
    getSectionsInitialValues: (target: StyleCoreTarget): BlockContents => ({})
  }
  return {
    get: (target: StyleCoreTarget) => StyleCore.map.get(convertTargetToKey(target)),
    set: (target: StyleCoreTarget, value: StyleCoreElement) => StyleCore.map.set(convertTargetToKey(target), value),
    getRoot: (target: StyleCoreTarget) => StyleCore.map.get(convertTargetToKey({componentName: target.componentName})),
    setRoot: (target: StyleCoreTarget, config: StyleCoreElement) => StyleCore.map.set(convertTargetToKey({componentName: target.componentName}), config),
    asArray: () => Array.from(StyleCore.map.values()),
    getSectionsInitialValues: (target: StyleCoreTarget): BlockContents => {
      const formattedContentData = extractFormManagerContents(section_contents[target.identifier as string] ?? [])
      const {[`${target.componentName}::ST${target.identifier}`]: initialData = {}} = (formattedContentData?.[FORM_MANAGER_PREFIX] ?? {}) as BlockContents
      return initialData as BlockContents
    }
  }
}

export function useStyleCorePayload(target: StyleCoreTarget, cms:boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  return {
    get: () => StyleCore.map.get(convertTargetToKey(target))?.payload,
    set: (value: StyleCorePayload) => {
      const foundTarget = StyleCore.map.get(convertTargetToKey(target)) as StyleCoreElement
      foundTarget.payload = value
    },
    getRoot: () => StyleCore.map.get(convertTargetToKey({componentName: target.componentName}))?.payload,
    setRoot: (value: StyleCorePayload) => {
      const foundTarget = StyleCore.map.get(convertTargetToKey({componentName: target.componentName})) as StyleCoreElement
      foundTarget.payload = value
    }
  }
}

export function useStyleCoreGlobalTheme() {
  const [theme] = useContext(SiteThemeContext)
  return theme.components['global'] ?? ''
}

export function useStyleCorePreRendered() {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  return StyleCore.rendered.map ?? new Map()
}

export function useStyleCoreReceiver(target: StyleCoreTarget, incomingStyles: StyleCoreOverload | undefined, cms:boolean): StyleCoreOverload {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  if (!cms) return Helpers.convert.objectValuesToPrimitive(incomingStyles ?? {}) as StyleCoreOverload
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  const targetAsKey = convertTargetToKey(target)
  const foundTarget = StyleCore.map.get(targetAsKey) ? {
    ...StyleCore.map.get(targetAsKey)
  } as StyleCoreElement : undefined
  const baseTarget = StyleCore.map.get(convertTargetToKey({componentName: target.componentName}))
  const arrayOfOverloads = [extractFieldFromConfig({...(baseTarget?.config ?? {})}, 'default', true) as DefaultValuesForStyles,...(baseTarget?.overloads ?? []), ...(foundTarget?.overloads ?? [])].reverse()
  return Helpers.convert.objectValuesToPrimitive(mergeOverloads(arrayOfOverloads as StyleCoreOverload[])) as StyleCoreOverload
}

export function useStyleCoreRenderer(target: StyleCoreTarget, cms:boolean): [string[], string] {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)

  const targetAsKey = convertTargetToKey(target)

  let foundTarget = StyleCore.map.get(targetAsKey) ? {
    ...StyleCore.map.get(targetAsKey)
  } as StyleCoreElement : undefined

  const captureRender = (data: [string[], string]) => {
    if (typeof target.identifier !== 'undefined') {
      const currentState = StyleCore.rendered.map.get(target.identifier)
      if (typeof currentState === 'undefined' || currentState.css !== data[1]) {
        StyleCore.rendered.map.set(target.identifier, {
          css: data[1],
          selectors: [JSON.stringify(data[0])]
        })
      }
    }
    return data
  }

  if (typeof target.identifier !== 'undefined') {
    const targetWithoutIdentifier = StyleCore.map.get(convertTargetToKey({...target, identifier: undefined}))
    if (typeof targetWithoutIdentifier !== 'undefined') {

      if (typeof foundTarget === 'undefined') {
        foundTarget = targetWithoutIdentifier
      } else {
        const newOverloads = targetWithoutIdentifier.overloads ?? [{}]
        foundTarget.overloads = [newOverloads[0], ...(foundTarget.overloads ?? [])]
      }

    }
  }

  if (typeof foundTarget === 'undefined') {
    if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
      console.warn(`Lucid StyleCore: The target ${targetAsKey} was not found in the StyleCore map.`)
    }

    return captureRender([[''],''])
  }
  if (typeof foundTarget.overloads !== 'undefined') {
    foundTarget.overloads = foundTarget.overloads.filter((overload) => Object.entries(overload).length !== 0)
    return captureRender([StyleCoreHelpers.selectors.get(foundTarget.target), RenderStyle(foundTarget, StyleCore.typography.map)])
  }

  if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The target ${targetAsKey} was not found in the StyleCore map.`)
  }

  return captureRender([[''], ''])

}

export const getFontNamesAsFlatArrayFromStyleCore = ({StyleCore}: Pick<StyleCoreState, 'StyleCore'>) => {
  const fonts: Font[] = []
  for (const [key, value] of StyleCore.typography.map.entries()) {
    const shouldBeAddedToFonts = (name: string | undefined) => fonts.findIndex((font) => font[0] === name) === -1 && typeof name !== 'undefined'
    value.elements.forEach((element) => {
      if (shouldBeAddedToFonts(element.configuration.fonts.primary.name))
        fonts.push([element.configuration.fonts.primary.name])
      if (shouldBeAddedToFonts(element.configuration.fonts.fallback.name))
        fonts.push([element.configuration.fonts.fallback.name])
    })
  }
  return fonts
}

/**
 * Imports all referenced Google fonts
 */
export function useStyleCoreTypographyImports(cms: boolean, enabled: boolean): TypographyFontHookReturn {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  useMemo(() => initializeStyleCoreSections(StyleCore, dispatch, cms), [StyleCore.map.size])
  const [fonts, setFonts] = useState<Font[]>([])

  const fontsGathered = getFontNamesAsFlatArrayFromStyleCore({StyleCore})

  useMemo(() => {
    if (JSON.stringify(fonts) !== JSON.stringify(fontsGathered) && fontsGathered.length > 0) {
      setFonts(fontsGathered)
    }
  }, [JSON.stringify(Array.from(StyleCore.typography.map.entries()))])

  useGoogleFonts(enabled ? fonts : [], {display: 'swap', addBodyClass: true})
  useTypeKitFonts(StyleCore.typography.typekitKits)

  return {
    fonts,
    options: {display: 'swap', addBodyClass: true}
  }
}

function transformGQLRequestToTypographyGroup(input: TypographyGQL) : TypographyGroup {
  return {
    id: `${input.id}`,
    name: input.name,
    description: input.description,
    isDefault: input.is_default,
    elements: input.components.map((component) => {
      return {
        id: `${component.id}`,
        name: component.name,
        selectors: component.selectors,
        configuration: {
          fonts: {
            primary: {
              name: component.primary_font_family
            },
            fallback: {
              name: component.fallback_font_family ?? component.primary_font_family
            }
          },
          ...Object.fromEntries(component.options?.map((option) => {
            return [option.label, option.value]
          }) ?? [])
        }
      } as TypographyElement
    })
  }
}


async function getProjectInfoFromKits(url: string, kits: {data: string[]}): Promise<TypeKitProxyResponse> {
  if (!kits.data || kits.data.length === 0) {
    return {}
  }

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({kits: kits.data}),
    headers: {'Content-Type': 'application/json'},
  })

  if (!response.ok) {
    throw new Error('Error while fetching Typekit project info')
  }
  const data = await response.json()
  return data
}

export function useStyleCoreTypographyServerState(cms: boolean, enabled: boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const [SiteStore] = useLucidContext(cms)
  let fetchKeyTypography = null
  let fetchKeyTypekitKits = null
  if (SiteStore.site_build_id !== '') {
    fetchKeyTypography = [GET_SITE_BUILD_TYPOGRAPHY, {site_build_id: SiteStore.site_build_id}]
    fetchKeyTypekitKits = [GET_SITE_BUILD_TYPEKIT_KITS, {site_build_id: SiteStore.site_build_id}]
  }

  const {
    data: typographyData,
  }: SWRResponse<{data: {typographies: TypographyGQL[]}}> = useSWR(
    fetchKeyTypography,
    lucidDataFetcherV2 as (query: string, variable?: {}) => Promise<{data: {typographies: TypographyGQL[]}}>,
    {revalidateOnFocus: SiteStore.revalidateOnFocus}
  )

  const {
    data: typekitKitsData,
  }: SWRResponse<{data: {siteBuild: {typekit_kits: TypeKitSource[]}}}> = useSWR(
    fetchKeyTypekitKits,
    lucidDataFetcherV2 as (query: string, variable?: {}) => Promise<{data: {siteBuild: {typekit_kits: TypeKitSource[]}}}>,
    {revalidateOnFocus: SiteStore.revalidateOnFocus}
  )

  const typekitKits = typekitKitsData?.data?.siteBuild?.typekit_kits ?? []
  const data = useSWR(['/api/typekit-proxy', {data: typekitKits.map(e => e.typekit_id)}], getProjectInfoFromKits, {
    fallbackData: {},
    suspense: true,
  })


  if (!typographyData?.data?.typographies || !enabled) {
    return
  }
  const typographyMap = new Map<string, TypographyGroup>()
  typographyData.data.typographies.forEach((value) => {
    const id = value.id
    if (typeof id !== 'undefined') {
      typographyMap.set(id, transformGQLRequestToTypographyGroup(value))
    }
  })

  let hasChanges = false
  for (const [key, value] of StyleCore.typography.map.entries()) {
    const foundTypographyGroup = typographyMap.get(key)
    if (typeof foundTypographyGroup === 'undefined') {
      hasChanges = true
      break
    }
    if (!deepEqual(foundTypographyGroup, value)) {
      hasChanges = true
      break
    }
  }

  for (const [key, value] of typographyMap.entries()) {
    const foundTypographyGroup = StyleCore.typography.map.get(key)
    if (typeof foundTypographyGroup === 'undefined') {
      hasChanges = true
      break
    }
    if (!deepEqual(foundTypographyGroup, value)) {
      hasChanges = true
      break
    }
  }


  if (JSON.stringify(StyleCore.typography.typekitKits.map(e => e.typekit_id)) !== JSON.stringify(typekitKitsData?.data?.siteBuild?.typekit_kits?.map(e => e.typekit_id) ?? [])) {
    hasChanges = true
  }

  if (Object.entries(data.data ?? {}).length > 0 && (Object.values(data.data ?? {})).filter(e => {
    const foundKit = StyleCore.typography.typekitKits.find(kit => kit.typekit_id === e?.kit?.id && e?.kit?.name === kit?.kit?.name)
    return typeof foundKit === 'undefined'
  }).length > 0) {
    hasChanges = true
  }

  if (!hasChanges) {
    return
  }

  function matchTypographyGroupsToTypekitKits(kit: TypeKitCSSAndSource) {
    if (!kit) {
      return []
    }
    const typographyGroups = Array.from(typographyMap.values())
    return typographyGroups.filter(group => {
      return group.elements.findIndex(element => {
        return kit.kit?.families.map(e => e.slug).includes(element.configuration.fonts.primary.name)
      }) !== -1
    }).map(e => e.id)
  }

    dispatch({
      type: 'UPDATE',
      payload: {
        StyleCore: {
          ...StyleCore,
          typography: {
            map: typographyMap,
            typekitKits: typekitKits.map(e => {
              const foundKit = (Object.values(data.data ?? {})).find(kit => kit.kit.id === e.typekit_id)
              if (foundKit) {
                return {
                  ...e,
                  ...foundKit,
                  status: {
                    loading: false,
                    error: false
                  },
                  usedInGroups: matchTypographyGroupsToTypekitKits(foundKit)
                }
              }
              const preexistingDefinition = StyleCore.typography.typekitKits.find(k => k.typekit_id === e.typekit_id)
              return {
                ...e,
                ...(preexistingDefinition ?? {}),
                status: {
                  loading: false,
                  error: true
                },
                usedInGroups: preexistingDefinition ? matchTypographyGroupsToTypekitKits(preexistingDefinition) : []
              }
            }) as TypekitFetched[]
          }
        }
      }
    })
}

export function useStyleCorePresets({componentName}: StyleCoreTarget) : [(PresetList)] {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const presets = StyleCore.presets.get(convertTargetToKey({componentName}) as StyleCoreComponentName)
  return [(presets ?? [])]
}
