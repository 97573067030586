import type {BlockContents} from '@/components/shared/types'

/**
 * parseContentVariables replaces variables of the form `$variable.prop ` (note the trailing space) in content with values of the form `{variable: {prop: value}}`
 * from contentVariables without using regex. If the variable is not found in contentVariables, it is left as is
 */
export function parseContentVariables(content: string, contentVariables: BlockContents, previouslyParsedString: string = ''): string {
  if (!content) return previouslyParsedString
  const variableStart = content.indexOf('$')
  if (variableStart === -1) {
    return previouslyParsedString + content
  }

  const endingChars = [' ', ',', '\'', ';', '!', '?', ')', ']', '}', ':', '\t', '\n', '<']
  const variableEnd = endingChars.reduce((acc, char) => {
    const index = content.indexOf(char, variableStart)
    if (index === -1) return acc
    if (acc === -1) return index
    return Math.min(acc, index)
  }, -1)

  const variable = content.substring(variableStart + 1, variableEnd === -1 ? content.length : variableEnd)
  const variableValue = getVariableValue(variable, contentVariables)
  if (typeof variableValue === 'undefined') {
    return parseContentVariables(content.substring(variableStart + 1), contentVariables, previouslyParsedString + content.substring(0, variableStart + 1))
  }

  const newAccumulator = previouslyParsedString + content.substring(0, variableStart) + variableValue
  return parseContentVariables(content.substring(variableEnd === -1 ? content.length : variableEnd), contentVariables, newAccumulator)
}

function getVariableValue(variable: string, contentVariables: BlockContents): string | undefined {
  const delimiter: number = variable.indexOf('.')
  const currentKey: string = delimiter > -1 ? variable.substring(0, delimiter) : variable.substring(0)
  const rest = variable.substring(delimiter + 1)
  const value = contentVariables?.[currentKey] as string | BlockContents

  if (typeof value === 'object') {
    return getVariableValue(rest, value)
  }

  return value
}
