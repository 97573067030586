import {MarginFields} from '@/components/sections/shared/stylecore/fields/MarginFields'
import {PaddingFields} from '@/components/sections/shared/stylecore/fields/PaddingFields'
import {BreakpointOptions} from '@/components/sections/shared/stylecore/options/BreakpointOptions'
import {windowSize, WindowSizeKey} from '@/components/sections/shared/useWindowWidthListener'

const basicTextFields = ['heading-1', 'heading-2', 'heading-3', 'heading-4', 'heading-5', 'heading-6', 'paragraph', 'link', 'small']
const specializedTextFields = ['title', 'subtitle', 'description']
const allTextFields = [...basicTextFields, ...specializedTextFields]
function generateTextFieldMarginAndPaddingFields(prefix = '') {
  const fields = {}
  allTextFields.forEach((field) => {
    const defaults = specializedTextFields.includes(field) ? {
      padding: {
        unit: '',
        topPadding: 'inherit',
        rightPadding: 'inherit',
        bottomPadding: 'inherit',
        leftPadding: 'inherit',
      },
      margin: {
        unit: '',
        topMargin: 'inherit',
        rightMargin: 'inherit',
        bottomMargin: 'inherit',
      }
    } : {
      padding: {},
      margin: {}
    }
    fields[field] = {
      content: {
        mobileOverride: {
          content: {
            ...PaddingFields.createFields(defaults.padding, prefix + field + '.mobileOverride.'),
            ...MarginFields.createFields(defaults.margin, prefix + field + '.mobileOverride.')
          }
        },
        defaultSizing: {
          content: {
            ...PaddingFields.createFields(defaults.padding, prefix + field + '.defaultSizing.'),
            ...MarginFields.createFields(defaults.margin, prefix + field + '.defaultSizing.')
          }
        }
      }
    }
  })
  return fields
}

function generateTextFieldCSS(prefix = '') {
  return allTextFields.map((field) => `
      {target} .${field} {
        ${PaddingFields.createPartialCSS(prefix + field + '.defaultSizing.')}
        ${MarginFields.createPartialCSS(prefix + field + '.defaultSizing.')}
      }
      
      @media (max-width: {${prefix + 'mobileBreakpoint'}}px) {
        {target} .${field} {
          ${PaddingFields.createPartialCSS(prefix + field + '.mobileOverride.')}
          ${MarginFields.createPartialCSS(prefix + field + '.mobileOverride.')}
        }
      }
    `).join('')
}



export const Defaults = {
  createFields(defaults = {}, prefix = '') {
    const defaultFields = {
      section: {
        background: {
          color: '',
          image: '',
          repeat: 'no-repeat',
          size: 'cover',
          horizontalPosition: 'center',
          verticalPosition: 'center',
          horizontalOffset: '',
          verticalOffset: '',
          attachment: 'scroll',
          opacity: 1,
        },
        animations: {
          section: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
          },
          text: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
            appliesTo: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6, {target} .paragraph, {target} a, {target} span',
            stagger: true,
          },
          background: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
          }
        }
      },
    }
    const animationTypes = [
      {
        'label': 'None',
        'value': 'none'
      },
      {
        'label': 'Fade In Up',
        'value': 'fadeInUp'
      },
      {
        'label': 'Fade In',
        'value': 'fadeIn'
      }
    ]
    const fields = Object.assign(defaultFields, defaults)
    return {
      section: {
        content: {
          animations: {
            content: {
              section: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.section.enableAnimation,
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.section.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.section.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.section.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                }
              },
              text: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.text.enableAnimation,
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.text.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.text.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  stagger: {
                    content: 'toggle',
                    default: fields.section.animations.text.stagger,
                    description: 'Stagger the animation of the text elements',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : false
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.text.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  appliesTo: {
                    content: 'select',
                    default: fields.section.animations.text.appliesTo,
                    options: [
                      {
                        label: 'All',
                        value: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6, {target} .paragraph, {target} a, {target} span'
                      },
                      {
                        label: 'Headings',
                        value: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6'
                      },
                      {
                        label: 'Paragraph',
                        value: '{target} .paragraph'
                      }
                    ]
                  }
                }
              },
              background: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.background.enableAnimation,
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.background.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.background.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.background.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  }
                }
              },
            }
          },
          background: {
            content: {
              color: {
                content: 'color',
                default: fields.section.background.color,
                description: 'The background color of the section',
              },
              repeat: {
                content: 'select',
                default: fields.section.background.repeat,
                options: [
                  {value: 'no-repeat', label: 'No Repeat'},
                  {value: 'repeat', label: 'Repeat'},
                  {value: 'repeat-x', label: 'Repeat X'},
                  {value: 'repeat-y', label: 'Repeat Y'},
                ],
                description: 'The background repeat of the section',
              },
              size: {
                content: 'select',
                default: fields.section.background.size,
                options: [
                  {value: 'cover', label: 'Cover'},
                  {value: 'contain', label: 'Contain'},
                ],
                description: 'The background size of the section',
              },
              attachment: {
                content: 'select',
                default: fields.section.background.attachment,
                options: [
                  {value: 'scroll', label: 'Scroll'},
                  {value: 'fixed', label: 'Fixed'},
                ],
                description: 'The background attachment of the section',
              },
              horizontalPosition: {
                content: 'select',
                default: fields.section.background.horizontalPosition,
                options: [
                  {value: 'center', label: 'Center'},
                  {value: 'left', label: 'Left'},
                  {value: 'right', label: 'Right'},
                ],
                description: 'The horizontal position of the background image',
              },
              verticalPosition: {
                content: 'select',
                default: fields.section.background.position,
                options: [
                  {value: 'center', label: 'Center'},
                  {value: 'top', label: 'Top'},
                  {value: 'bottom', label: 'Bottom'},
                ],
                description: 'The vertical position of the background image',
              },
              horizontalOffset: {
                content: 'number',
                default: fields.section.background.horizontalOffset,
                description: 'Has no effect when the horizontal background position is set to center',
                unit: 'px',
              },
              verticalOffset: {
                content: 'number',
                default: fields.section.background.verticalOffset,
                description: 'Has no effect when the vertical background position is set to center',
                unit: 'px',
              },
              opacity: {
                content: 'number',
                default: fields.section.background.opacity,
                description: 'The opacity of the background image',
                min: 0,
                max: 1
              }
            }
          }
        }
      },
      typography: {
        content: {
          typographyOverride: {
            label: 'Typography Group',
            content: 'typography',
            default: 'default',
          },
          spacings: {
            content: {
              mobileBreakpoint: {
                content: 'select',
                default: windowSize[WindowSizeKey.sm],
                options: BreakpointOptions,
                description: 'The breakpoint at which the mobile styles will be applied',
                message: {
                  state: 'warning',
                  message: 'Note that the mobile breakpoint is additive, so, unless overwritten in the mobile styles, the desktop styles will be applied to all screen sizes below the selected breakpoint.'
                }
              },
              ...generateTextFieldMarginAndPaddingFields(prefix + 'typography.spacings.')
            }
          }
        },
      }
    }

  },
  createCSS(prefix = '') {
    return `
      @keyframes fadeInUp {
        from {
          transform: translate3d(0,40px,0)
        }
        to {
          transform: translate3d(0,0,0);
          opacity: 1
        }
      }
      @-webkit-keyframes fadeInUp {
        from {
          transform: translate3d(0,40px,0)
        }
        to {
          transform: translate3d(0,0,0);
          opacity: 1
        }
      }
      
      @keyframes fadeIn {
        from {
          opacity: 0
        }
        to {
          opacity: 1
        }
      }
      @-webkit-keyframes fadeInUp {
        from {
          opacity: 0
        }
        to {
          opacity: 1
        }
      }
      
      {target} .section-container {
        box-sizing: border-box;
      }
      
      {target} .section-background {
        background-color: {${prefix}section.background.color};
      }
      
      {target} {
        opacity: {${prefix}section.animations.section.enableAnimation ? 0 : 1};
        animation-name: {${prefix}section.animations.section.animationType};
        animation-duration: {${prefix}section.animations.section.animationDuration}s;
        animation-delay: {${prefix}section.animations.section.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.section.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.section.animationType};
        -webkit-animation-duration: {${prefix}section.animations.section.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.section.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.section.enableAnimation ? both : none};
      }
      
      {${prefix}section.animations.text.appliesTo} {
        opacity: {${prefix}section.animations.text.enableAnimation ? 0 : 1};
        animation-name: {${prefix}section.animations.text.animationType};
        animation-duration: {${prefix}section.animations.text.animationDuration}s;
        animation-delay: {${prefix}section.animations.text.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.text.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.text.animationType};
        -webkit-animation-duration: {${prefix}section.animations.text.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.text.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.text.enableAnimation ? both : none};
      }
      
      {target} .heading-1 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.1s : 0s};
      }
      
      {target} .heading-2 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.3s : 0s};
      }
      
      {target} .heading-3 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.5s : 0s};
      }
      
      {target} .heading-4 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.7s : 0s};
      }
      
      {target} .heading-5 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.9s : 0s};
      }
      
      {target} .heading-6 {
        animation-delay: {${prefix}section.animations.text.stagger ? 1.1s : 0s};
      }
      
      {target} .paragraph, {target} a {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.7s : 0s};
      }
      
      {target} .section-image-background {
        animation-name: {${prefix}section.animations.background.animationType};
        animation-duration: {${prefix}section.animations.background.animationDuration}s;
        animation-delay: {${prefix}section.animations.background.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.background.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.background.animationType};
        -webkit-animation-duration: {${prefix}section.animations.background.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.background.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.background.enableAnimation ? both : none};
      }
      
      ${generateTextFieldCSS(prefix + 'typography.spacings.')}
      
    `
  }
}

/***
 * Removed some interfering code temporarily. Will be added back in later.
 * Responding to recovery ticket to allow additional testing: https://www.notion.so/einsteinindustries/Heading-1-is-not-working-04d6ba159d8a481ebe70637b4134c061?pvs=4
 *
 *
 * @media (min-width: {section.container.breakpointA.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointA.sectionWidth}; }
 *             }
 *             @media (min-width: {section.container.breakpointB.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointB.sectionWidth}; }
 *             }
 *             @media (min-width: {section.container.breakpointC.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointC.sectionWidth}; }
 *             }
 */
