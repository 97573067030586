import type {
  AllowedFieldConfigComponents,
  DynamicListProps,
  FieldConfig,
  FieldConfigDataGeneralGroup,
  fieldDataPoint,
  FormConfig,
  GroupListField,
  initialValueType,
  ListField
} from '@/components/shared/externalTypes'
import type {Field} from '@einsteinindustries/tinacms'
import type {BlockContents, Contents} from '@/components/shared/types'
import CopyToClipboard from '@/src/utils/shared/CopyToClipboard'
import {
  FORM_MANAGER_DELIMITER,
  FORM_MANAGER_PREFIX,
  handleFormManagerChange,
  handleFormManagerSubmit
} from '@/src/utils/formManagerHelper'
import {mutate as globalMutate} from 'swr'
import {PAGE_BUILD, SITE_QUERY} from '@/graphql/queries'

export const CONTENT_VARIABLE_COPY_FIELD_NAME = 'copyvar' as const
const CONFIG_TREE_CONTENT_DEPTH = 2

type MutateInfo = {
  site_id: string,
  page_build_id: string,
  site_build_id: string
}

export type StringObject = { [key: string]: string | StringObject }
export type PrimitiveObject = { [key: string]: string | number | null | undefined | boolean | PrimitiveObject }

const HiddenHelpers = {
  tina: {
    forms: {
      createLabelFromName: (label: string) => {
        return [...label.split(/(?=[A-Z_-][a-z]+)/)].map((word) => (word.charAt(0).toUpperCase() + word.slice(1))).join(' ')
      },
      handleGroupList: (fieldConfig: (GroupListField | ListField), label: string) => {
        return {
          itemProps: ({id: key, name: label}: Record<string, any>) => {
            return {key, label}
          },
          defaultItem: {
            name: `New ${fieldConfig.newItemName ?? HiddenHelpers.tina.forms.createLabelFromName(label)}`,
            ...(fieldConfig.defaultItem ?? {})
          }
        }
      },
      handleGroup: (fieldConfig: FieldConfigDataGeneralGroup, groupName: string, prefix: string, showVariables: boolean = false) => {
        fieldConfig.asGroupList = fieldConfig.asGroupList ?? false
        return Object.assign({
          label: HiddenHelpers.tina.forms.createLabelFromName(groupName),
          name: `${prefix}${FORM_MANAGER_DELIMITER}${groupName}`,
          component: fieldConfig.asGroupList ? 'group-list' : 'group',
          fields: HiddenHelpers.tina.forms.createConfigTree(fieldConfig.content as FieldConfig, `${prefix}${FORM_MANAGER_DELIMITER}${groupName}`, showVariables) as Field[],
          ...({...fieldConfig, ...{content: undefined, asGroupList: undefined}})
        }, fieldConfig.asGroupList ? HiddenHelpers.tina.forms.handleGroupList(fieldConfig as FieldConfigDataGeneralGroup & DynamicListProps, groupName) : {}) as Field
      },
      createConfigTree: (config: FieldConfig, prefix: string = FORM_MANAGER_PREFIX, showVariables: boolean = false): Field[] => {
        const configTree: Field[] = []
        for (const [label, field] of Object.entries(config)) {
          if (field) {
            if (typeof field.content === 'object') {
              configTree.push(HiddenHelpers.tina.forms.handleGroup(field as FieldConfigDataGeneralGroup, label, prefix, showVariables))
            } else if (field.formatted) {
              configTree.push(field.formatted as Field)
            } else {
              if (showVariables && field.content !== 'blocks') {
                configTree.push({
                  name: label + CONTENT_VARIABLE_COPY_FIELD_NAME,
                  label: 'Copy Variable Name',
                  component: () => CopyToClipboard({
                    textToCopy: Helpers.tina.forms.getVariableName(prefix, label)
                  }),
                })
              }
              configTree.push({
                label: HiddenHelpers.tina.forms.createLabelFromName(label),
                name: `${prefix}${FORM_MANAGER_DELIMITER}${label}`,
                component: (field.content ?? 'text') as AllowedFieldConfigComponents,
                ...field
              } as Field)
            }
          }
        }
        return configTree
      },
      getVariableName: (prefix: string, label?: string, depth: number = CONFIG_TREE_CONTENT_DEPTH): string => {
        const sanitizedPrefix = prefix
          .split(FORM_MANAGER_DELIMITER)
          .splice(depth)
          .map(str => str.replace(/\s+/g, '_'))
          .join('.')

        const sanitizedLabel = label ? label.replace(/\s+/g, '_') : ''

        return '$' + sanitizedPrefix + (label ? '.' + sanitizedLabel : '')
      },
      externalEventListenerPreprocess: (values: fieldDataPoint) => {
        const processedValues: fieldDataPoint = {}
        if (typeof values === 'object' && values !== null) {
          for (const [key, value] of Object.entries(values as object)) {
            let keyModified = key.substring(key.lastIndexOf(FORM_MANAGER_DELIMITER) + 1, key.length)
            processedValues[keyModified as keyof initialValueType] = HiddenHelpers.tina.forms.externalEventListenerPreprocess(value)
          }
        } else {
          return values === '' ? null : values
        }
        return processedValues
      },
      createForm: (config: FormConfig, initialData: BlockContents, showVariables: boolean = false, mutate?: MutateInfo) => {
        return {
          id: config.id,
          label: config.title,
          initialValues: initialData,
          fields: HiddenHelpers.tina.forms.createConfigTree(
            config.content,
            `${FORM_MANAGER_PREFIX}${FORM_MANAGER_DELIMITER}${config.id}`,
            showVariables
          ),
          onSubmit: async (values: any) => {
            await handleFormManagerSubmit(values, config)
            if (mutate) {
              await globalMutate([SITE_QUERY, {id: mutate.site_id}])
              await globalMutate([PAGE_BUILD, {id: mutate.page_build_id}])
            }
          },
          onChange: async (values: any) => {
            await handleFormManagerChange(values, config)
          }
        }
      }
    }
  }
}
const Helpers = {
  tina: {
    forms: {
      create: HiddenHelpers.tina.forms.createForm,
      getVariableName: HiddenHelpers.tina.forms.getVariableName
    }
  },
  convert: {
    convertStringToPrimitive: (stringToConvert: string): boolean | number | null | undefined | string => {
      if (['true', 'false', 'null', 'undefined', 'NaN'].includes(stringToConvert)) return eval(stringToConvert)
      if (!isNaN(Number(stringToConvert))) return Number(stringToConvert)
      return stringToConvert
    },
    objectValuesToString: (objectToConvert: any) => {
      const convertedObject: StringObject = {}
      for (const [key, value] of Object.entries(objectToConvert)) {
        convertedObject[key] = typeof value === 'object' && value !== null ? Helpers.convert.objectValuesToString(value) : `${value}`
      }
      return convertedObject
    },
    objectValuesToPrimitive: (objectToConvert: any) => {
      const convertedObject: PrimitiveObject = {}
      for (const [key, value] of Object.entries(objectToConvert)) {
        convertedObject[key] = typeof value === 'object' && value !== null ? Helpers.convert.objectValuesToPrimitive(value) : Helpers.convert.convertStringToPrimitive(`${value}`)
      }
      return convertedObject
    }
  },
  format: {
    capitalize: {
      /**
       * This function takes a string and returns a string with the first letter capitalized.
       * @param stringToCapitalize String to capitalize.
       * @returns A string with the first letter capitalized.
       * @example
       * // returns "Twitter"
       * Helpers.format.capitalize.firstLetter('twitter')
       */
      firstLetter: (stringToCapitalize: string): string => stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1),
      /**
       * This function takes a string and returns a string with the first letter of each word capitalized.
       * @param stringToCapitalize String to capitalize.
       * @returns A string with the first letter of each word capitalized.
       * @example
       * // returns "Twitter"
       * Helpers.format.=capitalize.firstLetterOfEachWord('twitter')
       * @example
       * // returns "Twitter Is Awesome"
       * Helpers.format.capitalize.firstLetterOfEachWord('twitter is awesome')
       */
      firstLetterOfEachWord: (stringToCapitalize: string): string => stringToCapitalize.split(' ').map(w => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase()).join(' ')
    },
    human: {
      date: {
        /***
         * This function takes a date and returns a string in a human-readable format, such as "2 days ago"
         * If the date is more than one day ago, it will return "on Month/DD/YYYY at HH:MM AM/PM"
         * @param dateToFormat Date string to format. Can be either a date string or a date object.
         */
        relative: (dateToFormat: string | Date): string => {
          const date = (typeof dateToFormat === 'string') ? new Date(Date.parse(dateToFormat)) : dateToFormat
          const dayOfMonth: number | string = date.getDate()
          const month: number | string = date.getMonth() + 1
          const year: number | string = date.getFullYear().toString().slice(-2)
          const hour: number | string = date.getHours()
          const minutes: number | string = date.getMinutes()
          const diffMs = Date.now() - date.getTime()
          const diffSec = Math.round(diffMs / 1000)
          const diffMin = Math.round(diffSec / 60)
          const diffHour = Math.round(diffMin / 60)
          const diffDay = Math.round(diffHour / 24)
          const AMPM = (hour < 12 ? 'AM' : 'PM')

          if (diffSec < 1) {
            return 'just now'
          } else if (diffMin < 1) {
            return `${diffSec} second${diffSec === 1 ? '' : 's'} ago`
          } else if (diffHour < 1) {
            return `${diffMin} minute${diffMin === 1 ? '' : 's'} ago`
          } else if (diffDay < 1) {
            return `${diffHour} hour${diffHour === 1 ? '' : 's'} ago`
          } else {
            return `on ${month}/${dayOfMonth}/${year} at ${hour % 12}:${(minutes < 10 ? '0' + minutes : minutes)} ${AMPM}`
          }
        },
        /***
         * This function takes a date and returns a string in a human-readable format, such as "January 1, 2020"
         * @param dateToFormat Date string to format. Can be either a date string or a date object.
         * @param includeTime Whether or not to include the time in the string.
         */
        long: (dateToFormat: string | Date, includeTime: boolean = false): string => {
          const date = (typeof dateToFormat === 'string') ? new Date(Date.parse(dateToFormat)) : dateToFormat
          const dayOfMonth: number | string = date.getDate()
          const month: number | string = date.getMonth() + 1
          const year: number | string = date.getFullYear().toString()
          const hour: number | string = date.getHours()
          const minutes: number | string = date.getMinutes()
          const AMPM = (hour < 12 ? 'AM' : 'PM')

          // return string in format "January 1, 2020"
          if (includeTime) {
            return `${Helpers.format.human.date.long(dateToFormat)} at ${hour % 12}:${(minutes < 10 ? '0' + minutes : minutes)} ${AMPM}`
          }
          return `${Helpers.format.human.date.month(month)} ${dayOfMonth}, ${year}`
        },
        /***
         * This function takes a month number and returns a string in a human-readable format, such as "January"
         * @param monthNumber Month number to format.
         * @returns A string in a human-readable format, such as "January"
         */
        month: (monthNumber: number): string => {
          const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          return monthNames[monthNumber - 1]
        }
      },
      count: {
        /**
         * This function takes a count, and a singular noun, and returns a string with the count and the noun, pluralized if necessary.
         * @param count The count to be formatted.
         * @param noun The noun to be formatted.
         * @param asZero The string to be returned if the count is zero, defaults to the number 0.
         * @returns A string with the count and the noun, pluralized if necessary.
         */
        pluralSingular: (count: number | undefined, nounSingular: string, asZero?: string): string => {
          if (typeof count === 'undefined') {
            count = 0
          }
          return `${(count === 0 && asZero) ? asZero : count} ${nounSingular}${count === 1 ? '' : 's'}`
        }
      },
      phone: {
        /**
         * This function takes a phone number (partial or complete) and returns a string in a human-readable format, such as "(123) 456-7890" or "(123) 456-"
         * @param phoneToFormat Phone number string to format. Can be either a phone number string or a phone number object.
         * @returns A string in a human-readable format, such as "(123) 456-7890"
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format('(123)4567890')
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format('1234567890')
         * @example
         * // returns "(123) "
         * Helpers.format.human.phone.format('123')
         * @example
         * // returns "(123) 456-"
         * Helpers.format.human.phone.format('123456')
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format({areaCode: '123', prefix: '456', lineNumber: '7890'})
         */
        format: (phoneToFormat: string | { areaCode: string, prefix: string, lineNumber: string }): string => {
          if (phoneToFormat === null || typeof phoneToFormat === 'undefined') return ''
          const phone = (typeof phoneToFormat === 'string') ? phoneToFormat.replace(/[^0-9]/g, '') : `${phoneToFormat.areaCode}${phoneToFormat.prefix}${phoneToFormat.lineNumber}`
          const areaCode = phone.slice(0, 3)
          const prefix = phone.slice(3, 6)
          const lineNumber = phone.slice(6, 10)

          if (phone.length > 6) {
            return `(${areaCode}) ${prefix}-${lineNumber}`
          } else if (phone.length > 3) {
            return `(${areaCode}) ${prefix}`
          } else if (phone.length > 0) {
            return `(${areaCode}`
          }
          return ''
        },
        /**
         * This function limits the length of a phone number to 10 digits.
         * @param phoneToFormat Phone number string to format. Can be either a phone number string or a phone number object. If the phone number is longer than 10 digits, it will be truncated.
         * @returns A string with the phone number limited to 10 digits.
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('(123)4567890')
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('1234567890')
         * @example
         * // returns "123"
         * Helpers.format.human.phone.limit('123')
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '7890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '1234567890', prefix: '456', lineNumber: '7890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('123456789012345')
         */
        limit: (phoneToFormat: string | { areaCode: string, prefix: string, lineNumber: string }): string => {
          if (phoneToFormat === null || typeof phoneToFormat === 'undefined') return ''
          const phone = (typeof phoneToFormat === 'string') ? phoneToFormat.replace(/[^0-9]/g, '') : `${phoneToFormat.areaCode}${phoneToFormat.prefix}${phoneToFormat.lineNumber}`
          if (phone.length === 0) {
            return ''
          }
          return phone.substring(0, 10)
        },
        /**
         * This function takes a phone number (partial or complete) and returns a boolean indicating whether or not the phone number is valid.
         * @param maybePhone Phone number string to validate. Can be either a phone number string or a phone number object.
         * @returns A boolean indicating whether or not the phone number is valid.
         * @example
         * // returns true
         * Helpers.format.human.phone.validate('(123)4567890')
         * @example
         * // returns true
         * Helpers.format.human.phone.validate('1234567890')
         * @example
         * // returns false
         * Helpers.format.human.phone.validate('123')
         */
        is: (maybePhone: string | { areaCode: string, prefix: string, lineNumber: string }): boolean => {
          if (maybePhone === null || typeof maybePhone === 'undefined') return false
          if (typeof maybePhone === 'string') {
            return (maybePhone.replace(/[^0-9]/g, '').length === 10)
          } else {
            return (maybePhone.areaCode.length === 3 && maybePhone.prefix.length === 3 && maybePhone.lineNumber.length === 4)
          }
        }
      }
    },
    validate: {
      /**
       * This function takes a twitter handle and returns a string in a human-readable format, such as "@twitter"
       * This also will remove any non-alphanumeric characters or non-underscores from the handle.
       * Finally, this limits the handle to 15 characters.
       * @param twitterHandle Twitter handle to format. Can be either a twitter handle string or a twitter handle object.
       * @returns A string in a human-readable format, such as "@twitter"
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format('twitter')
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format({handle: 'twitter'})
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format({handle: '@twitter'})
       * @example
       * // returns "@t"
       * Helpers.format.human.twitter.format('t')
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format('1234twitter--')
       * @example
       * // returns "@123456789012345"
       * Helpers.format.human.twitter.format('123456789012345')
       */
      twitter: (twitterHandle: string | { handle: string }): string => {
        if (twitterHandle === null || typeof twitterHandle === 'undefined') return ''
        const handle = (typeof twitterHandle === 'string') ? twitterHandle.replace(/[^a-zA-Z0-9_]/g, '') : twitterHandle.handle.replace(/[^a-zA-Z0-9_]/g, '')
        if (handle.length === 0) {
          return ''
        }
        return `@${handle.slice(0, 15)}`
      },
      /**
       * This function takes an instagram handle and returns a string in a human-readable format, such as "@instagram"
       * This also will remove any non-alphanumeric characters or non-periods from the handle.
       * Finally, this limits the handle to 30 characters.
       * @param instagramHandle Instagram handle to format. Can be either an instagram handle string or an instagram handle object.
       * @returns A string in a human-readable format, such as "@instagram"
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format('instagram')
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format({handle: 'instagram'})
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format({handle: '@instagram'})
       */
      instagram: (instagramHandle: string | { handle: string }): string => {
        if (instagramHandle === null || typeof instagramHandle === 'undefined') return ''
        const handle = (typeof instagramHandle === 'string') ? instagramHandle.replace(/[^a-zA-Z0-9\._]/g, '') : instagramHandle.handle.replace(/[^a-zA-Z0-9\._]/g, '')
        if (handle.length === 0) {
          return ''
        }
        return `@${handle.slice(0, 30)}`
      },
      /**
       * This function takes a phone number (partial, complete, or invalid) and returns an error string or undefined if the phone number is valid.
       * @param phoneToValidate Phone number string to validate. Can be either a phone number string or a phone number object.
       * @returns An error string or undefined if the phone number is valid.
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate('(123)4567890')
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate('1234567890')
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate('123')
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate('123456')
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate({areaCode: '123', prefix: '456', lineNumber: '7890'})
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
       */
      phone: (phoneToValidate: string | {
        areaCode: string,
        prefix: string,
        lineNumber: string
      }): string | undefined => {
        if (phoneToValidate === null || typeof phoneToValidate === 'undefined') return
        const phone = (typeof phoneToValidate === 'string') ? phoneToValidate.replace(/[^0-9]/g, '') : `${phoneToValidate.areaCode}${phoneToValidate.prefix}${phoneToValidate.lineNumber}`
        if (phone && phone.length !== 10) {
          return 'Error: Phone number must be 10 digits'
        }
      },
    }
  },
  contents: {
    getContentIDFromOriginalContents(key: string | undefined, originalContents: Contents[]): string | undefined {
      const ID = originalContents.find((e) => e.name === key)?.id
      return typeof key !== 'string' || typeof key === 'undefined' ? undefined : ID
    },
    getContentValueFromOriginalContents(key: string | undefined, originalContents: Contents[]): string | undefined {
      const value = originalContents.find((e) => e.name === key)?.value
      return typeof key === 'undefined' ? undefined : value
    }
  }
}

export default Helpers
