import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import React, {useState} from 'react'
import {useLucidContext} from '../../../state/ServerSideStore'
import SectionImage from '../shared/SectionImage'
import {ArrowDown2, ArrowUp2, HambergerMenu} from 'iconsax-react'
import {Twirl as Hamburger} from 'hamburger-react'
import {AnimatePresence, motion} from 'framer-motion'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {IMAGE_FIELDS} from '../shared/InputTypesFields'

export const getSpacerTemplate = () => {
  return {
    template: {
      label: 'Spacer',
      defaultItem: {
        width: '100%'
      },
      fields: [
        {
          name: 'width',
          label: 'Width',
          component: 'text',
          description: 'Enter a width and a unit, e.g. 100px, 100%'
        },
        {
          name: 'height',
          label: 'Height',
          component: 'text',
          description: 'Enter a height and a unit, e.g. 100px, 100%'
        }
      ]
    },
    Component({index, cms, data}) {
      return (
        <ItemWrapper cms={cms} index={index} y={2} x={0}>
          <div style={{
            width: data.width,
            height: data.height
          }}>&nbsp;</div>
        </ItemWrapper>
      )
    }
  }

}

export const getImageTemplate = () => {
  return {
    template: {
      label: 'Image',
      defaultItem: {
        image: {
          src: '/img/logo-placeholder.png',
          alt: 'Logo',
          priority: 'true'
        }
      },
      fields: [
        {
          name: 'image',
          label: 'Image',
          component: 'group',
          fields: IMAGE_FIELDS
        }
      ]
    },
    Component({index, cms, data}) {
      return (
        <ItemWrapper cms={cms} index={index} y={2} x={0}>
          <SectionImage
            name="image"
            className="navigation-image"
            src={data?.image?.src}
            mobile={data?.image?.mobile}
            alt={data?.image?.alt}
            width={data?.image?.width}
            height={data?.image?.height}
            priority={data?.image?.priority}
            sizes={data?.image?.sizes}
            link={data?.image?.link}
            cms={cms}
          />
        </ItemWrapper>
      )
    }
  }
}

export const getLinkTemplate = (state, isMenuLink = false) => {
  return {
    template: {
      label: 'Link'
    },
    Component({index, cms, data}) {
      const [{menus = []}] = useLucidContext(cms)
      const {showMenu} = state

      const menuLinks = []
      for (const {menu_items} of menus) {
        for (const menuItem of menu_items) {
          menuLinks.push({id: menuItem.id, label: menuItem.label, name: menuItem.label, route: menuItem.route})
        }
      }

      return (
        <ItemWrapper cms={cms} index={index} y={2} x={0} showMenu={showMenu}>
          <LucidInlineBlocks
            itemProps={{...data, cms: cms}}
            name="link"
            blocks={getBlocks(menuLinks, 'link-control', cms, isMenuLink)}
          />
        </ItemWrapper>
      )
    }
  }
}

export const getInlineMenuTemplate = (state) => {
  return {
    template: {
      label: 'Inline Menu',
      defaultItem: {
        menu_title: 'Menu'
      },
      fields: [
        {
          name: 'menu_title',
          label: 'Menu Title',
          component: 'text'
        }
      ]
    },
    Component({index, cms, data}) {
      const {changeShowMenu, showMenu} = state
      const [toggle, setToggle] = useState(false)
      const [{menus = []}] = useLucidContext(cms)
      const classMap = {
        'true': 'menu-open',
        'false': 'menu-closed'
      }

      function onMenuClick() {
        changeShowMenu(!showMenu)
      }

      return (
        <div key={index} className="section-item-wrapper section-item-full-width section-item-inline-menu" onClick={onMenuClick}>
          <ItemWrapper cms={cms} index={index} x={60} y={16}>
            <HambergerMenu/>
            <span onClick={onMenuClick} className="link">
              <span>{data.menu_title}</span>
            </span>
          </ItemWrapper>
          <LucidInlineBlocks
            className={classMap[toggle]}
            name="menu_items"
            blocks={getBlocks(menus, 'menu-control', cms)}
            itemProps={{...data, cms: cms}}
          />
        </div>
      )
    }
  }
}

export const getTextTemplates = () => {
  const textTypes = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6', 'Paragraph', 'Quote']
  const textTemplates = {}
  for (const textType of textTypes) {
    textTemplates[textType.toLowerCase().replaceAll(' ', '-')] = {
      template: {
        label: textType,
        defaultItem: {
          text: 'Text'
        },
        fields: [
          {
            name: 'text',
            label: 'Text',
            component: 'text'
          }
        ]
      },
      Component({index, cms, data}) {
        return (
          <ItemWrapper cms={cms} index={index} y={2} x={0}>
            <div className={textType.toLowerCase().replaceAll(' ', '-')}>
              <RichtextWithVariables richtext={data.text} cms={cms} name={'text'}/>
            </div>
          </ItemWrapper>
        )
      }
    }
  }
  return textTemplates
}

export const getMenuTemplate = (state, isLeftmostSection, totalSections) => {
  return {
    template: {
      label: 'Menu',
      defaultItem: {
        menu_title: 'Menu'
      },
      fields: [
        {
          name: 'menu_title',
          label: 'Menu Title',
          component: 'text'
        }
      ]
    },
    Component({index, cms, data}) {
      const {changeShowMenu, showMenu} = state
      const [toggle, setToggle] = useState(showMenu)

      function handleToggle() {
        setToggle(!toggle)
        setTimeout(() => {
          changeShowMenu()
        }, 400)
      }

      const placement = isLeftmostSection && index === totalSections - 1 ? 'right' : 'left'

      const hamburgerIcon = <Hamburger size={20} toggled={toggle} duration={0.5} onToggle={handleToggle}/>

      return (
        <div key={index} className="section-item-wrapper navigation-menu-trigger section-item-menu " onClick={handleToggle}>
          <ItemWrapper cms={cms} index={index} x={4} y={0} disableAnimation>
            {
              placement === 'left' && hamburgerIcon
            }
            <span className="link">
                            <AnimatePresence exitBeforeEnter>
                                {(toggle && showMenu) && <motion.div
                                  initial={{opacity: 0, y: 10}}
                                  animate={{opacity: 1, y: 0}}
                                  exit={{opacity: 0, y: -10}}
                                  style={{position: 'relative'}}
                                  transition={{duration: 0.4, delay: 0}}
                                >Close</motion.div>}
                              {(!toggle && !showMenu) && <motion.div
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -10}}
                                style={{position: 'relative'}}
                                transition={{duration: 0.4, delay: 0}}
                              >{data.menu_title}</motion.div>}
                            </AnimatePresence>
                            <AnimatePresence>

                            </AnimatePresence>
                        </span>
            {
              placement === 'right' && hamburgerIcon
            }
          </ItemWrapper>
        </div>
      )
    }
  }
}

const getCenteringClass = (index, totalCount) => {
  if (totalCount === 1) {
    return 'section-flex-center'
  }
  if (totalCount === 2) {
    if (index === 0) {
      return 'section-flex-start'
    } else {
      return 'section-flex-end'
    }
  }
  if (totalCount % 2 === 1) {
    if (index === Math.floor(totalCount / 2)) {
      return 'section-flex-center'
    } else if (index < Math.floor(totalCount / 2)) {
      return 'section-flex-start'
    } else {
      return 'section-flex-end'
    }
  }
  if (totalCount % 2 === 0) {
    if (index < Math.floor(totalCount / 2)) {
      return 'section-flex-start'
    } else {
      return 'section-flex-end'
    }
  }
}

export const getSectionTemplate = (state, totalCount) => {
  return {
    template: {
      label: 'Section'
    },
    Component({index, cms, data}) {
      return (
        <GroupWrapper cms={cms} index={index} y={2} x={0} className={getCenteringClass(index, totalCount)}>
          <AnimatePresence>
            <LucidInlineBlocks
              name="section"
              className={'flex-container'}
              itemProps={{...data, cms: cms}}
              direction={'horizontal'}
              blocks={{
                menu: getMenuTemplate(state, index === totalCount - 1, data['section']?.length),
                inlineMenu: getInlineMenuTemplate(state),
                link: getLinkTemplate(state),
                spacer: getSpacerTemplate(),
                image: getImageTemplate()
              }}/>
          </AnimatePresence>
        </GroupWrapper>
      )
    }
  }
}

function ItemWrapper({cms, index, children, x, y, showMenu, disableAnimation}) {
  return <LucidBlocksControls cms={cms} index={index} style={{display: 'none'}}
                              focusRing={{offset: {x, y}, borderRadius: 4}} insetControls={false}
                              className={'section-item-wrapper'}>
    {!disableAnimation && <motion.div
      initial={{opacity: 0, y: 10}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: -10}}
      transition={{duration: 0.4, delay: index * 0.1, ease: 'backOut'}}
      className="section-item-wrapper">
      {children}
    </motion.div>}
    {disableAnimation &&
      <div className="section-item-wrapper">{children}</div>
    }
  </LucidBlocksControls>
}

function GroupWrapper({cms, index, children, x, y, className}) {
  return <div className="flex-full-size">
    <LucidBlocksControls
      cms={cms}
      style={{display: 'none'}}
      className={'section-item-wrapper ' + className}
      index={index}
      focusRing={{offset: {x, y}, borderRadius: 4}}
      insetControls={true}
    >
      {children}
    </LucidBlocksControls></div>
}

function buildMenuItemFromTemplate(menuItemId, targetNavigationItem, navigationObject) {
  let menuItem = []
  const isMenu = 'menu_items' in targetNavigationItem
  if (isMenu) {
    menuItem = navigationObject.find(
      menu => menu.id === menuItemId
    )?.menu_items.sort((a, b) => a.sort_order - b.sort_order)?.map(item => {
      if (item.group_menu_items.length > 0) {
        return {
          [item.label]: item.group_menu_items.sort((a, b) => a.sort_order - b.sort_order)?.map(groupItem => {
            return {[groupItem.label]: groupItem.route?.url ?? groupItem.label}
          })
        }
      }
      return {[item.label]: item.route?.url ?? item.label}
    })
  } else {
    const foundItem = navigationObject.find(item => item.id === menuItemId)
    if (foundItem) menuItem = [{[foundItem.label]: foundItem.route.url}]
  }
  /**
   * TODO: this will not happen, we need to update TinaCMS
   *  to generate a corresponding not found
   *  template so we can use this
   */
  if (menuItem.length === 0) {
    return {'Item Not Found': '/errors/404'}
  }
  return menuItem.reduce((accumulator, currentValue) => ({
    ...accumulator, ...currentValue
  }), {})
}

const getBlockTemplateData = (item) => {
  const items = item.menu_items?.sort((a, b) => a.sort_order - b.sort_order) ?? [item]
  const fields = []
  const defaultItem = {}

  for (const menuItem of items) {
    fields.push({
      name: menuItem.label,
      label: menuItem.label,
      component: 'text'
    })
    defaultItem[menuItem.label] = menuItem.route?.url ?? menuItem.label
  }
  return {fields, defaultItem}
}

const getBlocks = (navigation, className, cms, isMenuLink = false) => {
  const blocks = {}
  navigation.forEach(item => {
    blocks[item.id] = {
      template: {
        label: item.name,
        name: item.name,
        key: item.id,
        ...getBlockTemplateData(item)
      },
      Component({index, data: {_template}}) {
        const displayableItems = buildMenuItemFromTemplate(_template, item, navigation)
        return (
          <div key={index}>
            <div className={className}>
              {Object.entries(displayableItems)?.map(([label, url], i) => {
                  if (typeof url === 'object') {
                    return (
                      <SubMenuItemLink
                        cms={cms}
                        text={label}
                        links={url}
                        key={i}
                        isMenuLink={isMenuLink}
                      />
                    )
                  }
                  return (
                    <MenuItemLink
                      cms={cms}
                      key={i}
                      name={label}
                      href={url}
                      text={label}
                      isMenuLink={isMenuLink}
                    />
                  )
                }
              )}
            </div>
          </div>
        )
      }
    }
  })
  return blocks
}

function SubMenuSet({links, cms, isMenuLink = false}) {
  if (!links) {
    return null
  }

  return (
    <ul className="navigation-sub-menu navigation-menu-links-items">
      {
        links?.map((link, i) => (
          Object.entries(link)?.map(([label, url]) => (
            <MenuItemLink
              key={i}
              cms={cms}
              text={label}
              href={url}
              isMenuLink={isMenuLink}
            />
          ))
        ))
      }
    </ul>
  )
}

function SubMenuItemLink({links, text, cms, isMenuLink = false}) {
  const [showMenu, setShowMenu] = useState(false)

  function toggleMenu() {
    setShowMenu(!showMenu)
  }

  return (
    <>
      <li>
        <a onClick={toggleMenu} className="menu-link-flex">
          <div className={`link ${isMenuLink ? 'navigation-menu-link' : ''}`}>{text}</div>
          {showMenu ? <ArrowUp2/> : <ArrowDown2/>}
        </a>
      </li>
      <AnimatePresence>
        {
          showMenu &&
          <motion.div
            initial={{opacity: 0, y: 10}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: -10}}
            style={{position: 'relative'}}
            transition={{duration: 0.4, delay: 0}}>
            <SubMenuSet links={links} cms={cms} isMenuLink={isMenuLink}/>
          </motion.div>
        }
      </AnimatePresence>
    </>
  )
}

function MenuItemLink({href, text, cms, isMenuLink = false}) {

  function noLinkOnCms(e) {
    if (cms) {
      e.preventDefault()
    }
  }

  return (
    <p>
      <a onClick={noLinkOnCms} href={href}>
        <span className={`link ${isMenuLink ? 'navigation-menu-link' : ''}`}>{text}</span>
      </a>
    </p>
  )
}

const getMenuTemplateColumn = (state, cms) => {
  return {
    template: {
      label: 'Dynamic Menu'
    },
    Component({index, data}) {
      const [{menus = []}] = useLucidContext(cms)
      return (
        <ItemWrapper cms={cms} index={index} x={2} y={0}>
          <LucidInlineBlocks
            name="menu_items"
            blocks={getBlocks(menus, 'navigation-menu-links-items navigation-inline-menu-trigger', cms, true)}
            itemProps={{...data, cms: cms}}
            className={'navigation-menu-links'}
            direction={'vertical'}
          />
        </ItemWrapper>
      )
    }
  }
}

export const getMenuContentTemplates = (state, cms) => {
  return {
    column: {
      template: {
        label: 'Column'
      },
      Component({index, data}) {
        return (
          <ItemWrapper cms={cms} index={index} x={2} y={0}>
            <div className={'navigation-menu-column'}>
              <LucidInlineBlocks itemProps={{...data, cms: cms}} name="column" direction={'vertical'} blocks={
                {
                  dynamic_menu: getMenuTemplateColumn(state, cms),
                  link: getLinkTemplate(state, true),
                  spacer: getSpacerTemplate(),
                  image: getImageTemplate(),
                  ...getTextTemplates()
                }}/>
            </div>
          </ItemWrapper>
        )
      }
    }
  }
}
