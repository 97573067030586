import {FlexBox} from '@/components/sections/shared/stylecore/sets/BreakpointFlexboxGridOptions'
import {Box} from '@/components/sections/shared/stylecore/sets/Box'
export const styleCore = {
  config: {
    ...FlexBox.createFields(),
    ...Box.createFields('reviewContainer', {
      decoration: {
        border: {
          width: '1px',
          style: 'solid',
          color: '#ddd',
          borderRadius: 0,
        }
      }
    })
  },
  target: {
    componentName: 'reviews',
  },
  css: `
    ${FlexBox.createCSS('reviews-wrapper', 'reviews-item')}
    ${Box.createCSS('reviews-item', 'reviewContainer.')}
  `
}
