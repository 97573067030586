import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {TIMELINE_ITEM_BLOCKS, TimelineItem} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {Button} from '@nextui-org/react'
import {useState} from 'react'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Timeline/presets'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'


export default function Timeline(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    outro,
    button,
    className,
    css_overrides,
    starting_point_text,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms
  } = props

  const [edit, setEdit] = useState(false)
  const CleanTimeline = () => <>
    {
      quote && <div className="column timeline-item show-for-large">
        <div className="timeline-item-container quote-container">
          <blockquote className="exclude-border">
            <span className="quote"><RichtextWithVariables richtext={quote} cms={cms} name={'quote'}/></span>
            <cite className="citation"><InlineTextWithVariables cms={cms} value={cite} name="cite"/></cite>
          </blockquote>
        </div>
      </div>
    }
    {
      props.timeline_items?.map((item, index) => <TimelineItem key={index} data={item} cms={cms}/>)
    }
  </>

  const ControlWrapper = () => {
    if (cms) {
      return <LucidBlocksControls cms={cms} index={1} label={false} customActions={[
        {
          name: 'Toggle View',
          icon: <Button auto size="xs" onPress={() => {
            setEdit(!edit)
          }} color={edit ? 'secondary' : 'primary'} rounded>
            {!edit ? 'Timeline' : 'Edit'} View
          </Button>,
          onClick: () => setEdit(!edit)
        }
      ]} insetControls={false} focusRing={{
        offset: {
          x: 0,
          y: edit ? 0 : 50
        },
        nestedFocus: true
      }}>
        {
          edit ? <LucidInlineBlocks className="row" itemProps={{...props}} name="timeline_items"
                                    blocks={TIMELINE_ITEM_BLOCKS}/> :
            <CleanTimeline/>
        }
      </LucidBlocksControls>
    } else {
      return <CleanTimeline/>
    }
  }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className}
      name="timeline"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
        <span className="title heading-1">
          <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
        </span>
              <span className="subtitle heading-2">
          <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
        </span>
            </h1>
          </div>

          <div className="row">
            <div className="column">
              <div className="section-richtext paragraph">
                <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
              </div>
              <div className="timeline-starting-point">
                <h3 className="timeline-starting-point-text heading-4">
                  <RichtextWithVariables richtext={starting_point_text} cms={cms} name={'starting_point_text'}/>
                </h3>
              </div>
            </div>
          </div>

          <div className={`timeline-wrapper section-richtext ${outro ? 'has-outro' : ''}`}>
            {
              ControlWrapper()
            }
          </div>

          <div className="section-outro paragraph">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
              </div>
            </div>
          </div>

          {
            button && <div className="section-button">
              <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
            </div>
          }
        </div>
      </div>
    </Section>
  )
}

Timeline.StyleCore = styleCore
Timeline.presets = presets


