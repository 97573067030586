import {LucidBlocksControls} from '../shared/LucidInlineGroups'


const itemTemplates = {
  mega_menu_block: {
    label: 'Mega Menu Block Item',
    defaultItem: {
      links: [
        {
          name: 'Name',
          url: 'https://www.einsteinindustries.com/'
        }
      ]
    },
    fields: [
      {
        name: 'links',
        label: 'Link',
        itemProps: ({id: key, name: label}) => {
          return {key, label}
        },
        component: 'group-list',
        defaultItem: {
          name: 'Name',
          url: 'https://www.einsteinindustries.com/'
        },
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'URL',
            name: 'url',
            component: 'text',
          }
        ]
      }
    ],
  },
}

export const MEGA_MENU_BLOCK_ITEM_BLOCKS = {
  mega_menu_block: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index} cms={cms}>
          <ul className="all-pages">
            {
              data?.links?.map((e, i) => <li key={i} className="page-group"><a href={e?.url}>{e?.name}</a></li>)
            }
          </ul>
        </ItemWrapper>
      )
    },
    template: itemTemplates.mega_menu_block,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
      cms={props.cms}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
