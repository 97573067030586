import {PresetList} from '@/components/shared/types'

/**
 * 3/3 Migrated
 *
 * 3 - Feature Card Quotes Masonry
 * ✅ Migrated
 * ✅ Tested
 *
 * 83 - Feature Cards Flip cards [CIRCLE BACK]
 * ⚠️ Partially Migrated
 * ✅ Tested
 * Note: This preset mostly works, but there is a second part of the CSS in notion that was not properly labeled, which
 *       interferes with the CSS in the preset. This second part of the CSS is seemingly not needed for the preset to work.
 *       Additionally, the text can layer on top of itself in the transition. Not sure what the fix is here
 *
 * 135 - Features Card: Cards Left Column
 * ✅ Migrated
 * ✅ Tested
 */
export const presets: PresetList = [
  {
    display: {
      name: 'Feature Card Quotes Masonry',
      documentationURL: 'https://www.notion.so/einsteinindustries/Feature-Card-Quotes-Masonry-d12b3142a97c4851bcc0390d12baf4eb?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_card-IID-3'
    },
    notion_IID: '3',
    allowedSections: [],
    css: `
{
   max-height: 150vh;
    overflow: hidden;
}

 .section-button {
    position: absolute;
    top: calc(150vh - 18rem);
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
}
@media (max-width: 1024px) {
  + section.wildcard-section a.button {
    width: 90%;
  }
}
::after {
    z-index: 1;
    content: '';
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    width: 100%;
    height: 360px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.features-card-items { /* Masonry container */
    column-count: 4 !important;
    column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
    display: block;
}

.menu.column { /* Masonry bricks or child elements */
    display: inline-block !important;
    margin: 1rem 0 2rem !important;
    width: 100% !important;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
}

.features-card-item {
    display: flex;
    flex-flow: column;
}

.features-card-items {
    margin: 1rem 0 !important;
    padding: 0 !important;
}

.section-container {
  padding: 0 1rem;
}

.menu-card-item-subtitle {
    order: 5;
}

.menu.column {
    display: inline-block !important;
    width: 100% !important;
    max-width: 100% !important;
}

 + section.wildcard-section {
    margin-top: calc((-5.56rem * 2) - 76px - 1.7rem);
    z-index: 1;
    background: transparent;
}


 + section.wildcard-section .section-container {
    background: transparent;
}

 + section.wildcard-section + section {
    z-index: 1;
}

@media only screen and (min-width: 500px) {
     .features-card-items {
        -moz-column-count: 1 !important;
        -webkit-column-count: 1 !important;
        column-count: 1 !important;
    }
}

@media only screen and (min-width: 800px) {
     .features-card-items {
        -moz-column-count: 2 !important;
        -webkit-column-count: 2 !important;
        column-count: 2 !important;
    }
}

@media only screen and (min-width: 1024px) {
     .features-card-items {
        -moz-column-count: 3 !important;
        -webkit-column-count: 3 !important;
        column-count: 3 !important;
    }
}

@media only screen and (min-width: 1600px) {
     .features-card-items {
        -moz-column-count: 4 !important;
        -webkit-column-count: 4 !important;
        column-count: 4 !important;
    }
}
    
    `
  },
  {
    display: {
      name: 'Feature Cards Flip cards',
      documentationURL: 'https://www.notion.so/einsteinindustries/Feature-Cards-Flip-cards-c222b21ae81e44b29760eb6b31099fa3?pvs=4',
    },
    notion_IID: '83',
    allowedSections: [],
    css: `
.features-card-items > .menu {
     min-height: 300px;
}
 .menu-card-item-border-0-1 {
     perspective: 800px;
}
 .features-card-items .menu-card-item-border-0-1 {
     overflow: visible;
     background-color: transparent;
}
 .features-card-items .menu-card-item-border-0-1:hover .features-card-item {
     align-items: center;
     display: flex;
     justify-content: center;
     transform: rotateY( 180deg );
     transition: all 1s ease;
     box-shadow: 0 0.9px 0.9px -18px rgb(0 0 0 / 24%), 0 1.9px 2px -18px rgb(0 0 0 / 18%), 0 3.4px 3.5px -18px rgb(0 0 0 / 16%), 0 5.4px 5.5px -18px rgb(0 0 0 / 14%), 0 8.3px 8.5px -18px rgb(0 0 0 / 12%), 0 13px 13.3px -18px rgb(0 0 0 / 10%), 0 21.5px 22px -18px rgb(0 0 0 / 8%), 0 43px 44px -18px rgb(0 0 0 / 6%);
}
 .features-card-items .menu-card-item-border-0-1 .features-card-item {
     transform-style: preserve-3d;
     box-shadow: 0px 36px 80px rgb(0 0 0 / 6%), 0px 13.1406px 29.2013px rgb(0 0 0 / 4%), 0px 6.37951px 14.1767px rgb(0 0 0 / 3%), 0px 3.12736px 6.94968px rgb(0 0 0 / 3%), 0px 1.23656px 2.74791px rgb(0 0 0 / 2%);
     transition: all 1s ease;
     transform: rotateY( 0deg );
     height: 100%;
     background-color: #2d518d;
}
 .features-card-items .menu-card-item-border-0-1:hover .features-card-item-description {
     text-align: center;
     transform: rotateY( -180deg );
     z-index: 1;
}
 .features-card-item-description {
     backface-visibility: hidden;
     transform: rotateY( 180deg );
     color: #fff !important;
}
 .menu-card-item.features-card-item:before {
     background-color: #fff;
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     backface-visibility: hidden;
}
 .features-card-items .column:nth-of-type(odd) .menu-card-item-border-0-1 {
     z-index: 1;
}
 @media (min-width: 1024px) {
     h2.menu-card-item-title {
         padding: 2rem 1rem;
         top: 45px;
    }
}
 @media (max-width: 1023px) {
     h2.menu-card-item-title {
         padding: 2rem 1rem;
         color: #2d518d;
    }
}
 .menu-card-item-subtitle {
     top: 120px;
     padding: 2rem;
}
 .features-card-item-container, .menu-card-item-title, .menu-card-item-subtitle {
     backface-visibility: hidden;
     position: absolute !important;
     left: 0;
     width: 100%;
     overflow: hidden;
}
 .features-card-items .menu-card-item-border-0-1:hover .menu-card-item-title, .features-card-items .menu-card-item-border-0-1:hover .menu-card-item-subtitle {
     opacity: 0;
     transition: all 1s ease;
}
 .features-card-items .menu-card-item-border-0-1 .menu-card-item-title, .features-card-items .menu-card-item-border-0-1 .menu-card-item-subtitle {
     opacity: 1;
     transition: all 1s ease;
}
 .menu-card-item-title {
     opacity: 1 !important;
}
 .features-card-item:hover {
     z-index: 1000;
}undefined}undefined
    `
  },
  {
    display: {
      name: 'Features Card: Cards Left Column',
      documentationURL: 'https://www.notion.so/einsteinindustries/Features-Card-Cards-Left-Column-e4a557945b0446ffaee7940a4b8ace2c?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/feature_card-IID-135.png',
      description: 'Place your text in the Outro field.'
    },
    notion_IID: '135',
    allowedSections: [],
    css: `
 @media (min-width:1024px) {
     .section-content {
         display: flex;
         flex-wrap: wrap;
    }
     .section-heading {
         width: 100%;
    }
     .features-card-items {
         flex-flow: column !important;
         padding: 0 1rem;
         flex-basis: 40%;
         justify-content: flex-start;
    }
     .section-outro {
         display: flex;
         flex-flow: column;
         padding: 0 1rem;
         flex: 1;
    }
     .section-outro .column {
         max-width: unset;
         flex: 0 0 100%;
    }
     .features-card-items > .menu {
         max-width: 100% !important;
         width: 100% !important;
    }
     .features-card-item {
         display: flex;
    }
     .features-card-item-title {
         display: flex;
         text-align: left;
         flex: 1;
    }
     .features-card-item-subtitle {
         display: flex;
         justify-content: flex-end;
    }
}
    `
  }
]
