import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Slideshow staggered images',
      documentationURL: 'https://www.notion.so/einsteinindustries/Slideshow-staggered-images-ec4d1e0709ff47ba994a33a52fb7d3ee?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/slideshow-IID-89.png'
    },
    notion_IID: '89',
    allowedSections: [],
    css: `.section-container {
    max-width: 1680px;
\t\twidth: 98vw;

}

\t.swiper-slide img {
    position: relative;
\t\tz-index: -1;
\t}

\t/*alt swiper button */
\t.swiper-button-next, .slideshow-section .swiper-button-prev {
    background-color: transparent !important;

}
.slideshow {
    overflow: hidden;
\t}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: "prev";
    background: radial-gradient(100% 50% at 0% 50%, rgba(0,0,0,0.4) 99%, rgba(0,0,0,0) 100%) no-repeat 50% 50% / 100% 100%;
    padding: 3.5rem 2rem;
\t\tcolor: #fff;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: "next";
    background: radial-gradient(100% 50% at 100% 50%, rgba(0,0,0,0.4) 99%, rgba(0,0,0,0) 100%) no-repeat 50% 50% / 100% 100%;
    padding: 3.5rem 2rem;
\t\tcolor: #fff;

}
.slideshow-section .swiper-slide {
\tbox-shadow: 0px 0px 0px 3px #fff inset;
}
@media (min-width:1024px){
\t.slideshow-section .swiper-slide:nth-child(even) {
\t    margin-top: 6rem;
\t    margin-left: -3px;
\t    margin-right: -3px;
\t    padding-left: 3px;
\t    padding-right: 3px;
\t}
}`
  }
]
