type AssetType = 'ClientAsset' | 'EinsteinSharedAsset' | 'Unknown'

function checkAlphaNumeric(input: string): boolean {
    return /[a-z0-9]{16}/i.test(input)
}

function isClientAsset(pathParts: string[]): boolean {
    if (pathParts.length < 4) return false

    return (
        checkAlphaNumeric(pathParts[0]) &&
        pathParts[1] === 'einstein-clients' &&
        Number.isInteger(Number(pathParts[2])) &&
        pathParts[pathParts.length - 1].includes('.')
    )
}

function isEinsteinSharedAsset(pathParts: string[]): boolean {
    if (pathParts.length < 2) return false

    const filenameIndex = pathParts.findIndex((part, index) => index > 0 && part.includes('.'))

    return (
        checkAlphaNumeric(pathParts[0]) &&
        filenameIndex !== -1
    )
}

function classifyAsset(pathParts: string[]): AssetType {
    if (isClientAsset(pathParts)) return 'ClientAsset'
    if (isEinsteinSharedAsset(pathParts)) return 'EinsteinSharedAsset'
    return 'Unknown'
}

export default function constructAssetUrl(path: string): string {
    const pathParts = path.split('/')
    const assetType = classifyAsset(pathParts)
    const filePath = pathParts.slice(1).join('/')

    switch (assetType) {
        case 'ClientAsset':
            return `${process.env.NEXT_PUBLIC_IMGIX_CLIENT_SOURCE_URL}/${filePath}`
        case 'EinsteinSharedAsset':
            return `${process.env.NEXT_PUBLIC_IMGIX_EINSTEIN_SOURCE_URL}/${filePath}`
        default:
            return path
    }
}
