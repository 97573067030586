export function formatContent(content) {
  if (content.length > 500) {
    let position = content.substring(500).search(' ')
    if (position >= 0) {
      position = position + 500
    }
    content = content.substring(0, position)
  }
  return content
}

export function getUserInitialsAvatar(name) {
  let formattedName = name.split(' ').join('+')
  return `https://ui-avatars.com/api/?size=70&name=${formattedName}`
}

export function formatLinkText(publisherId) {
  switch (publisherId) {
    case 'GOOGLEMYBUSINESS':
      return 'Google'
    case 'YELP':
      return 'Yelp'
    case 'FACEBOOK':
      return 'Facebook'
    case 'FOURSQUARE':
      return 'Foursquare'
    case 'VITALS':
      return 'Vitals'
    case 'RATEABIZ':
      return 'RateABiz'
    case 'HEALTHGRADES':
      return 'Healthgrades'
    case 'REALSELF':
      return 'RealSelf'
    case 'AVVO':
      return 'Avvo'
    case 'RATEMDS':
      return 'RateMDs'
    case 'ZOCDOC':
      return 'Zocdoc'
    case 'CITYSEARCH':
      return 'Citysearch'
    default:
      return 'this site'
  }
}

export function publisherLogo(publisherId) {
  switch (publisherId) {
    case 'GOOGLEMYBUSINESS':
      return 'https://s3.amazonaws.com/flextemplates/fragments/review/google-logo.svg'
    case 'YELP':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/yelp-logo.svg'
    case 'FACEBOOK':
      return 'https://s3.amazonaws.com/flextemplates/fragments/review/facebook-logo.svg'
    case 'FOURSQUARE':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/foursquare-logo.png'
    case 'VITALS':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/Vitals_Icon.jpg'
    case 'RATEABIZ':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/rate-a-biz.png'
    case 'HEALTHGRADES':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/healthgrades-logo.png'
    case 'REALSELF':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/realself-logo.png'
    case 'AVVO':
      return 'https://s3.amazonaws.com/flextemplates/fragments/review/avvo-logo.svg'
    case 'RATEMDS':
      return 'https://d3quiyb59qw5ad.cloudfront.net/fragments/review/ratemds-logo.png'
    case 'ZOCDOC':
      return 'https://d3quiyb59qw5ad.cloudfront.net/fragments/review/zocdoc.png'
    case 'CITYSEARCH':
      return 'https://flextemplates.s3.amazonaws.com/fragments/review/citysearch-icon.png'
    default:
      return 'https://tools--dev.cms.eiidev.com/placeholder/64/64'
  }
}

export function mapReviewToFields(review) {
  const fields = [
    {'name': 'name'},
    {'name': 'date'},
    {'name': 'rating'},
    {'name': 'avatar_url'},
    {'name': 'avatar_alt'},
    {'name': 'source_logo'},
    {'name': 'review'},
    {'name': 'source_logo_alt'},
    {'name': 'source_link_text'},
    {'name': 'source_link_url'}
  ]
  const mapped = fields.map((field) => {
    switch (field.name) {
      case 'name':
        field.value = review.authorName
        break
      case 'date':
        field.value = new Date(review.publisherDate).getFullYear()
        break
      case 'rating':
        field.value = review.rating
        break
      case 'avatar_url':
        field.value = getUserInitialsAvatar(review.authorName)
        break
      case 'avatar_alt':
        field.value = ''
        break
      case 'source_logo':
        field.value = publisherLogo(review.publisherId)
        break
      case 'review':
        field.value = review.content
        break
      case 'source_logo_alt':
        field.value = `${formatLinkText(review.publisherId)}`
        break
      case 'source_link_text':
        field.value = `${formatLinkText(review.publisherId)}`
        break
      case 'source_link_url':
        field.value = review.url
        break
      default:
        break
    }

    return field
  })

  const formattedReview = {}
  formattedReview['_template'] = 'imported'
  for (let field of mapped) {
    formattedReview[field.name] = field.value
  }
  return formattedReview
}
