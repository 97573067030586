import {PresetList} from '@/components/shared/types'

/**
 * 1/1 Migrated
 *
 * 68 - Announcement A: Simple layout with thick image border
 * ✅ Migrated
 * ✅ Tested
 * Note: The background image must be set in the schemes.
 */
export const presets: PresetList = [
  {
    display: {
      name: 'Announcement A: Simple layout with thick image border ',
      documentationURL: 'https://www.notion.so/einsteinindustries/Announcement-A-Simple-layout-with-thick-image-border-797c68d9443d403d8978409c59937e12?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/announcement-IID-68.png'
    },
    notion_IID: '68',
    allowedSections: ['announcementa'],
    css: `
    .heading-1 { font-size: 48px; margin-bottom: 20px; }
.heading-4 { font-size: 20px; }
.section-container {
\tbackground-color: none;
}
.section-container::before,
.section-container::after {
\tbackground-color: none;
\tdisplay: none;
}
/* optional reduce spacing between this section and adjacent ones
section {
    padding: 0;
    margin: 0;
}

*/


/* CHANGE BORDER IMAGE LINK HERE */
.section-container {
  background: url("https://einstein-clients.imgix.net/7117/419394/original.jpeg?1669993354");
  padding: 40px;
  -webkit-box-shadow: 0px 6px 14px 0px #0005; 
  box-shadow: 0px 6px 14px 0px #0005;
}

.section-content { 
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding:4rem;
    -webkit-box-shadow: 0px 6px 14px 0px #0005; 
    box-shadow: 0px 6px 14px 0px #0005;

}
.row {
    position: relative;
    background: #fff;
    margin: 0;
    min-width: 100%;
}

/* optional column line
.color-scheme-10-background-color .column {
    border-right: 1px solid grey;
    margin-right: 100px;
}
*/
.column.shrink {
    border-right: none;
    margin-right: 0;
}
a.button { margin: 30px 0 0 0; }

@media (max-width: 1000px) {

.section-content { 
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding:4rem;
    -webkit-box-shadow: none; 
    box-shadow: none;

}

\t.section .section-container {
\t\tpadding: 30px;
\t}
\t.section {
padding-left: 1rem;
padding-right: 1rem;
padding-bottom: 0;
}
\t.section-container .section-content { padding: 30px; }
\t.section-heading { padding: 0; }
    .row {
\t\tborder-right: none;
   \t\tflex-direction: column;
\t}
\t .column:first-child {
\t   border-right: none;
\t   margin: 0;
\t}
}

.announcement_gutter_row_1 .row {
\t\tmargin-top: 0 !important;
\t\tmargin-bottom: 0 !important;
}
    `
  }
]
