import Section from '../shared/Section'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionButton from '../shared/SectionButton'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {LOCATIONS_ITEM_BLOCKS} from './config'
import {styleCore} from './stylecore'

export default function Locations(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className}
      name="locations"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </div>
              <div className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </div>
            </h1>
          </div>
          <RichtextWithVariables
            cms={cms}
            name="intro"
            richtext={intro}
          />
          <LucidInlineBlocks name="locations" blocks={{location: LOCATIONS_ITEM_BLOCKS}} itemProps={props} />
          <RichtextWithVariables
            cms={cms}
            name="outro"
            richtext={outro}
          />
          <SectionButton
            href={button?.url}
            label={button?.label}
            cms={cms}
          />
        </div>
      </div>
    </Section>
  )
}

Locations.StyleCore = styleCore
