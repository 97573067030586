import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Affiliations: Magazine Collage',
      documentationURL: 'https://www.notion.so/einsteinindustries/Affiliations-Magazine-Collage-51a8022d7035443ab6d4b3a28da6b24d?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/affiliations-IID-30.png',
    },
    notion_IID: '30',
    allowedSections: [],
    css: `
    /* Affiliations: Magazine Collage */
    /* This preset has not been implemented yet. */
    `
  },
  {
    display: {
      name: 'Black and White Affiliation logos',
      documentationURL: 'https://www.notion.so/einsteinindustries/Black-and-White-Affiliation-logos-1cd60312e1494f88b83a1e23bd2eabc1?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/affiliations-IID-23.png',
    },
    notion_IID: '23',
    allowedSections: [],
    css: `
    /* Affiliations Section: make logos black and white */
.affiliations-section .affiliations {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}
    `
  },
  {
    display: {
      name: 'Affiliations: Staggered Affiliations Gallery',
      documentationURL: 'https://www.notion.so/einsteinindustries/Affiliations-Staggered-Affiliations-Gallery-8e48b7c9c6c74d6d9044ace9a2ad5e15?pvs=4'
    },
    notion_IID: '112',
    allowedSections: [],
    css: `
    /* Staggered Gallery Color Scheme 8 ------------------- */
/* Allows up to 4 animated images */
.color-scheme-8.affiliations-section .image-wrapper span.image img{
    display: block;
    height: 100%;
    min-height: 40rem;
    width: 100%;
    object-fit: cover;
}
.color-scheme-8.affiliations-section .image-wrapper span.image img{
    margin-top: 1rem;
}
@media (min-width: 641px) {
    .color-scheme-8.affiliations-section .count-1,
    .color-scheme-8.affiliations-section .count-2,
    .color-scheme-8.affiliations-section .count-3 {
        justify-content: space-around !important;
        display: flex;
        padding: 0 !important;
        margin: 0 !important;
        min-width: 100%;
        width: 0 !important;
    }
    .color-scheme-8.affiliations-section .images-container :nth-child(even) {
        padding-top:10rem !important;
    }
    .color-scheme-8.affiliations-section .image-wrapper span.image img{
        margin: 0rem;
    }
    .color-scheme-8.affiliations-section .affiliations_gutter_column_1 .image-wrapper,
    .color-scheme-8.affiliations-section .affiliations_gutter_column_2 .image-wrapper,
    .color-scheme-8.affiliations-section .affiliations_gutter_column_3 .image-wrapper{
        flex: 1 !important;
        max-width: 100% !important;
    }
\t\t/* Optional border */
    .color-scheme-8.affiliations-section .affiliations .images-container img {
        border: 1px solid #fff;
    }
}
    `
  },
  {
    display: {
      name: 'Single-Row Affiliations',
      documentationURL: 'https://www.notion.so/einsteinindustries/Single-Row-Affiliations-dfd904584a50424f8b9e4b5c54b0fb80?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/affiliations-IID-104.png',
      description: 'This is an alternate version to this named "Single-Row Affiliations (Alternate)"'
    },
    notion_IID: '104',
    allowedSections: [],
    css: `
    /* Single-row affiliations with window-size adjustments */
/* NOTE: Make the section full-width ------------------ */
@media (min-width:1024px) {
    .affiliations-section .affiliations {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .affiliations-section .images-container {
        margin: auto;
    }

.affiliations-section .images-container.count-2 .image-wrapper {
  flex-basis: calc(50% - 4rem);
  max-width: calc(50% - 4rem);
}
}
    `
  },
  {
    display: {
      name: 'Single-Row Affiliations (Alternate)',
      documentationURL: 'https://www.notion.so/einsteinindustries/Single-Row-Affiliations-dfd904584a50424f8b9e4b5c54b0fb80?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/affiliations-IID-104.png',
      description: 'This is an alternate version to this named "Single-Row Affiliations"'
    },
    notion_IID: '104',
    allowedSections: [],
    css: `
    /* Single-row affiliations with window-size adjustments */
/* NOTE: Make the section full-width ------------------ */
@media (min-width:1024px) {
    .affiliations-section .affiliations {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
justify-content: center;

    }
    .affiliations-section .images-container {
        margin: auto;
    }

.affiliations-section .images-container.count-2 .image-wrapper {
  flex-basis: calc(50% - 4rem);
  max-width: calc(50% - 4rem);
}
.affiliations-section .images-container{
  margin: 0;
}
}
    `
  },
  {
    display: {
      name: 'Animation: Animate section content only instead of entire section',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-42.gif',
      documentationURL: 'https://www.notion.so/einsteinindustries/Animation-Animate-section-content-only-instead-of-entire-section-24bf985d3afd45dea65ac67a9785c1d8?pvs=4'
    },
    notion_IID: '42',
    allowedSections: ['all'],
    css: `
    /* Animation: Animate section content only instead of entire section */
        section.animated.effect-fade-in-up {
            animation-play-state: paused !important;
            animation-direction: reverse !important; 
        \t\tz-index: 1;
        }
        section.animated.effect-fade-in-up.back-stack {
            z-index: 0;
        }
        section.animated.effect-fade-in-up .section-content {
            animation: fadeInUp 1.25s cubic-bezier(.76,0,.24,1) 1 !important;
            z-index: 2;
            position: relative;
        }
        section.back-stack.animated.effect-fade-in-up .section-container {
          background-color: transparent;
        }
        
        .animate.effect-fade-in-up {
            visibility: visible;
        }
        .animate.effect-fade-in-up .section-container {
            visibility: hidden;
        }
    `
  },
  {
    display: {
      name: 'Box Shadows for Cards, Buttons, Videos, Photos',
      documentationURL: 'https://www.notion.so/einsteinindustries/Box-Shadows-for-Cards-Buttons-Videos-Photos-ced8ae99abfd4f929c9bcc2f6d4da37a?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-33.png',
    },
    notion_IID: '33',
    allowedSections: ['all'],
    css: `
      /* Box Shadows for Cards, Buttons, Videos, Photos*/
        .menu-photo-card-section .menu.column,
        .blog-card-section .menu.column,
        .features-card-section .menu.column,
        .menu-icon-card-section .menu.column,
        .reviews-item, .video video, .button, figure img  {
            box-shadow: 0px 10px 15px #0000001a;
        }
    `
  },
  {
    display: {
      name: 'Scheme: Responsive Left and Right Background Images',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-25.gif',
      documentationURL: 'https://www.notion.so/einsteinindustries/Scheme-Responsive-Left-and-Right-Background-Images-413b2513ac8645089c3cad8976a2fb6a?pvs=4'
    },
    notion_IID: '25',
    allowedSections: ['all'],
    css: `
             /* Scheme: Responsive Left and Right Background Images */
       @media (min-width:640px) {
       section.color-scheme-7 .section-container:before, section.color-scheme-7 .section-container:after, section.color-scheme-8 .section-container:before, section.color-scheme-8 .section-container:after {
           content: '';
           max-width: 500px;
           width: 22vw;
           height: 100%;
           background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7309/342081/original.svg?1612221033);
           position: absolute;
           right: 0;
           top: 0;
           background-size: contain;
           background-repeat: no-repeat;
           background-position: top right;
       
       }
       
       section.color-scheme-7 .section-container:after,
       section.color-scheme-8 .section-container:after {
           background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7309/342083/original.svg?1612221088);
           bottom: 0;
           left: 0;
           background-position: bottom left;
           max-width: 700px;
           width: 27vw;
       }
       }
       
       
       section.color-scheme-8 .section-container:before {
           background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7309/342863/original.svg?1612485851);
           left: 0;
           background-position: top left;
           z-index: 1;
           width: 40vw;
       }
       section.color-scheme-8 .section-container:after {
           background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7309/342865/original.svg?1612485852);
           right: 0;
           left: initial;
           background-position: bottom right;
           width: 32vw;
       }
    `
  },
  {
    display: {
      name: 'Front Sections: Rounded corners',
      documentationURL: 'https://www.notion.so/einsteinindustries/Front-Sections-Rounded-corners-16b51cdd469e4381be7b04feaf86b191?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-44.gif',
    },
    notion_IID: '44',
    allowedSections: ['all'],
    css: `
      /* Front Sections: All corners rounded */
        .front-stack .section-container:before   {
            border-top-left-radius: 40px; 
            border-top-right-radius: 40px;
        }
        .front-stack .section-container:after   {
            border-bottom-left-radius: 40px; 
            border-bottom-right-radius: 40px;
        }
        
        /* Front Sections: All corners rounded */
        .front-stack .section-container:before, .front-stack .section-background, .front-stack .section-custom-background   {
            border-top-left-radius: 40px;
        }
        .front-stack .section-container:after, .front-stack .section-background, .front-stack .section-custom-background   {
            border-bottom-left-radius: 40p}
    `
  },
  {
    display: {
      name: 'Background Gradient for sections set to "front"',
      documentationURL: 'https://www.notion.so/einsteinindustries/Background-Gradient-for-sections-set-to-front-7cd1e6fada0c4fd18bb14ae16be2c822?pvs=4',
    },
    notion_IID: '11',
    allowedSections: ['all'],
    css: `
      .color-scheme-4-background-color.back-stack {
          background-color: #AF913D;
          background: #ffffff;
          /* Old browsers */
          background: -moz-linear-gradient(top, #ffffff 0%, #AF913D 50%, #ffffff 100%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #ffffff 0%, #AF913D 50%, #ffffff 100%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #ffffff 0%, #AF913D 50%, #ffffff 100%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
          /* IE6-9 */
      }
    `
  },
  {
    display: {
      name: 'Matte Background Images',
      documentationURL: 'https://www.notion.so/einsteinindustries/Matte-Background-Images-2d6784857c8547feb2df3d88e1908135?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-36.png',
      description: `
      1. Choose a scheme you'd like to apply the matte background to and make sure it's number matches with the code below. 
      2. Change the url for the image to the one you'd like to use
      `
    },
    notion_IID: '36',
    allowedSections: ['all'],
    css: `
      /* Matte Background Images */
      .color-scheme-14-background-color.front-stack {
          background-image: url(https://s3.amazonaws.com/eii-lucid/assets/7337/345727/original.svg?1614275630);
          background-size: cover;
      }
    `
  },
  {
    display: {
      name: 'Front Sections with no overlap',
      documentationURL: 'https://www.notion.so/einsteinindustries/Front-Sections-with-no-overlap-2ebb940dede14f95a537e6c2fa48093f?pvs=4',
      description: `
        - Add Wildcard sections before and after the section you want to have space around.
        - Set scheme in \`Site Setup > Schemes\`  to have no value for it's background color
        - Set each Wildcard section to that scheme. Now the sections will show whatever colors and backgrounds are set \`Site Setup > Style > Body\`
        - Set the section you want to have space around to "front" under \`Click-to-edit > Style > Stacking Order\`
        - Change the number in \`color-scheme-#\` below to match the scheme you used for the wild card section
      `,
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-35.gif',
    },
    notion_IID: '35',
    allowedSections: ['all'],
    css: `
      /*Front Section with no overlap */
        @media (min-width:1024px) {
            section.section.wildcard-section.back-stack.color-scheme-7 {
                padding: 3rem;
            }
        }
    `
  },
  {
    display: {
      name: 'Cascading animation for each item in section',
      documentationURL: 'https://www.notion.so/einsteinindustries/Cascading-animation-for-each-item-in-section-adec8b5c165044769044ff9c726df051?pvs=4',
    },
    notion_IID: '90',
    allowedSections: ['all'],
    css: `
    /* Cascading animation for each item in section */
  section.animated.effect-fade-in-up .section-container {
    animation-play-state: paused !important;
    animation-direction: reverse !important;
  }

  section.animate.effect-fade-in-up .row>div,
  section.animate.effect-fade-in-up .section-heading,
  section.animate.effect-fade-in-up .section-button {
    visibility: hidden;
  }

  section.animated.effect-fade-in-up .row>div,
  section.animated.effect-fade-in-up .section-heading,
  section.animated.effect-fade-in-up .section-button {
    animation: fadeInUp 1.25s;
  }

  section.effect-fade-in-up .row>div,
  section.effect-fade-in-up .section-heading,
  section.effect-fade-in-up .section-button {
    animation-fill-mode: backwards !important;
  }

  section.effect-fade-in-up .row>div:nth-of-type(1) {
    animation-delay: .3s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(2) {
    animation-delay: .6s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(3) {
    animation-delay: .9s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(4) {
    animation-delay: 1.1s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(5) {
    animation-delay: 1.4s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(6) {
    animation-delay: 1.7s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(7) {
    animation-delay: 2s;
  }

  section.effect-fade-in-up .row>div:nth-of-type(8) {
    animation-delay: 2.3s;
  }

  section.effect-fade-in-up .section-button {
    animation-delay: .6s !important;
  }
    `
  },
  {
    display: {
      name: 'Animation: Convert all animations to fade and NOT slide up',
      documentationURL: 'https://www.notion.so/einsteinindustries/Animation-Convert-all-animations-to-fade-and-NOT-slide-up-deae6badd0e542d8b11dac05f001ce01?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-38.gif',
    },
    notion_IID: '38',
    allowedSections: ['all'],
    css: `
    /*Animation: Convert all animations to fade and NOT slide up */
.animated.effect-fade-in-up {
    animation: fadeIn 1.25s cubic-bezier(.76,0,.24,1) 1 !important;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
    `
  },
  {
    display: {
      name: 'Button Alignment',
      documentationURL: 'https://www.notion.so/einsteinindustries/Button-Alignment-a20216bfefd54448bd4ccfd07f89f06f?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-65.gif',
    },
    notion_IID: '65',
    allowedSections: ['all'],
    css: `
    /* Textblock Columns: Left align buttons */
.column-1-button, .column-2-button, .column-3-button {
    text-align: left;
}
    `
  },
  {
    display: {
      name: 'All Sections: Increase section default width on desktop'
    },
    notion_IID: '158',
    allowedSections: ['all'],
    css: `
    
#layout {
overflow: hidden;
}

.row:not(.overlay-wrapper) {
  max-width: 1600px;
}

@media (min-width:1024px) { 

.desktop-menu {
  max-width: 1600px;
  margin: 0 auto;
} 
}




/* stops single column sections from going too wide to be readable */
.column:only-child {
  max-width: 736px;
}
.menu-section .section-heading {
  max-width: 1600px;

}

.section-container {
  max-width: 1600px;

}

.menu-section .section-heading {
  max-width: 1600px;

}
    `
  },
  {
    display: {
      name: 'All Sections: Color Scheme Transitions',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-157.png',
    },
    notion_IID: '157',
    allowedSections: ['all'],
    css: `
    
.color-scheme-5-background-image {
\t\tborder-top: 60vh solid #ECECEC;
\t\topacity: 1;
}

@media (max-width:1024px) {
\t.color-scheme-5-background-image {
\t\t\tborder-top: 250px solid #ECECEC;
\t}
}
    `
  },
  {
    display: {
      name: 'Any Section: Bottom Gradient and top Gradient',
      documentationURL: 'https://www.notion.so/einsteinindustries/Any-Section-Bottom-Gradient-and-top-Gradient-7c53922e90ef495aa809a1b33b72e396?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-150.png',
    },
    notion_IID: '150',
    allowedSections: ['all'],
    css: `
    /* Bottom gradient */
    @media (min-width:1024px) {
section.color-scheme-3 .section-custom-background::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0,0,0,0) 20%);
}
}
/* Top gradient */
@media (min-width:1024px) {
section.color-scheme-3 .section-custom-background::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0) 20%);
}
}
    `
  },
  {
    display: {
      name: 'Side padding for full-width sections',
      documentationURL: 'https://www.notion.so/einsteinindustries/Side-padding-for-full-width-sections-87384163ca2348ad8bbd3fe0afa55e63?pvs=4',
      description: 'This can be helpful if you want to generally have widescreen experiences across a site, but don’t want to be setting widths on individual sections'
    },
    notion_IID: '144',
    allowedSections: ['all'],
    css: `
      @media (min-width: 1024px){

section.full-width {
  padding-left: 4rem;
  padding-right: 4rem;
}

}
    `
  },
  {
    display: {
      name: 'Reduce spacing between sections when they are the same color scheme (good for models and visual grouping)',
      documentationURL: 'https://www.notion.so/einsteinindustries/Reduce-spacing-between-sections-when-they-are-the-same-color-scheme-good-for-models-and-visual-grou-661ce4dbf69a43dfb3c1d70d83d27c74?pvs=4'
    },
    notion_IID: '119',
    allowedSections: ['all'],
    css: `
    /* Reduce spacing between sections when they are the same color scheme (good for models and visual grouping) */

section.color-scheme-0.back-stack + style + section.color-scheme-0.back-stack {
  padding-top: 2rem;
}
section.color-scheme-1.back-stack + style + section.color-scheme-1.back-stack {
  padding-top: 2rem;
}
section.color-scheme-2.back-stack + style + section.color-scheme-2.back-stack {
  padding-top: 2rem;
}
section.color-scheme-3.back-stack + style + section.color-scheme-3.back-stack {
  padding-top: 2rem;
}
section.color-scheme-4.back-stack + style + section.color-scheme-4.back-stack {
  padding-top: 2rem;
}
section.color-scheme-5.back-stack + style + section.color-scheme-5.back-stack {
  padding-top: 2rem;
}
section.color-scheme-6.back-stack + style + section.color-scheme-6.back-stack {
  padding-top: 2rem;
}
section.color-scheme-7.back-stack + style + section.color-scheme-7.back-stack {
  padding-top: 2rem;
}
section.color-scheme-8.back-stack + style + section.color-scheme-8.back-stack {
  padding-top: 2rem;
}
section.color-scheme-9.back-stack + style + section.color-scheme-9.back-stack {
  padding-top: 2rem;
}
section.color-scheme-10.back-stack + style + section.color-scheme-10.back-stack {
  padding-top: 2rem;
}
section.color-scheme-11.back-stack + style + section.color-scheme-11.back-stack {
  padding-top: 2rem;
}
section.color-scheme-12.back-stack + style + section.color-scheme-12.back-stack {
  padding-top: 2rem;
}
section.color-scheme-13.back-stack + style + section.color-scheme-13.back-stack {
  padding-top: 2rem;
}
section.color-scheme-14.back-stack + style + section.color-scheme-14.back-stack {
  padding-top: 2rem;
}

section.color-scheme-0.back-stack + section.color-scheme-0.back-stack {
  padding-top: 2rem;
}
section.color-scheme-1.back-stack + section.color-scheme-1.back-stack {
  padding-top: 2rem;
}
section.color-scheme-2.back-stack + section.color-scheme-2.back-stack {
  padding-top: 2rem;
}
section.color-scheme-3.back-stack + section.color-scheme-3.back-stack {
  padding-top: 2rem;
}
section.color-scheme-4.back-stack + section.color-scheme-4.back-stack {
  padding-top: 2rem;
}
section.color-scheme-5.back-stack + section.color-scheme-5.back-stack {
  padding-top: 2rem;
}
section.color-scheme-6.back-stack + section.color-scheme-6.back-stack {
  padding-top: 2rem;
}
section.color-scheme-7.back-stack + section.color-scheme-7.back-stack {
  padding-top: 2rem;
}
section.color-scheme-8.back-stack + section.color-scheme-8.back-stack {
  padding-top: 2rem;
}
section.color-scheme-9.back-stack + section.color-scheme-9.back-stack {
  padding-top: 2rem;
}
section.color-scheme-10.back-stack + section.color-scheme-10.back-stack {
  padding-top: 2rem;
}
section.color-scheme-11.back-stack + section.color-scheme-11.back-stack {
  padding-top: 2rem;
}
section.color-scheme-12.back-stack + section.color-scheme-12.back-stack {
  padding-top: 2rem;
}
section.color-scheme-13.back-stack + section.color-scheme-13.back-stack {
  padding-top: 2rem;
}
section.color-scheme-14.back-stack + section.color-scheme-14.back-stack {
  padding-top: 2rem;
}

section.color-scheme-0.front-stack + style + section.color-scheme-0.front-stack {
  padding-top: 2rem;
}
section.color-scheme-1.front-stack + style + section.color-scheme-1.front-stack {
  padding-top: 2rem;
}
section.color-scheme-2.front-stack + style + section.color-scheme-2.front-stack {
  padding-top: 2rem;
}
section.color-scheme-3.front-stack + style + section.color-scheme-3.front-stack {
  padding-top: 2rem;
}
section.color-scheme-4.front-stack + style + section.color-scheme-4.front-stack {
  padding-top: 2rem;
}
section.color-scheme-5.front-stack + style + section.color-scheme-5.front-stack {
  padding-top: 2rem;
}
section.color-scheme-6.front-stack + style + section.color-scheme-6.front-stack {
  padding-top: 2rem;
}
section.color-scheme-7.front-stack + style + section.color-scheme-7.front-stack {
  padding-top: 2rem;
}
section.color-scheme-8.front-stack + style + section.color-scheme-8.front-stack {
  padding-top: 2rem;
}
section.color-scheme-9.front-stack + style + section.color-scheme-9.front-stack {
  padding-top: 2rem;
}
section.color-scheme-10.front-stack + style + section.color-scheme-10.front-stack {
  padding-top: 2rem;
}
section.color-scheme-11.front-stack + style + section.color-scheme-11.front-stack {
  padding-top: 2rem;
}
section.color-scheme-12.front-stack + style + section.color-scheme-12.front-stack {
  padding-top: 2rem;
}
section.color-scheme-13.front-stack + style + section.color-scheme-13.front-stack {
  padding-top: 2rem;
}
section.color-scheme-14.front-stack + style + section.color-scheme-14.front-stack {
  padding-top: 2rem;
}
section.color-scheme-15.front-stack + style + section.color-scheme-15.front-stack {
  padding-top: 2rem;
}
section.color-scheme-16.front-stack + style + section.color-scheme-16.front-stack {
  padding-top: 2rem;
}
section.color-scheme-17.front-stack + style + section.color-scheme-17.front-stack {
  padding-top: 2rem;
}
section.color-scheme-18.front-stack + style + section.color-scheme-18.front-stack {
  padding-top: 2rem;
}
section.color-scheme-19.front-stack + style + section.color-scheme-19.front-stack {
  padding-top: 2rem;
}
section.color-scheme-20.front-stack + style + section.color-scheme-20.front-stack {
  padding-top: 2rem;
}

section.color-scheme-0.front-stack + section.color-scheme-0.front-stack {
  padding-top: 2rem;
}
section.color-scheme-1.front-stack + section.color-scheme-1.front-stack {
  padding-top: 2rem;
}
section.color-scheme-2.front-stack + section.color-scheme-2.front-stack {
  padding-top: 2rem;
}
section.color-scheme-3.front-stack + section.color-scheme-3.front-stack {
  padding-top: 2rem;
}
section.color-scheme-4.front-stack + section.color-scheme-4.front-stack {
  padding-top: 2rem;
}
section.color-scheme-5.front-stack + section.color-scheme-5.front-stack {
  padding-top: 2rem;
}
section.color-scheme-6.front-stack + section.color-scheme-6.front-stack {
  padding-top: 2rem;
}
section.color-scheme-7.front-stack + section.color-scheme-7.front-stack {
  padding-top: 2rem;
}
section.color-scheme-8.front-stack + section.color-scheme-8.front-stack {
  padding-top: 2rem;
}
section.color-scheme-9.front-stack + section.color-scheme-9.front-stack {
  padding-top: 2rem;
}
section.color-scheme-10.front-stack + section.color-scheme-10.front-stack {
  padding-top: 2rem;
}
section.color-scheme-11.front-stack + section.color-scheme-11.front-stack {
  padding-top: 2rem;
}
section.color-scheme-12.front-stack + section.color-scheme-12.front-stack {
  padding-top: 2rem;
}
section.color-scheme-13.front-stack + section.color-scheme-13.front-stack {
  padding-top: 2rem;
}
section.color-scheme-14.front-stack + section.color-scheme-14.front-stack {
  padding-top: 2rem;
}
section.color-scheme-15.front-stack + section.color-scheme-15.front-stack {
  padding-top: 2rem;
}
section.color-scheme-16.front-stack + section.color-scheme-16.front-stack {
  padding-top: 2rem;
}
section.color-scheme-17.front-stack + section.color-scheme-17.front-stack {
  padding-top: 2rem;
}
section.color-scheme-18.front-stack + section.color-scheme-18.front-stack {
  padding-top: 2rem;
}
section.color-scheme-19.front-stack + section.color-scheme-19.front-stack {
  padding-top: 2rem;
}
section.color-scheme-20.front-stack + section.color-scheme-20.front-stack {
  padding-top: 2rem;
}
    `
  },
  {
    display: {
      name: 'Max width / box for entire body with gutters on sides (NOT RECOMMENDED)',
      description: 'This preset is not recommended because it is a site wide change that will affect all sections. It is better to use the "Side padding for full-width sections" preset above.',
      documentationURL: 'https://www.notion.so/einsteinindustries/Max-width-box-for-entire-body-with-gutters-on-sides-79ce17cbe9214f3ea7416d66c046f6c5?pvs=4'
    },
    notion_IID: '91',
    allowedSections: ['all'],
    css: `
    html.xlucid_apploaded.xlucid_unlocked #layout {
    margin-left: auto !important;    
 }

.home-page main {
    overflow: hidden;
}
body {
    background-color: #e0e0e0;
    } 
    
.fixed-navigation {
    max-width: 1500px;
    }

.hero-video {
    max-width: 1500px;
}

.hero-video video {
    max-width: 1500px;
    }
    
    .layout {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
    `
  },
  {
    display: {
      name: 'Buttons: Add Texture',
      documentationURL: 'https://www.notion.so/einsteinindustries/Buttons-Add-Texture-9fff3149b182448dbbd4560ab9270e78?pvs=4',
    },
    notion_IID: '56',
    allowedSections: ['all'],
    css: `
.section:not(.color-scheme-2) a.button {
    background-image:url('[https://d1l9wtg77iuzz5.cloudfront.net/assets/2428/323043/original.jpg?1603992964](https://d1l9wtg77iuzz5.cloudfront.net/assets/2428/323043/original.jpg?1603992964)');
    background-size: cover;
}
    `
  },
  {
    display: {
      name: 'Bordered Button',
      documentationURL: 'https://www.notion.so/einsteinindustries/Bordered-Button-09b7907bc24e446db029985a5cf3ed77?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/all-IID-139.jpg',
    },
    notion_IID: '139',
    allowedSections: ['all'],
    css: `
    
    .button {
  position: relative;
  background: transparent !important;
}

.layout .tns-controls button span,
.layout a.button span,
.layout button.button span,
.overlay-section .button span {
  padding: 1.25rem 2rem;
}

.button::before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #b36d70;
  transition: all 200ms cubic-bezier(0.500, 0.000, 0.500, 1.000);
  transition-timing-function: cubic-bezier(0.500, 0.000, 0.500, 1.000);
}

.button:hover::before {
  width: 100%;
}

.button::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #b36d70;
}
    `
  },
  {
    display: {
      name: 'Button gradient animation on hover',
      documentationURL: 'https://www.notion.so/einsteinindustries/Button-gradient-animation-on-hover-5cd6c2fdb8e84b4c9b4d8fc4d48bc762?pvs=4'
    },
    notion_IID: '137',
    allowedSections: ['all'],
    css: `
    .color-scheme-1-button, .color-scheme-1 .button {
  background: linear-gradient(-45deg, #0c4243,#166153,  #2F8263,#166153,#0c4243);
  background-size: 300%;
  transition: all .8s ease-in-out !important;
    background-position: 100% 0 ;
  }
  .color-scheme-1-button:hover, .color-scheme-1 .button:hover {
  transition: all .8s ease-in-out !important;
    background-position: 0% 0 ;
  
  }
    `
  },
  {
    display: {
      name: 'Button: Sliding Hover Effect',
      documentationURL: 'https://www.notion.so/einsteinindustries/Button-Sliding-Hover-Effect-2a8e2c63a4df4e0090aa632a8c285b32?pvs=4'
    },
    notion_IID: '110',
    allowedSections: ['all'],
    css: `
    /* Button Setup */
.button {
    position: relative;
    background: #0000 !important;
}
.button::before {
    content:'';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    transition: all 200ms cubic-bezier(0.500, 0.000, 0.500, 1.000);
    transition-timing-function: cubic-bezier(0.500, 0.000, 0.500, 1.000); 
}
.button:hover::before { width: 100%; }
.button::after {
    content:'';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Buttons Colors for Light Scheme */
/* Text color */
.button span { color: #434641 !important; }
/* Hovered background */
.button::before { background: #ddc3a8 !important; }
/* Border color */
.button::after { border: 4px solid #434641; }

/* Buttons Colors for Dark Schemes and scheme 0 */
/* Background color */
section.color-scheme-0 .button { background: #002863; !important}
/* Text color */
section.color-scheme-0 .button span { color: #fff !important; }
/* Hovered background */
section.color-scheme-0 .button::before { background: #ddc3a8 !important; }
/* Border color */
section.color-scheme-0 .button::after { border: 4px solid #fff; }
    `
  },
  {
    display: {
      name: 'Paragraphs: Left align on mobile resolutions',
      documentationURL: 'https://www.notion.so/einsteinindustries/Paragraphs-Left-align-on-mobile-resolutions-61eb423c0985462aabfd4580ba0b6108?pvs=4'
    },
    notion_IID: '66',
    allowedSections: ['all'],
    css: `
    @media (max-width:640px) {
  p {
    text-align: left !important;
  }
}
    `
  },
  {
    display: {
      name: 'fix blockquote link size',
      documentationURL: 'https://www.notion.so/einsteinindustries/fix-blockquote-link-size-be4fa65e4ac4497eb34392074a6e45f3?pvs=4'
    },
    notion_IID: '131',
    allowedSections: ['all'],
    css: `
    blockquote a {
  font-size: inherit !important;
}
    `
  }
]
