import icons from './icons'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    social_account_logo: 'facebook',
    social_account_url: 'https://www.facebook.com',
  },
  itemProps: (item) => {
    if (!item.title) {
      return
    }
    return {label: item.title}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'social_account_logo',
      label: 'Source Logo',
      component: 'select',
      options: [
        'angi',
        'avvo',
        'docshop',
        'doctoroogle',
        'facebook',
        'flikr',
        'foursquare',
        'google',
        'healthgrades',
        'instagram',
        'lawyershop',
        'linkedin',
        'pinterest',
        'realself',
        'snapchat',
        'tiktok',
        'twitter',
        'vimeo',
        'yelp',
        'youtube',
        'zocdoc',
      ],
    },
    {
      name: 'social_account_url',
      label: 'Source Link URL',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  socialstripe: {
    ...baseItem,
    label: 'Item',
    defaultItem: {
      ...baseItem.defaultItem,
    },
    fields: [...baseItem.fields],
  },
}

// InlineBlocks `blocks`
export const SOCIAL_STRIPE_ITEM_BLOCKS = {
  socialstripe: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index} cms={cms}>
          <a href={data.social_account_url} target="_blank" rel="noreferrer">
            {/* {data.social_account_logo} */}
            {/* {icons[data.social_account_logo]} */}
            {data.social_account_logo == 'angi' ? <icons.angi /> : null}
            {data.social_account_logo == 'avvo' ? <icons.avvo /> : null}
            {data.social_account_logo == 'docshop' ? <icons.docshop /> : null}
            {data.social_account_logo == 'doctoroogle' ? (
              <icons.doctoroogle />
            ) : null}
            {data.social_account_logo == 'facebook' ? <icons.facebook /> : null}
            {data.social_account_logo == 'flikr' ? <icons.flikr /> : null}
            {data.social_account_logo == 'foursquare' ? (
              <icons.foursquare />
            ) : null}
            {data.social_account_logo == 'google' ? <icons.google /> : null}
            {data.social_account_logo == 'healthgrades' ? (
              <icons.healthgrades />
            ) : null}
            {data.social_account_logo == 'instagram' ? (
              <icons.instagram />
            ) : null}
            {data.social_account_logo == 'lawyershop' ? (
              <icons.lawyershop />
            ) : null}
            {data.social_account_logo == 'linkedin' ? <icons.linkedin /> : null}
            {data.social_account_logo == 'pinterest' ? (
              <icons.pinterest />
            ) : null}
            {data.social_account_logo == 'realself' ? <icons.realself /> : null}
            {data.social_account_logo == 'snapchat' ? <icons.snapchat /> : null}
            {data.social_account_logo == 'tiktok' ? <icons.tiktok /> : null}
            {data.social_account_logo == 'twitter' ? <icons.twitter /> : null}
            {data.social_account_logo == 'vimeo' ? <icons.vimeo /> : null}
            {data.social_account_logo == 'yelp' ? <icons.yelp /> : null}
            {data.social_account_logo == 'youtube' ? <icons.youtube /> : null}
            {data.social_account_logo == 'zocdoc' ? <icons.zocdoc /> : null}
          </a>
        </ItemWrapper>
      )
    },
    template: itemTemplates.socialstripe,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      cms={props.cms}
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
