import Affiliations from './Affiliations'
import AnnouncementA from './AnnouncementA'
import AnnouncementB from './AnnouncementB'
import Blog from './Blog'
import BlogBlock from './BlogBlock'
import BlogCard from './BlogCard'
import BMICalculator from './BMICalculator'
import Cliffhanger from './Cliffhanger'
import Closer from './Closer'
import ContactCard from './ContactCard'
import ContactForm from './ContactForm'
import FeaturesCard from './FeaturesCard'
import FeaturesList from './FeaturesList'
import Footer from './Footer'
import Hero from './Hero'
import HeroPreface from './HeroPreface'
import HeroTagline from './HeroTagline'
import Image from './Image'
import ImageWide from './ImageWide'
import Intro from './Intro'
import Location from './Location'
import Locations from './Locations'
import MegaMenuBlock from './MegaMenuBlock'
import MenuBlock from './MenuBlock'
import MenuBlockPortrait from './MenuBlockPortrait'
import MenuIcon from './MenuIcon'
import MenuIconCard from './MenuIconCard'
import MenuPhoto from './MenuPhoto'
import MenuPhotoCard from './MenuPhotoCard'
import MenuText from './MenuText'
import Navigation from './Navigation'
import OfficeTour from './OfficeTour'
import PhotoSet from './PhotoSet'
import PhotoSetCollage from './PhotoSetCollage'
import Pods from './Pods'
import PodsPhotoSetCard from './PodsPhotoSetCard'
import ProfileCard from './ProfileCard'
import Quote from './Quote'
import QuoteImage from './QuoteImage'
import QuoteImageLinks from './QuoteImageLinks'
import Reviews from './Reviews'
import Slideshow from './Slideshow'
import SocialStripe from './SocialStripe'
import TextblockColumns2 from './TextblockColumns2'
import TextblockColumns3 from './TextblockColumns3'
import Textblock from './Textblock'
import TextblockImage from './TextblockImage'
import TextblockImageCollageA from './TextblockImageCollageA'
import TextblockImageCollageB from './TextblockImageCollageB'
import TextblockImageCollageC from './TextblockImageCollageC'
import TextblockQuoteCarousel from './TextblockQuoteCarousel'
import Timeline from './Timeline'
import TitleAndImageCollage from './TitleAndImageCollage'
import Video from './Video'
import VideoLooping from './VideoLooping'
import VideoLoopingWide from './VideoLoopingWide'
import VideoWide from './VideoWide'
import Wildcard from './Wildcard'
import HeroComparison from './HeroComparison'

const Sections = {
  Affiliations,
  AnnouncementA,
  AnnouncementB,
  Blog,
  BlogBlock,
  BlogCard,
  BMICalculator,
  Cliffhanger,
  Closer,
  ContactCard,
  ContactForm,
  FeaturesCard,
  FeaturesList,
  Footer,
  Hero,
  HeroPreface,
  HeroTagline,
  HeroComparison,
  Image,
  ImageWide,
  Intro,
  Location,
  Locations,
  MegaMenuBlock,
  MenuBlock,
  MenuBlockPortrait,
  MenuIcon,
  MenuIconCard,
  MenuPhoto,
  MenuPhotoCard,
  MenuText,
  Navigation,
  OfficeTour,
  PhotoSet,
  PhotoSetCollage,
  Pods,
  PodsPhotoSetCard,
  ProfileCard,
  Quote,
  QuoteImage,
  QuoteImageLinks,
  Reviews,
  Slideshow,
  SocialStripe,
  Textblock,
  TextblockImage,
  TextblockColumns2,
  TextblockColumns3,
  TextblockImageCollageA,
  TextblockImageCollageB,
  TextblockImageCollageC,
  TextblockQuoteCarousel,
  Timeline,
  TitleAndImageCollage,
  Video,
  VideoLooping,
  VideoLoopingWide,
  VideoWide,
  Wildcard,
}

export default Sections
