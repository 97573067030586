import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/HeroTagline/presets'

export default function HeroTagline(props) {
  const {
    title,
    subtitle,
    caption,
    button,
    image,
    image_mobile,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props

  let CaptionElement = null
    if (caption) {
      CaptionElement = <div className="row hero-caption flexbox-alignment bottom-right mobile-bottom">
        <div className="column">
          <p className="caption">{caption}</p>
        </div>
      </div>
    }
    let HeroButton = null
    if (button?.url && button?.label) {
      HeroButton = <SectionButton label={button?.label} href={button?.url} cms={cms} name={'button'} />
    }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section hero-tagline-section back-stack bar-enabled'}
      name="herotagline"
      heroImage={image?.src}
      heroImageMobile={image_mobile?.src}
      section_background={section_background}
    >
      <div className="top-bar show-for-large site section back-stack hero-inner-section">
      </div>
      <div className="section-container">
        <div className="section-content">
          <div className="row hero-tagline flexbox-alignment middle mobile-middle">
            <div className="column">
              <div className="section-heading">
                <h2>
                  <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
                  <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
                </h2>
              </div>
              {HeroButton}
            </div>
          </div>
          {CaptionElement}
        </div>
      </div>
    </Section>
  )
}

HeroTagline.StyleCore = styleCore
HeroTagline.presets = presets
