import {LucidSiteContext} from '@/src/state/site/Store'
import React, {useContext} from 'react'
import {mapLocations} from './utils'
import styled from 'styled-components'

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  position: relative;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:hover {
    border-color: #b8daff;
  }

  &::-ms-expand {
    display: none;
  }
`

const DropdownIndicator = styled.div`
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: 12px;
  transform: translateY(-50%);
  border: solid #495057;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: translateY(-50%) rotate(45deg);
`

const Label = styled.label`
  box-sizing: content-box;
  color: rgb(67, 62, 82);
  cursor: auto;
  display: block;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.13px;
  line-height: 17.55px;
  margin-bottom: 8px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  text-rendering: geometricprecision;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
`

export type Location = {
  id: string
  name: string
  address: string
  address2: string
  city: string
  state: string
  postal_code: string
  country: string
  phone: string
  hours: string
  image: string
}

interface LocationPickerProps {
  onChange: (location: Location) => void
  value: any
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  onChange,
  value
}) => {
  const [{site_contents}] = useContext(LucidSiteContext)
  const locations = mapLocations((site_contents?.practice as {[key: string]: any})?.locations ?? [])

  const handleChange = (e: {target: {value: any}}) => {
    const locationId = e.target.value
    onChange(locationId)
  }

  return (
    <SelectContainer>
      <Label>Select a Location</Label>
      <StyledSelect
        className="form-control"
        onChange={handleChange}
        value={value}
      >
        {locations.length > 0 ? (
          locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name} - {location.address}
            </option>
          ))
        ) : (
          <option value="">No locations found</option>
        )}
      </StyledSelect>
      <DropdownIndicator />
    </SelectContainer>
  )
}

export default LocationPicker
