import {parseContentVariables} from '@/src/utils/shared/parseContentVariables'
import type {InlineTextProps} from '@einsteinindustries/react-tinacms-inline'
import React, {useEffect, useState} from 'react'
import {useLucidContext} from '@/src/state/ServerSideStore'
import type {ComponentLoader} from '@/components/shared/types'

interface InlineTextWithVariablesProps extends InlineTextProps {
  value: string
  cms: boolean
}

export function InlineTextWithVariables({cms, value, ...props}: InlineTextWithVariablesProps) {
  const [{site_contents}] = useLucidContext(cms)

  const [editable, setEditability] = useState(false)
  const [inlineTextComponent, setInlineTextComponent] = useState<ComponentLoader<InlineTextProps>>({
    Component: null
  })

  useEffect(() => {
    async function loadTina() {
      const {InlineText} = await import('@einsteinindustries/react-tinacms-inline')
      setInlineTextComponent({Component: InlineText})
    }

    if (cms) {
      loadTina()
    }
  }, [])

  function makeEditable() {
    setEditability(true)
  }

  function makeUneditable() {
    setEditability(false)
  }

  const component = editable && inlineTextComponent.Component ? <inlineTextComponent.Component {...props} /> :  <>{parseContentVariables(value, site_contents)}</>

  return cms ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      {component}
    </span>
  ) : component
}

interface RichtextWithVariablesProps {
  richtext: string
  cms: boolean
  name: string
}

export function RichtextWithVariables({cms, ...props}: RichtextWithVariablesProps) {
  const [{site_contents}] = useLucidContext(cms)

  const [isEditMode, setIsEditMode] = useState(false)
  const [displayableText, setDisplayableText] = useState(parseContentVariables(props.richtext, site_contents))
  const [richTextComponent, setRichTextComponent] = useState<ComponentLoader<{name: string, richtext: string}>>({
    Component: null
  })

  useEffect(() => {
    async function loadTina() {
      const SectionRichtext = (await import('./SectionRichtext')).default
      setRichTextComponent({Component: SectionRichtext})
    }

    if (cms) {
      loadTina()
    }
  }, [])

  useEffect(() => {
    if (!isEditMode) {
      setDisplayableText(parseContentVariables(props.richtext, site_contents))
    }
  }, [props.richtext, isEditMode])

  function makeEditable() {
    setIsEditMode(true)
    setDisplayableText(props.richtext)
  }

  function makeUneditable() {
    setIsEditMode(false)
  }

  return richTextComponent.Component ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      <richTextComponent.Component name={props.name} richtext={displayableText} />
    </span>
  ) : <span dangerouslySetInnerHTML={{__html: parseContentVariables(props.richtext, site_contents)}}/>
}
