import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'

export default function Cliffhanger(props) {
  const {
    text,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section cliffhanger-section stack'}
      name="cliffhanger"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column">
              <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name="text"
                    richtext={text}
                    cms={cms}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

Cliffhanger.StyleCore = styleCore
