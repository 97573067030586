import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Zoom on hover',
      documentationURL: 'https://www.notion.so/einsteinindustries/Zoom-on-hover-aa31db20fd4b4fa9b6dc307b8be55dd5?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/wildcard-IID-101.png',
      description: 'Check out the documentation for this preset to see how to use it.'
    },
    notion_IID: '101',
    allowedSections: [],
    css: `
    .simplyDentalAds {
    margin: auto;
    width: 80%;
    justify-content: center;
    display: flex; flex-flow: row wrap;
}
.simplyDentalAds span { 
    flex:1;
    display:block;
    margin: 0.5rem;
    box-shadow: 0 2px 5px #0002;
    transition: transform .2s;
}
.simplyDentalAds span:hover { 
    transform:scale(2);
    -webkit-box-shadow: 0px 5px 9px 2px rgba(0,0,0,0.45); 
    box-shadow: 0px 5px 9px 2px rgba(0,0,0,0.45);
}
.simplyDentalAds span img { width: 100%; height: auto; }

/* Media Queries */
@media (max-width: 960px) {
    .simplyDentalAds {
        width: 100%;
    }
    .simplyDentalAds span:hover { 
        transform: none; 
        box-shadow: 0 2px 5px #0002;
    }
}
@media (max-width: 600px) {
.simplyDentalAds {
        margin: auto;
        flex-flow: column wrap;
    }
}
@media (prefers-reduced-motion) {
    .simplyDentalAds span:hover { 
        transform:scale(3);
        transform: none; 
        box-shadow: 0 2px 5px #0002;
    }
}
    `
  }
]
