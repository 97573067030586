import {useState} from 'react'

export type EditorDisplayStates = 'visible' | 'hidden' | 'locked'

export const useOverridesWindowState = (cms: boolean, page_section_id?: string) => {
  const [display, setDisplay] = useState<EditorDisplayStates>('hidden')
  const [overridesModalIsOpened, setOverrideModalIsOpened] = useState(false)  

  const onActive = () => {
    if (display !== 'visible' && cms) {
      setDisplay(typeof page_section_id !== 'undefined' ? 'visible' : 'locked')  
    }
  }  

  const onBlur = () => {
    if (display && !overridesModalIsOpened && cms) {
      setDisplay('hidden')  
    }
  }  

  return {display, overridesModalIsOpened, onActive, onBlur, setOverrideModalIsOpened}  
}  
