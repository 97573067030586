import {useContext} from 'react'
import {Location} from './LocationPicker'
import {LucidSiteContext} from '@/src/state/site/Store'

export const useLocationData = (locationId: string) => {
  const [{site_contents}] = useContext(LucidSiteContext)
  const locations = mapLocations((site_contents?.practice as {[key: string]: any})?.locations ?? [])
  const location = getLocationById(locations, locationId)

  const schemaProps = {
    name: location?.name || 'Default Name',
    image: location?.image || 'Default Image URL',
    streetAddress: location?.address || 'Default Address',
    addressLocality: location?.city || 'Default City',
    addressRegion: location?.state || 'Default State',
    postalCode: location?.postal_code || 'Default Postal Code',
    addressCountry: location?.country || 'Default Country',
    telephone: location?.phone || 'Default Phone',
    openingHours: location?.hours || 'Default Opening Hours'
  }

  return {location, schemaProps}
}

export const prepareMapProperties = (data: any, location: Location) => {
  const customMapUrl = data.customMapUrl
  const mapLink = customMapUrl || createGoogleMapsLink(location)

  const hasCustomCoordinates = data.customCoordinates && data.customCoordinates.includes(',')
  const [latitude, longitude] = hasCustomCoordinates ? data.customCoordinates.split(',').map((coord: string) => coord.trim()) : [null, null]
  const mapCenter = hasCustomCoordinates ? {lat: parseFloat(latitude), long: parseFloat(longitude)} : undefined
  const mapAddress = hasCustomCoordinates ? undefined : `${location?.address} ${location?.address2 ?? ''}, ${location?.city}, ${location?.state} ${location?.postal_code ?? ''} ${location?.country ?? ''}`
  return {mapLink, mapCenter, mapAddress}
}

export function getLocationById(locations: Location[], id: string) {
  return locations.find((location: any) => location.id === id)
}

export function mapLocations(locations: Location[]) {
  return Object.keys(locations).map((key: string) => {
    return {
      id: key,
      ...(locations as {[key: string]: any})[key],
    }
  })
}

export function createGoogleMapsLink(location: Location) {
  if (!location) return
  const fullAddress = `${location.address} ${location.address2 ?? ''}, ${location.city}, ${location.state} ${location.postal_code ?? ''} ${location.country ?? ''}`
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`
}
