import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Intro/presets'

export default function Intro(props) {
  const {
    title,
    subtitle,
    richtext,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section intro-section stack with-content'}
      name="intro"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </div>
              <div className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </div>
            </h1>
          </div>
          <div className="row">
            <div className="column">
              <div className="section-richtext paragraph">
                <RichtextWithVariables cms={cms} name="richtext" richtext={richtext}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>

  )
}

Intro.StyleCore = styleCore
Intro.presets = presets
