import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Menu Icon Card: Cards Left Column',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Icon-Card-Cards-Left-Column-8f27570b46c3482ba7d4ae81f2426f00?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/menu_icon_card-IID-134.png'
    },
    notion_IID: '134',
    allowedSections: [],
    css: `
.menu-icon-card-items .column {
    flex-basis: 100%;
    max-width: 100%;
  }

.section-content {
    display: flex;
  }

.section-outro > .row .column {
    max-width: unset;
  }

.menu-card-item-border-0-1 div {
    display: flex;
    align-items: center;
    text-align: left;
    flex-basis: 100%
  }

.menu-icon-card svg {
    max-height: 60px;
    max-width: 60px;
    padding-right: 1rem;
  }

.menus>.menu>a {
    padding-left: 2rem;
    padding-right: 2rem;
  }

.row.menus.menu-icon-card-items {
    flex-basis: 33%;
    padding: 0 1rem;
  }

.section-outro .column {
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 3rem;
  }

.section-outro {
    flex-basis: 66.6%;
    padding: 0 1rem;
  }

.menu-item-title {
    margin-bottom: 0;
  }  
    `
  },
  {
    display: {
      name: 'Menu Icon Card: Cards Right Column',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Icon-Card-Cards-Right-Column-5a208f7f5cfb4e8c81fc8cce6e3097b4?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/menu_icon_card-IID-127.png'
    },
    notion_IID: '127',
    allowedSections: [],
    css: `
    @media (min-width:1024px) {

.menu-icon-card-items .column {
  flex-basis: 100% !important;
  max-width: 100% !important;
}

.section-content {
  display: flex;
padding-left: 1rem;
}

.menu-card-item-border-0-1 div {
  display: flex;
  align-items: center;
  text-align: left;
flex-basis: 100%;
}


.menu-icon-card svg {

max-height: 150px;
max-width: 150px;
padding-right: 1rem;
}

.menu-card-item-border-0-1 {
padding-left: 2rem;
padding-right: 2rem;
}

.row.menus.menu-icon-card-items {
  flex-basis: 50%;
padding: 0 1rem;
}

.section-description .column {
  flex-basis: 100% !important;
  padding-left: 0 !important;
  padding-right: 3rem !important;
}
.section-description {
  flex-basis: 50%;
}
.menu-icon-card-content {
  flex-wrap: wrap;
}
}
    `
  },
  {
    display: {
      name: 'Menu Icon Flip Cards: Icon on back side',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-Icon-Flip-Cards-Icon-on-back-side-1b722113c89c40c5a245f7dd452d522a?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/menu_icon_card-IID-121.png'
    },
    notion_IID: '121',
    allowedSections: [],
    css: `
.menu-icon-card-item {
  transform-style: preserve-3d;
}



/* 1st level container styles */
.menu-card-item-border-0-1 {
  perspective: 1100px;
}

.menu-icon-card-items .menu-card-item-border-0-1 {
  overflow: visible;
  background-color: transparent;
}

.menu-icon-card-items .column:nth-of-type(odd) .menu-card-item-border-0-1 {
  z-index: 1;
}

/* BACK Description: makes sure it's vertically centered */
.menu-icon-card-content {
  transform-style: preserve-3d;
}

.menu-icon-card {
  padding: 0rem;
}

/* FRONT Subtitle and Title and image : Position absolutely and hide when card is flipped */
.menu-card-item-title {
  transform: rotateY(0deg);
  -webkit-transform:rotateY(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  width: 100%;
  left: 0;
  margin-bottom: 0;
}

.menu-icon-card-item-description, .menu-icon-card-item-subtitle, .menu-icon-card-content h3 {
  transform: rotateY(0deg);
  -webkit-transform:rotateY(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* make sure icon image or SVG are same color as front card to hide it */

.menu-icon-card-item-image, .menu-icon-card svg {
  transform:scaleX(-1);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
}

/* FRONT add different color to front of card  */
.menu-card-item-border-0-1 > div:last-child:before {
  background-color: #fff;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  -webkit-transform:rotateY(0deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  z-index: -1;
}

/*  ----- Animations------ 

.menu-icon-card-items .menu-card-item-border-0-1 .menu-item-title {
  opacity: 1;
  transition: all .5s cubic-bezier(1, -0.29, 0, -0.28);
}

.menu-icon-card-items .menu-card-item-border-0-1:hover .menu-item-title,
.menu-icon-card-items .menu-card-item-border-0-1:hover .menu-item-subtitle {
  opacity: 0;
  transition: all .5s cubic-bezier(0, 0.51, 1, 1.92);
}

*/

/* FRONT 2nd level Container: animate return flip  and add box shadow to parent container */
.menu-icon-card-items .menu-card-item-border-0-1 > div:last-child {
  transform-style: preserve-3d;
  box-shadow: 0 1.5px 2.7px rgba(0, 0, 0, 0.09), 0 3.8px 6.8px rgba(0, 0, 0, 0.062), 0 7.8px 13.8px rgba(0, 0, 0, 0.05), 0 16.1px 28.5px rgba(0, 0, 0, 0.04), 0 44px 78px rgba(0, 0, 0, 0.028);
  transition: all .7s ease;
  height: 100%;
  width: 100%;
  background-color: #3C4E67;
  min-height: 400px;
  display: flex;
  align-items: center;
}

/* on hover of border-0-1 flip entire 2nd level container */
.menu-icon-card-items .menu-card-item-border-0-1:hover>div:last-child {
  transform: rotateY(180deg);
  transition: all .7s ease;
  box-shadow: 0 1.5px 2.7px rgba(0, 0, 0, 0.09),
    0 3.8px 6.8px rgba(0, 0, 0, 0.062),
    0 7.8px 13.8px rgba(0, 0, 0, 0.05),
    0 16.1px 28.5px rgba(0, 0, 0, 0.04),
    0 44px 78px rgba(0, 0, 0, 0.028);
}

    `
  },
  {
    display: {
      name: 'Menu icon cards with flip',
      documentationURL: 'https://www.notion.so/einsteinindustries/Menu-icon-cards-with-flip-501564d1cde44ba899ee59d05e03f8aa?pvs=4'
    },
    notion_IID: '96',
    allowedSections: [],
    css: `
.menu-icon-card-item {
  transform-style: preserve-3d;
}

.section-container {
}

/* 1st level container styles */
.menu-card-item-border-0-1 {
  perspective: 1100px;
}

.menu-icon-card-items .menu-card-item-border-0-1 {
  overflow: visible;
  background-color: transparent;
}

.menu-icon-card-items .column:nth-of-type(odd) .menu-card-item-border-0-1 {
  z-index: 1;
}

/* BACK Description: Moved to back */
.menu-icon-card-item-description {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(180deg) translateY(-50%);
  color: #fff !important;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  padding: 2rem;
  font-size: 20px;
}

.menu-icon-card-item-description em {
  color: #fff;
  font-style: normal;
}

/* BACK Description: makes sure it's vertically centered */
.menu-icon-card-content {
  min-height: 400px;
  transform-style: preserve-3d;
}

.menu-card-item-subtitle {
  padding-top: 0 !important;
  font-size: 18px;
  font-weight: 400;
}

/* FRONT Subtitle and Title and image : Position absolutely and hide when card is flipped */
.menu-card-item-title, .menu-card-item-subtitle {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  width: 100%;
  left: 0;
  padding: 14rem 2rem 0 2rem;
  margin-bottom: 0;
}

.menu-icon-card-content .menu-icon-card-item-description {
  color: #E5BF73 !important;
  line-height: 1.3;
  font-size: 18px;
}

.menu-icon-card-item-description strong {
  color: #fff;
  font-size: 24px;
  display: block;
}

.menu-icon-card-item-image {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
  padding-top: 2rem !important;
}

/* FRONT add different color to front of card  */
.menu-card-item-border-0-1 div:last-child:before {
  background-color: #fff;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  z-index: -1;
}

/*  ----- Animations------ */
/* BACK Description: on hover flip description */
.menu-icon-card-items .menu-card-item-border-0-1:hover .menu-icon-card-item-description {
  align-self: center;
  transform: rotateY(-180deg) translateY(-50%);
  z-index: 1;
  position: absolute;
  top: 50%;
}
.menu-icon-card-items .menu-card-item-border-0-1 .menu-item-title {
  opacity: 1;
  transition: all .5s cubic-bezier(1, -0.29, 0, -0.28);
}
.menu-icon-card-items .menu-card-item-border-0-1:hover .menu-item-title {
  opacity: 0;
  transition: all .5s cubic-bezier(0, 0.51, 1, 1.92);
}

/* FRONT 2nd level Container: animate return flip  and add box shadow to parent container */
.menu-icon-card-items .menu-card-item-border-0-1>div:last-child {
  transform-style: preserve-3d;
  box-shadow: 0 1.5px 2.7px rgba(0, 0, 0, 0.09),
    0 3.8px 6.8px rgba(0, 0, 0, 0.062),
    0 7.8px 13.8px rgba(0, 0, 0, 0.05),
    0 16.1px 28.5px rgba(0, 0, 0, 0.04),
    0 44px 78px rgba(0, 0, 0, 0.028);
  transition: all .7s ease;
  height: 100%;
  width: 100%;
  background-color: #3C4E67;
  background-image: url(https://d1l9wtg77iuzz5.cloudfront.net/assets/2994/279587/original.jpg?1571695474);
  min-height: 400px;
}

/* on hover of border-0-1 flip entire 2nd level container */
.menu-icon-card-items .menu-card-item-border-0-1:hover>div:last-child {
  transform: rotateY(180deg);
  transition: all .7s ease;
  box-shadow: 0 1.5px 2.7px rgba(0, 0, 0, 0.09),
    0 3.8px 6.8px rgba(0, 0, 0, 0.062),
    0 7.8px 13.8px rgba(0, 0, 0, 0.05),
    0 16.1px 28.5px rgba(0, 0, 0, 0.04),
    0 44px 78px rgba(0, 0, 0, 0.028);
}
    `
  }
]
