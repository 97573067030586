import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'

export default function Quote(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    outro,
    button,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    className,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + '  quote-section stack'}
      name="quote"
      section_background={section_background}
    >
        <div className="section-container">
            <div className="section-content">
                <div className="row quote flexbox-alignment middle">
                    <div className="column" style={{
                        maxWidth: '100%',
                        flex: '0 0 100%',
                    }}>
                        <div className="section-heading" style={{
                            textAlign: 'center'
                        }}>
                            <h1>
                                <span className="title heading-1">
                                    <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
                                </span>
                                <span className="subtitle heading-2"><
                                    RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
                                </span>
                            </h1>
                        </div>
                        <div className="section-description">
                            <div className="row">
                                <div className="column">
                                    <div className="section-richtext paragraph" style={{
                                        textAlign: 'left'
                                    }}>
                                        <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <blockquote style={{
                            textAlign: 'center'
                        }}>
                            <span className="quote"><RichtextWithVariables richtext={quote} cms={cms} name={'quote'}/></span>
                            <cite className="citation"><InlineTextWithVariables cms={cms} value={cite} name="cite"/></cite>
                        </blockquote>
                        <div className="section-outro">
                            <div className="row">
                                <div className="column section-richtext paragraph" style={{textAlign: 'left'}}>
                                    <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Section>
  )
}

Quote.StyleCore = styleCore

