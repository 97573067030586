import {createContext, PropsWithChildren, useReducer} from 'react'
import reducer, {ActionTypes} from '@/src/state/site/reducer'

export type YextReview = {
  id: string,
  authorName: string,
  publisherDate: string,
  rating: number,
  publisherId: string,
  content: string,
  url: string
}

export type ClientID = string
export type ReviewGroupMap = Map<ClientID, YextReview[]>
export type ReviewsState = {
  reviews: ReviewGroupMap
}

const initialReviewsState: ReviewsState = {
  reviews: new Map()
}

export type ReviewsDispatcher = (arg: {type: typeof ActionTypes[number], payload: Partial<ReviewsState>}) => void

type ReviewsContextInterface = readonly [ReviewsState, ReviewsDispatcher]

export const ReviewsContext = createContext<ReviewsContextInterface>([initialReviewsState, () => {}])

export const ReviewsSiteProvider = ({children}: PropsWithChildren<any>) => {
  const [reviewState, reviewDispatch] = useReducer(reducer, initialReviewsState)

  return (
      <ReviewsContext.Provider value={[reviewState as ReviewsState, reviewDispatch]}>
        {children}
      </ReviewsContext.Provider>
  )
}

