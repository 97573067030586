import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import Section from '../shared/Section'
import SectionImage from '../shared/SectionImage'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {styleCore} from './stylecore'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

const itemTemplates = {
  herocomparisiontableitem: {
    label: 'Item',
    defaultItem: {
      factor: 'factor',
      value_1: 'value 1',
      value_2: 'value 2',
    },
    itemProps: (item) => {
      return {factor: item.factor, value_1: item.value_1, value_2: item.value_2}
    },
    fields: [
        {
            name: 'factor',
            label: 'Factor',
            component: 'html',
        },
        {
            name: 'value_1',
            label: 'Value 1',
            component: 'html',
        },
        {
            name: 'value_2',
            label: 'Value 2',
            component: 'html',
        }
    ],
  }
}

function ItemWrapper(props) {
  if (!props.cms) return props.children
  return (
      <LucidBlocksControls
          cms={props.cms}
          index={props.index}
          focusRing={{offset: 16, borderRadius: 4}}
          insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
  )
}

const COMPARISON_TABLE_ITEM_BLOCKS = {
  herocomparisiontableitem: {
    Component({_name, index, data, cms}) {
      return (
          <ItemWrapper index={index} cms={cms}>
            <div className="row">
              <div className="column"><span className="label"><strong><RichtextWithVariables richtext={data.factor} cms={cms} name={'factor'}/></strong></span></div>
              <div className="column"><span className="paragraph"><RichtextWithVariables richtext={data.value_1} cms={cms} name={'value_1'}/></span></div>
              <div className="column"><span className="paragraph"><RichtextWithVariables richtext={data.value_2} cms={cms} name={'value_2'}/></span></div>
            </div>
          </ItemWrapper>
      )
    },
    template: itemTemplates.herocomparisiontableitem,
  },
}


export default function HeroComparison(props) {
  const {
    title,
    subtitle,
    label_1,
    label_2,
    image_1,
    image_2,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
      className
  } = props

    let images = null
    let imageDimmer = null
    let imageAdditionalClass = ''
    if (image_1 || image_2) {
        images = (
            <>
                    <SectionImage
                        name={'image_1'}
                        src={image_1?.src}
                        mobile={image_1?.mobile}
                        alt={image_1?.alt}
                        width={image_1?.width}
                        height={image_1?.height}
                        priority={image_1?.priority}
                        sizes={image_1?.sizes}
                        cms={cms}
                        className={'hero-comparison-image-1'}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100vw'
                        }}
                    />
                    <SectionImage
                        name={'image_2'}
                        src={image_2?.src}
                        mobile={image_2?.mobile}
                        alt={image_2?.alt}
                        width={image_2?.width}
                        height={image_2?.height}
                        priority={image_2?.priority}
                        sizes={image_2?.sizes}
                        cms={cms}
                        className={'hero-comparison-image-2'}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100vw'
                        }}
                    />
            </>
        )
        imageDimmer = (
            <div className="hero-comparison-dimmer"></div>
        )
        imageAdditionalClass = ' with-image'
    }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      name="herocomparison"
      className={className + ' section hero-comparison-section stack'}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h1>
          </div>
          <div className="row hero-comparison-content">
            <div className="column">
              <div className={`hero-comparison-chart section-richtext${imageAdditionalClass}`}>
                <div className="row">
                  <div className="column"></div>
                  <div className="column"><span className="label"><strong><RichtextWithVariables richtext={label_1} cms={cms} name={'label_1'}/></strong></span></div>
                  <div className="column"><span className="label"><strong><RichtextWithVariables richtext={label_2} cms={cms} name={'label_2'}/></strong></span></div>
                </div>
                <LucidInlineBlocks
                    itemProps={props}
                    name="descriptions"
                    blocks={COMPARISON_TABLE_ITEM_BLOCKS}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        {images}
        {imageDimmer}
    </Section>
  )
}

HeroComparison.StyleCore = styleCore

