import React from 'react'
import {formatContent, formatLinkText, publisherLogo} from './utils'
import {Avatar, Button, Card, Grid, Image, Link, Row, Text} from '@nextui-org/react'

function ReviewUrl(props) {
  const {content, url} = props
  if (content.length > 500) {
    return (
      <Link
        href={url}
        isExternal
        target='_new'
      >...more</Link>
    )
  }
  return null
}

function Review(props) {
  const {
    id,
    authorName,
    publisherDate,
    rating,
    publisherId,
    content,
    url,
    handleReviewClick,
  } = props

  return (
    <Card
      variant="bordered"
    >
      <Card.Header
        style={{
          padding: 20
        }}
      >
        <Avatar text={authorName.split(' ').map(e => e[0]).join('').substring(0, 2)} size="md"/>
        <Grid.Container style={{paddingLeft: 10}}>
          <Grid xs={12}>
            <Text h4 style={{lineHeight: 0.5}}>
              {authorName}
            </Text>
          </Grid>
          <Grid xs={12}>
            <Text>{new Date(publisherDate).getFullYear()} | {rating} Star{rating === 1 && 's'}</Text>
          </Grid>
        </Grid.Container>
      </Card.Header>
      <Card.Body style={{
        padding: 20
      }}>
        <p>{formatContent(content)}
          <ReviewUrl
            url={url}
            content={content}
          ></ReviewUrl></p>
      </Card.Body>
      <Card.Divider/>
      <Card.Footer>
        <Row justify="flex-end">
          <Button size="md" flat color="primary" href={url}>
            <Image
              src={publisherLogo(publisherId)}
              alt={formatLinkText(publisherId)}
              height="15px"
              width="15px"
            /> View on {formatLinkText(publisherId)}
          </Button>
          <Button size="md" color="primary" onClick={() => handleReviewClick(id)}>
            Use this review
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default Review
