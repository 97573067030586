import type {FormState} from '@/components/shared/externalTypes'
import type {FormManagerFieldProps} from '@/src/utils/shared/FormManagerFormUI'

export const styleCore = {
  config: {
    grid: {
      content: {
        itemsPerRow: {
          default: 3,
          content: 'number',
          min: 1,
          max: 10,
          step: 1,
          description: 'The number of items per row',
          listener: (formState: FormState, fieldState: string, field: FormManagerFieldProps) => {
            if (Number(fieldState) > 4) {
              return {
                ...field,
                message: {
                  state: 'warning',
                  message: 'Any layout size above 4 is not recommended'
                }
              }
            }
            return field
          },
          postProcessing: (formState: FormState, fieldState: string) => {
            return `${Math.round(((1 / Number(fieldState))) * 10000) / 100}%`
          }
        },
        padding: {
          default: 10,
          content: 'number',
          min: 0,
          unit: 'px',
          description: 'The padding around items',
        },
        forceImportant: {
          default: false,
          content: 'toggle',
          description: 'Force the CSS to be !important',
        }
      }
    },
    card: {
      content: {
        padding: {
          default: 10,
          content: 'number',
          unit: 'px'
        }
      }
    }
  },
  target: {
    componentName: 'menuiconcard',
  },
  css: `
    {target} .menu {
       flex: 0 0 {grid.itemsPerRow} {grid.forceImportant ? !important :  };
       max-width: {grid.itemsPerRow} {grid.forceImportant ? !important :  };
       padding: {grid.padding} {grid.forceImportant ? !important :  };
    }
    
    {target} .menu-icon-card {
      padding: {card.padding};
    }
  `
}
