import React, {ChangeEvent, FocusEvent, useEffect, useRef, useState} from 'react'
import {FieldValue, useForm, UseFormRegister} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import LucidInlineBlocks from './LucidInlineBlocks'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'
import type {BlockComponentProps} from '@einsteinindustries/react-tinacms-inline'
import {useLucidContext} from '@/src/state/ServerSideStore'
import Script from 'next/script'
import {BlockContents} from '@/components/shared/types'
import type {Form} from '@einsteinindustries/tinacms-forms'
import type {BlocksFieldDefinititon} from '@einsteinindustries/tinacms-fields/build/plugins/BlocksFieldPlugin'
import type {FormApi} from 'final-form'
import CustomTinaFieldComponentStyles from '@/src/utils/shared/CustomTinaFieldComponentStyles'

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][]

type ValidationOptions = {
  required: boolean | string
  is_email: boolean | string
  is_phone_number: boolean | string
  is_valid_number: boolean | string
  is_valid_alpha: boolean | string
}

type InputFieldData = {
  field_name?: string,
  field_type: typeof FORM_INPUT_TYPES[number]['value'],
  group_items?: string,
  placeholder?: string,
  label?: string,
  validate?: ValidationOptions,
  default_value?: string,
  depends_on?: string,
}

type BlockComponentInputType = BlockComponentProps & {
  cms?: boolean,
  data: InputFieldData
  state?: FieldStates
}

type FormComponentDataProps = {
  form_fields: InputFieldData[]
  verification: string
  formname?: string
  redirect: string
  opts_subject?: string
  submit_text?: string
  opts_sendto?: string
  opts_sendccto?: string
  opts_sendbccto?: string
  opts_createpdf?: string | boolean
  resetform?: string | boolean
}

type FormComponentProps = BlockComponentInputType & {
  data: FormComponentDataProps
}

interface BlockFieldProps {
  input: any;
  meta: any;
  field: BlocksFieldDefinititon;
  form: FormApi;
  tinaForm: Form;
}

export const ENCRYPTED_FIELDS = {
  opts_sendto: {globalKey: 'Default Send To Recipients'},
  opts_sendccto: {globalKey: 'Default Send CC To Recipients'},
  opts_sendbccto: {globalKey: 'Default Send BCC To Recipients'},
  opts_subject: {globalKey: undefined},
  redirect: {globalKey: undefined},
  verification: {globalKey: 'reCAPTCHA secret'},
} as const

export const UNSET_DEFAULT_VALUE = 'not-overwritten'

const FORM_DEFAULT_FIELDS = [
  {
    label: 'Form Name',
    name: 'formname',
    component: 'text',
    required: true,
  },
  {
    label: 'Form Subject',
    name: 'opts_subject',
    component: 'text'
  },
  {
    label: 'Send To',
    name: 'opts_sendto',
    component: DefaultsFromSiteGlobals,
    required: true
  },
  {
    label: 'Send CC To',
    name: 'opts_sendccto',
    component: DefaultsFromSiteGlobals,
  },
  {
    label: 'Send BCC To',
    name: 'opts_sendbccto',
    component: DefaultsFromSiteGlobals
  },
  {
    label: 'Redirect Back To',
    name: 'redirect',
    component: 'text',
    required: true,
  },
  {
    label: 'Submit Button Text',
    name: 'submit_text',
    component: 'text',
    required: true
  },
  {
    label: 'Add Reset Form Button',
    name: 'resetform',
    component: 'toggle',
  },
  {
    label: 'Attach Form As PDF',
    name: 'opts_createpdf',
    component: 'toggle',
  },
  {
    label: 'reCAPTCHA secret',
    name: 'verification',
    component: HiddenDefaultsFromSiteGlobals
  },
]

const GROUP_FIELDS = ['selectgroup', 'select', 'radio', 'checkbox']

const FORM_INPUT_TYPES = [
  {label: 'Text', value: 'text'},
  {label: 'Text Area', value: 'textarea'},
  {label: 'Select', value: 'select'},
  {label: 'Numeric', value: 'number'},
  {label: 'Radio Group', value: 'radio'},
  {label: 'Checkbox Group', value: 'checkbox'},
  {label: 'Select Group', value: 'selectgroup'},
  {label: 'Date', value: 'date'},
  {label: 'Email', value: 'email'},
  {label: 'Hidden', value: 'hidden'},
  {label: 'Hidden Encrypted', value: 'hiddenencrypted'},
] as const

type SectionContactFormProps = {
  cms: boolean,
  form_items: string[]
}


type DependentFieldsState = {
  dependentField: string
  anchorField: string
  anchorIsTicked: boolean
}[]

type FieldStates = {
  dependentFields: DependentFieldsState
  setDependentFields: React.Dispatch<React.SetStateAction<DependentFieldsState>>
}

const CONSULTATION_FORM = {
  template: {
    label: 'Consultation Form',
    fields: FORM_DEFAULT_FIELDS,
    defaultItem: {
      ...getFormDefaultConfigFields('consultation'),
      form_fields: [
        {
          _template: 'form_fields',
          field_name: 'Name',
          field_type: 'text',
          label: 'Name',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Email',
          field_type: 'text',
          label: 'Email',
          validate: {required: true, is_email: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Phone Number',
          field_type: 'text',
          label: 'Phone Number',
          validate: {required: true, is_phone_number: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Location',
          field_type: 'select',
          label: 'Which location would you prefer?',
          group_items: 'Downtown (San Diego, CA)\nMain Office (New York, NY)\nBerks (San Diego, CA)',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Message',
          field_type: 'textarea',
          label: 'How can we help?',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Availability',
          field_type: 'radio',
          label: 'What\'s your availability?',
          group_items: 'I\'m Flexible\nSet Availability',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'First Preference',
          field_type: 'selectgroup',
          label: 'First Preference',
          group_items: 'Monday\nTuesday\nWednesday\nThursday\nFriday\nSaturday\nSunday\n\nMornings\nAfternoons\nEvenings',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Second Preference',
          field_type: 'selectgroup',
          label: 'Second Preference (Optional)',
          group_items: 'Monday\nTuesday\nWednesday\nThursday\nFriday\nSaturday\nSunday\n\nMornings\nAfternoons\nEvenings',
        },
      ]
    }
  },
  Component: FormComponent
}

const CONTACT_FORM = {
  template: {
    label: 'Contact Form',
    fields: FORM_DEFAULT_FIELDS,
    defaultItem: {
      ...getFormDefaultConfigFields('contact'),
      form_fields: [
        {
          _template: 'form_fields',
          field_name: 'Name',
          field_type: 'text',
          label: 'Name',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Email',
          field_type: 'text',
          label: 'Email',
          validate: {required: true, is_email: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Phone Number',
          field_type: 'text',
          label: 'Phone Number',
          validate: {required: true, is_phone_number: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Message',
          field_type: 'textarea',
          label: 'Message',
          validate: {required: true}
        },
      ]
    }
  },
  Component: FormComponent
}

const CUSTOM_FORM = {
  template: {
    label: 'Custom Form',
    fields: FORM_DEFAULT_FIELDS,
    defaultItem: {
      ...getFormDefaultConfigFields('custom'),
      form_fields: [
        {
          _template: 'form_fields',
          field_name: 'Sample Name Field',
          field_type: 'text',
          label: 'Label (I am a label)',
          validate: {required: true}
        },
        {
          _template: 'form_fields',
          field_name: 'Sample Email field',
          field_type: 'text',
          placeholder: 'Email (I am A placeholder)',
          validate: {required: true, is_email: true}
        },
      ]
    }
  },
  Component: FormComponent
}

function SelectOptions({selectOptionLines}: { selectOptionLines: string[] }) {
  return (
    <>
      {selectOptionLines.map((line, i) => {
        const [v, text] = line.split(':')
        return <option key={i} value={v}>{text ?? v}</option>
      })}
    </>
  )
}

function InputGroup(
  {data, standardizedName, state, registerValidator}: {
    data: InputFieldData,
    standardizedName: string,
    registerValidator: UseFormRegister<FieldValue<any>>,
    state?: FieldStates
  }) {
  if (!data.group_items) return <></>
  const lines = data.group_items.split('\n')
  return (
    <div className='group-inputs'>
      {lines.map((line, i) => {
        const [v, text] = line.split(':')
        const id = standardizeName(v)
        const {onChange, ...validatorRefs} = registerValidator(standardizedName)

        function onInputChange(event: ChangeEvent<any>) {
          onInputFieldChange(data, standardizedName, event.target, state)
          return onChange(event)
        }

        return (
          <div key={i}>
            <input
              {...validatorRefs}
              onChange={onInputChange}
              type={data.field_type}
              name={standardizedName}
              id={id}
              value={v}
            />
            <label htmlFor={id}>{text ?? v}</label>
          </div>
        )
      })}
    </div>
  )
}

function SelectGroup(
  {data, standardizedName, state, registerValidator}: {
    data: InputFieldData,
    standardizedName: string,
    registerValidator: UseFormRegister<FieldValue<any>>,
    state?: FieldStates
  }) {
  if (!data.group_items) return <></>

  const groups = data.group_items.split('\n').reduce((acc, current) => {
    const index = acc.length - 1
    if (current === '') {
      acc.push([])
    } else {
      acc[index].push(current)
    }
    return acc
  }, [[]] as string[][])

  return (
    <div className='group-inputs'>
      {groups.map((group, i) => {
        const selectName = `${standardizedName}_${i}`
        const {onChange, ...validatorRefs} = registerValidator(selectName)

        function onInputChange(event: ChangeEvent<any>) {
          onInputFieldChange(data, standardizedName, event.target, state)
          return onChange(event)
        }

        return (
          <select {...validatorRefs} key={i} onChange={onInputChange} name={selectName}>
            <SelectOptions selectOptionLines={group}/>
          </select>
        )
      })}
    </div>
  )
}

function FormComponent({index, cms = false, data}: FormComponentProps) {

  const [dependentFields, setDependentFields] = useState<DependentFieldsState>([])
  const state = {dependentFields, setDependentFields}

  const {
    register, handleSubmit, formState: {errors}
  } = useForm({resolver: yupResolver(yup.object(createValidationSchema(data, dependentFields)).required())})

  const errorMessages = Object.entries(errors).reduce((acc, [key, details]) => {
    acc[key] = details?.message as string ?? ''
    return acc
  }, {} as Record<string, string>)

  const [{site_contents: {technical = {}}, site}] = useLucidContext(cms)
  const {forms = {}} = technical as { forms?: BlockContents }

  const extraProps: { 'data-sitekey'?: string, className: string } = {className: 'section-button'}
  if (forms?.['reCAPTCHA Site Key']) {
    extraProps['data-sitekey'] = forms?.['reCAPTCHA Site Key'] as string
    extraProps.className += ' g-recaptcha'
  }
  const action = process.env.NEXT_PUBLIC_DOMAIN?.includes('-dev') ?
    'https://formhouse.einstein-development.com/' : 'https://formhouse.einstein-prod.com/'

  const [clearFormButton, submitButtonWidth] = data.resetform ? [
    <button key={0} type="reset" className="section-button section-button-secondary">Clear Form</button>,
    'auto'
  ] : [null, '100%']

  const onSubmit = (data: Record<string, any>, event?: React.BaseSyntheticEvent) => {
    event?.target.submit()
  }

  const formClassName = data._template === 'consultation' ? 'consultation-form' : 'contact-form'

  return (
    <form action={action} method='post' onSubmit={handleSubmit(onSubmit)} className={`${formClassName} section-form`}>
      <LucidBlocksControls
        cms={cms}
        index={index}
        focusRing={{offset: {x: 20, y: 20}}}
        className={'section-item-wrapper'}
      >
        <LucidInlineBlocks
          itemProps={{...data, cms: cms, state, formState: {register, errors: errorMessages}}}
          name='form_fields'
          blocks={{form_fields: FIELDS_BLOCK}}
        />
        <div className="form-group form-buttons">
          {clearFormButton}
          <button
            data-callback='onSubmit'
            data-action='submit'
            style={{width: submitButtonWidth}}
            type="submit"
            {...extraProps}
          >{data.submit_text}</button>
        </div>
        <DefaultFormhouseFields data={data} cms={cms}/>
      </LucidBlocksControls>
    </form>
  )
}

function InputField(
  {data, standardizedName, state, registerValidator, cms}: {
    data: InputFieldData,
    standardizedName: string,
    state?: FieldStates,
    registerValidator: UseFormRegister<FieldValue<any>>,
    cms: boolean
  }) {

  const placeholder = data.placeholder && data.placeholder !== '' ? data.placeholder : undefined
  const defaultValue = data.default_value && data.default_value !== '' ? data.default_value : undefined

  switch (data.field_type) {
    case 'textarea':
      return (
        <textarea
          {...getInputHandlers(data, standardizedName, registerValidator, state)}
          id={standardizedName}
          name={standardizedName}
          placeholder={placeholder}
          defaultValue={defaultValue}
          rows={5}
        />
      )
    case 'select':
      return (
        <select
          {...getInputHandlers(data, standardizedName, registerValidator, state)}
          id={standardizedName}
          name={standardizedName}
        >
          <SelectOptions selectOptionLines={data.group_items?.split('\n') ?? []}/>
        </select>
      )
    case 'radio':
    case 'checkbox':
      return (
        <InputGroup
          state={state}
          registerValidator={registerValidator}
          data={data}
          standardizedName={standardizedName}
        />
      )
    case 'selectgroup':
      return (
        <SelectGroup
          state={state}
          registerValidator={registerValidator}
          data={data}
          standardizedName={standardizedName}
        />
      )
    default:
      const isHiddenEncrypted = data.field_type === 'hiddenencrypted'
      const style: Record<string, string> = {}

      let fieldType = isHiddenEncrypted ? 'hidden' : data.field_type
      if (cms && fieldType === 'hidden') {
        fieldType = 'text'
        style['color'] = isHiddenEncrypted ? 'transparent' : 'initial'
      }
      return (
        <>
          <input
            {...getInputHandlers(data, standardizedName, registerValidator, state)}
            style={style}
            id={standardizedName}
            type={fieldType}
            disabled={cms && isHiddenEncrypted}
            name={standardizedName}
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
          <HiddenEncryptedMask display={cms && isHiddenEncrypted}/>
        </>
      )
  }
}


function standardizeName(name?: string) {
  return name?.replaceAll(' ', '_').toLowerCase() ?? ''
}

const FIELDS_BLOCK = {
  template: {
    label: 'Form Field',
    fields: [
      {
        label: 'Field Name',
        name: 'field_name',
        component: 'text',
        required: true
      },
      {
        label: 'Field Type',
        name: 'field_type',
        component: 'select',
        options: FORM_INPUT_TYPES
      },
      {
        label: 'Field Items',
        description: 'One option/item per line. Format lines like the following. `my_value:Display Name`, `:No Selection` for empty values or simply `Display Name` to use in both value and text. For Select Groups, separate groups by an empty line',
        name: 'group_items',
        component: (form: BlockFieldProps) => {
          const parts = form.input.name.split('.')
          const fieldTypeField = parts.slice(0, parts.length - 1).join('.') + '.field_type'
          if (!GROUP_FIELDS.includes(form.form.getFieldState(fieldTypeField)?.value)) return null
          return (
            <CustomTinaFieldComponentStyles>
              <label htmlFor={form.input.name}>
                {form.field.label}
                <span>{form.field.description}</span>
              </label>
              <textarea id={form.input.name} name={form.field.name} onChange={form.input.onChange}
                        defaultValue={form.input.value}/>
            </CustomTinaFieldComponentStyles>
          )
        }
      },
      {
        label: 'Label (Optional)',
        name: 'label',
        component: 'text',
      },
      {
        label: 'Placeholder (Optional)',
        name: 'placeholder',
        component: 'text',
      },
      {
        label: 'Validation (Optional)',
        name: 'validate',
        component: 'group',
        fields: [
          {label: 'Required', name: 'required', component: 'toggle'},
          {label: 'Valid Email', name: 'is_email', component: 'toggle'},
          {label: 'Valid Phone', name: 'is_phone_number', component: 'toggle'},
          {label: 'Valid Number', name: 'is_valid_number', component: 'toggle'},
          {label: 'Valid Alphanumeric', name: 'is_valid_alpha', component: 'toggle'},
        ]
      },
      {
        label: 'Default Value (Optional)',
        name: 'default_value',
        component: function DefaultValue({form, field, input}: BlockFieldProps) {

          const [value, setValue] = useState(input.value)
          const [iv, v] = value.split(':')
          const isEncrypted = iv.length === 32 && typeof v === 'string'

          async function onBlur(e: FocusEvent<HTMLInputElement>) {
            const parts = input.name.split('.')
            const fieldTypeAccessor = parts.slice(0, parts.length - 1).concat('field_type').join('.')
            const fieldType = form.getFieldState(fieldTypeAccessor)?.value
            if (fieldType === 'hiddenencrypted' && !isEncrypted) {
              const encryptedValue = await fetch(
                '/api/formhouse', {method: 'POST', body: JSON.stringify({action: 'encrypt', value: e.target.value})}
              )
              const {value} = (await encryptedValue.json()) as { value: string }
              setValue(value)
              input.onChange({...e, target: {...e.target, value}})
            }
          }

          function onChange(e: ChangeEvent<HTMLInputElement>) {
            setValue(e.target.value)
            input.onChange(e)
          }

          return (
            <CustomTinaFieldComponentStyles>
              <label htmlFor={input.name}>
                {field.label}
                <span>{field.description}</span>
              </label>
              <input
                onChange={onChange}
                onBlur={onBlur}
                type='text'
                name={field.name}
                value={value}
              />
            </CustomTinaFieldComponentStyles>
          )
        }
      },
      {
        label: 'Field Depends On',
        description: '',
        name: 'depends_on',
        component: (form: BlockFieldProps) => {
          const parts = form.input.name.split('.')
          const allFields = parts.slice(0, parts.length - 2).join('.')
          const options: JSX.Element[] = [<option key={0} value='none'>None</option>]
          let key = 0
          let selectedOption: string | undefined = undefined
          for (const field of form.form.getFieldState(allFields)?.value as InputFieldData[]) {
            key += 1
            const val = standardizeName(field.field_name)
            if (form.input.value === field.field_name) {
              selectedOption = form.input.value
            }
            options.push(
              <option key={key} value={val}>
                {field.field_name}
              </option>
            )
            if (GROUP_FIELDS.includes(field.field_type) && field.group_items) {
              for (const line of field.group_items?.split('\n')) {
                if (line === '') continue
                key += 1
                const [v, text] = line.split(':')
                const value = `${val}.${v}`
                const displayName = `${field.field_name}<${text ?? v}>`
                if (form.input.value === value) {
                  selectedOption = form.input.value
                }
                options.push(
                  <option key={key} value={value}>
                    {displayName}
                  </option>
                )
              }
            }
          }
          return (
            <CustomTinaFieldComponentStyles>
              <label htmlFor={form.input.name}>
                {form.field.label}
                <span>{form.field.description}</span>
              </label>
              <select id={form.input.name} value={selectedOption} name={form.field.name}
                      onChange={form.input.onChange}>{options}</select>
            </CustomTinaFieldComponentStyles>
          )
        }
      }
    ]
  },
  Component(
    {index, cms = false, data, state, formState}: BlockComponentInputType & {
      formState?: {
        register: UseFormRegister<FieldValue<any>>,
        errors: { [name: string]: string }
      }
    }) {

    const fieldName = standardizeName(data.field_name)
    useEffect(() => {
      registerDependentField(fieldName, data, state)
    }, [])

    if (!cms) {
      /**
       * We want to see all the fields while in edit mode, so that we can make changes to it.
       */
      if (!showDependentField(fieldName, data, state)) return null
    }

    return (
      <LucidBlocksControls cms={cms} index={index} focusRing={true} className={'section-item-wrapper'}>
        <div className='field-group'>
          {data.label && data.label !== '' &&
            <label className={`${data.field_type}-label`} htmlFor={fieldName}>{data.label}</label>}
          <InputField
            cms={cms}
            data={data}
            state={state}
            standardizedName={fieldName}
            registerValidator={formState?.register as UseFormRegister<FieldValue<any>>}
          />
          {formState?.errors?.[fieldName] &&
            <div className='field-error'>
              <p>{formState.errors[fieldName]}</p>
            </div>
          }
        </div>
      </LucidBlocksControls>
    )
  }
}

function DefaultFormhouseFields({data, cms}: { data: FormComponentDataProps, cms: boolean }) {

  const [{site}] = useLucidContext(cms)
  const formname = data.formname + `_${site?.client_account ?? 'invalid'}_${Number(new Date())}`
  return (
    <>
      {/*Honeypot fields*/}
      <input type="hidden" id="password" name="password"/>
      <input type="hidden" id="website" name="website"/>
      {/*form title seems to be auto generated*/}
      <input type="hidden" name="form_title" value={`Lucid ${data.formname} ${site?.domain}`}/>
      {/*Some fields require encryption. They are encrypted only on SSG via formatInitialBlocks*/}
      <input type='hidden' name='formname' value={formname}/>
      <input type='hidden' name='redirect' value={data.redirect ?? ''}/>
      <input type="hidden" name="verification" value={data.verification}/>
      {Object.entries(data).map(([name, val], i) => {
        if (name.indexOf('opts_') === 0) {
          return <input key={i} type='hidden' name={name} value={val as string ?? ''}/>
        }
        return null
      })}
    </>
  )
}

function showDependentField(standardName: string, data: InputFieldData, state?: FieldStates): boolean {
  if (!data.depends_on) return true

  if (state?.dependentFields) {
    const dependsOnState = state.dependentFields.find(item => item.dependentField === standardName)
    return typeof dependsOnState !== 'undefined'
      && dependsOnState.anchorField === data.depends_on
      && dependsOnState.anchorIsTicked
  }
  return false
}

function registerDependentField(standardName: string, data: InputFieldData, state?: FieldStates) {
  if (data.depends_on) {
    if (typeof state?.dependentFields.find(item => item.dependentField === standardName) !== 'undefined') {
      return
    }
    state?.setDependentFields((prev: DependentFieldsState) => {
      return [
        ...prev,
        {
          dependentField: standardName,
          anchorField: data.depends_on as string,
          anchorIsTicked: false
        }] as DependentFieldsState
    })
  }
}

function onInputFieldChange(data: InputFieldData, standardizedName: string, target: ChangeEvent<any>['target'], state?: FieldStates) {
  if (!state?.dependentFields) return
  switch (data.field_type) {
    case 'checkbox':
    case 'radio':
    case 'select':
    case 'selectgroup':
      const selection = `${standardizedName}.${target.value}`
      const newDependentFields: DependentFieldsState = []
      for (const linkedField of state.dependentFields) {
        if (linkedField.anchorField.includes(`${standardizedName}.`)) {
          if (linkedField.anchorField === selection) {
            newDependentFields.push({...linkedField, anchorIsTicked: true})
          } else {
            newDependentFields.push({...linkedField, anchorIsTicked: false})
          }
        } else {
          newDependentFields.push(linkedField)
        }
      }
      state.setDependentFields(newDependentFields)
      break
    default: {
      const linkedField = state?.dependentFields.find(f => f.anchorField === standardizedName)
      if (!linkedField) return
      const newDependentFields: DependentFieldsState = []
      for (const linkedField of state.dependentFields) {
        if (linkedField.anchorField === standardizedName && target.value.trim() !== '') {
          newDependentFields.push({...linkedField, anchorIsTicked: true})
        } else {
          newDependentFields.push({...linkedField, anchorIsTicked: false})
        }
      }
      state.setDependentFields(newDependentFields)
    }
  }
}

export default function SectionContactForm(props: SectionContactFormProps) {
  return (
    <>
      <Script src="https://www.google.com/recaptcha/api.js"></Script>
      <LucidInlineBlocks
        className='form-items'
        name="form_items"
        blocks={{contact: CONTACT_FORM, consultation: CONSULTATION_FORM, custom: CUSTOM_FORM}}
        max={1}
        itemProps={{...props}}
      />
    </>
  )
}

function getInputHandlers(
  data: InputFieldData,
  standardizedName: string,
  registerValidator: UseFormRegister<FieldValue<any>>,
  state?: FieldStates
) {
  if (!standardizedName || standardizedName === '') return null
  const {onChange, ...validatorRefs} = registerValidator(standardizedName)

  function onInputChange(event: ChangeEvent<any> | any) {
    onInputFieldChange(data, standardizedName, event.target, state)
    return onChange(event)
  }

  return {onChange: onInputChange, ...validatorRefs}
}

function getPerGroupItems(fieldDirectives: InputFieldData, standardizedName: string): [string, ValidationOptions][] {
  if (fieldDirectives.field_type === 'selectgroup' && fieldDirectives.group_items) {

    return fieldDirectives.group_items.split('\n').reduce((acc, current) => {
      if (current === '') {
        acc.push([`${standardizedName}_${acc.length}`, fieldDirectives.validate as ValidationOptions])
      }
      return acc
    }, [[`${standardizedName}_0`, fieldDirectives.validate as ValidationOptions]] as [string, ValidationOptions][])
  }

  return [[standardizedName, fieldDirectives.validate as ValidationOptions]]
}

function createValidationSchema(inputFields: FormComponentDataProps, dependentFields: DependentFieldsState) {
  const shape: { [inputName: string]: yup.StringSchema<string | undefined | null> } = {}
  for (const fieldDirectives of inputFields.form_fields) {
    if (!fieldDirectives.validate) continue

    const standardizedName = standardizeName(fieldDirectives.field_name)
    const skipField = dependentFields.find(df =>
      df.dependentField === standardizedName
    )?.anchorIsTicked === false
    if (skipField) continue

    const perGroupItems = getPerGroupItems(fieldDirectives, standardizedName)
    for (const [groupedStandardName, validations] of perGroupItems) {
      let yupValue: yup.StringSchema<string | undefined> = yup.string()

      for (const [validation, isOn] of Object.entries(validations) as Entries<ValidationOptions>) {
        if (!isOn || isOn === 'false') break
        switch (validation) {
          case 'required':
            yupValue = yupValue.required(`${fieldDirectives.field_name} is required`)
            break
          case 'is_valid_alpha':
            yupValue = yupValue.matches(/^[\sa-zA-Z0-9_-]*$/, `${fieldDirectives.field_name} must be alphanumeric`)
            break
          case 'is_phone_number':
            yupValue = yupValue.matches(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, `${fieldDirectives.field_name} must be a phone number`)
            break
          case 'is_valid_number':
            yupValue = yupValue.matches(/^[0-9]*$/, `${fieldDirectives.field_name} must be a number`)
            break
          case 'is_email':
            yupValue = yupValue.email(`${fieldDirectives.field_name} must be a valid email`)
            break
        }
      }
      shape[groupedStandardName] = fieldDirectives.field_type === 'radio' ? yupValue.nullable() : yupValue
    }
  }
  return shape
}

/**
 * Default values without data should be set to 'unset'
 * This is because we need to differentiate from empty values
 */
function getFormDefaultConfigFields(templateName: string) {
  return {
    _template: templateName,
    redirect: '/thank-you',
    formname: `${templateName}form`,
    submit_text: templateName === 'consultation' ? 'Request Appointment' : 'Submit',
    opts_sendto: UNSET_DEFAULT_VALUE,
    opts_sendccto: UNSET_DEFAULT_VALUE,
    opts_sendbccto: UNSET_DEFAULT_VALUE,
    verification: UNSET_DEFAULT_VALUE,
  }
}

function HiddenDefaultsFromSiteGlobals(data: BlockFieldProps) {
  return <DefaultsFromSiteGlobals {...data} type={'hidden'}/>
}

function DefaultsFromSiteGlobals({field, input, type = 'text'}: BlockFieldProps & { type: 'text' | 'hidden' }) {

  const hasSavedValue = input.value !== UNSET_DEFAULT_VALUE
  const inputRef = useRef<HTMLInputElement>(null)
  const [{site_contents: {technical = {}}, site}] = useLucidContext(true)
  const {forms = {}} = technical as { forms?: BlockContents }

  const fieldName = input.name.substring(input.name.lastIndexOf('.') + 1)
  const fieldConfig = ENCRYPTED_FIELDS[fieldName as keyof typeof ENCRYPTED_FIELDS]
  let defaultValue = hasSavedValue ? input.value : undefined
  if (typeof defaultValue === 'undefined' && typeof fieldConfig?.globalKey !== 'undefined') {
    defaultValue = (forms?.[fieldConfig.globalKey] as string ?? '').trim()
  }

  return (
    <CustomTinaFieldComponentStyles>
      <label htmlFor={input.name}>
        {field.label}
        <span>{field.description}</span>
      </label>
      <input
        ref={inputRef}
        onChange={input.onChange}
        type={type}
        name={field.name}
        value={defaultValue}
      />
    </CustomTinaFieldComponentStyles>
  )
}

function HiddenEncryptedMask({display}: { display: boolean }) {
  if (!display) return null
  return <span style={{position: 'absolute', left: 0, padding: '17px'}}>Encrypted</span>
}
