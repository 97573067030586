export default function BlogCardItem(props) {
  const {
    title,
    subtitle = null,
    url = null,
  } = props
  return (
    <div className="column">
      {url ? (
        <a href={url} className="menu-card-item blog-card-item blog-post">
          <span className="blog-post-title heading-1 title">{title}</span>
          {subtitle && <span className="blog-post-author heading-3 subtitle">{subtitle}</span>}
        </a>
      ) : (
        <div className="menu-card-item blog-card-item blog-post">
          <span className="blog-post-title heading-1 title">{title}</span>
          {subtitle && <span className="blog-post-author heading-3 subtitle">{subtitle}</span>}
        </div>
      )}
    </div>
  )
}
