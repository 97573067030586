import styled from 'styled-components'

const CustomTinaFieldComponentStyles = styled.div`
  > label:not([class^="nextui-"]) {
    display: block;
    font-weight: 600;
    color: var(--tina-color-grey-8);
    font-size: var(--tina-font-size-1);

    > span {
      font-size: var(--tina-font-size-0);
      font-style: italic;
      color: var(--tina-color-grey-6);
      padding-top: 4px;
      text-wrap: initial;
      display: block;
    }
  }
  
  span.custom-description {
    display: block;
    font-size: var(--tina-font-size-0);
    font-style: italic;
    color: var(--tina-color-grey-6);
    margin-bottom: var(--tina-padding-big);
  }

  > select,
  > textarea, > input {
    border: 1px solid var(--tina-color-grey-2);
    width: 100%;
    outline: none;
    box-shadow: 0 0 0 2px transparent;
    border-radius: var(--tina-radius-small);
    padding: var(--tina-padding-small);
    margin-bottom: var(--tina-padding-big);

    :hover {
      box-shadow: 0 0 0 2px var(--tina-color-grey-3);
    }

    :focus {
      box-shadow: 0 0 0 2px var(--tina-color-primary);
    }
  }

  select {
    height: auto;
    padding: var(--tina-padding-small);
    margin-bottom: 10px;
  }
`

export default CustomTinaFieldComponentStyles
