import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import React from 'react'
import RSSReader from '../shared/RSSReader'
import BlogItem from '../BlogItem'
import SectionImage from '../shared/SectionImage'
import useSWR from 'swr'
import {styleCore} from './stylecore'

export default function Blog(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    image,
    feed,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms
  } = props

  const {data: items, error} = useSWR([feed, 3], RSSReader)

  const isLoading = !error && !items

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section blog-section stack'}
      name="blog"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content blog_gutter_row_2 blog_gutter_column_2">
          {image?.src && (
            <div className="row">
              <div className="column">
                <div className="author-image">
                  <SectionImage
                    name='image'
                    src={image?.src}
                    mobile={image?.mobile}
                    alt={image?.alt}
                    width={image?.width}
                    height={image?.height}
                    priority={image?.priority}
                    sizes={image?.sizes}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <SectionText name='title' text={title} cms={cms} />
              </span>
              <span className="subtitle heading-2">
                <SectionText name='subtitle' text={subtitle} cms={cms} />
              </span>
            </h1>
          </div>
          {intro && (
            <div className="section-description">
              <div className="row">
                <div className="column">
                  <div className="section-richtext paragraph">
                    <RichtextWithVariables
                      name='intro'
                      richtext={intro}
                      cms={cms}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row blog-posts section-richtext">
            {isLoading && (
              <div className="column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">Loading...</span>
                </div>
              </div>
            )}
            {error && (
              <div className="column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">Failed to load posts.</span>
              </div>
            </div>
            )}
            {items && items.length === 0 && (
              <div className="column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">No posts from source.</span>
                </div>
              </div>
            )}
            {items && items.length > 0 && (
              items.map((item) => (
                <BlogItem
                  key={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  description={item.description}
                  date={item.date || item.pubDate}
                  url={item.url || item.link}
                />
              ))
            )}
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables
                  name='outro'
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.url && button?.label && (
            <div className="button-container">
              <SectionButton
                name='button'
                href={button?.url}
                label={button?.label}
                cms={cms}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

Blog.StyleCore = styleCore
